import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Helmet } from 'react-helmet';

import { FormButton, FormError } from 'components/Form';
import { BASELINE_MAIN_PROBLEM, CASE_STRUCTURE } from 'data';
import {
  Formik,
  Form,
  FormikHelpers,
  FormikProps,
  ErrorMessage,
} from 'formik';
import { useRouter } from 'hooks';
import React, { useEffect, useState } from 'react';
import {
  PatientDetails,
  PatientResponseInfo,
  SimilarPatients,
} from 'types';
import {
  convertToCase,
  getMappedValue,
  hasTestPermission,
  sortByKey,
} from 'utils';
import {
  Goal,
  PSFS,
  Diagnose,
  Neck,
  Shoulder,
  Back,
  Hip,
  Knee,
  ICPC,
  BaselineSchema,
} from '.';

interface BaselineProps {
  data: PatientResponseInfo;
}

export const Baseline: React.FC<BaselineProps> = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { patientDetails, similarPatients } = data;

  // SPA Link Tracking
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  const router = useRouter();
  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Undersøkelse`,
      href: `https://supportprim.idi.ntnu.no${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  const handleSubmit = async (
    values: PatientDetails,
    actions: FormikHelpers<PatientDetails>,
  ) => {
    try {
      setLoading(true);
      const { setSubmitting } = actions;
      const token = localStorage.getItem('token') || '';
      if (
        window.confirm(
          'Er du sikker på at du vil lagre? Valgt diagnose kan ikke endres.',
        )
      ) {
        // ! save all patients
        // await savePatientBaseline();
        // return;

        const replaceValues =
          values.psfs1_act_1 === 'RESET'
            ? {
                psfs1_act_1: '',
                psfs1_1: '',
                body_main_1: '',
                similarPatients: {},
              }
            : {};

        // Set params
        const query = new URLSearchParams({
          casebase: 'cb_all',
          concept: 'patient',
          k: '-1', // desired number of similar cases
          // similarityFunction: 'ism_knowledge_based',
          similarityFunction: 'ism_knowledge_based',
        });

        // keys used for similarity matching.
        const caseKeys = Object.keys(
          CASE_STRUCTURE.activeAttributesWithWeights,
        );

        // Get patient
        // ! Need to use Object.assign to avoid removing empty value
        const body = JSON.stringify(
          convertToCase(
            Object.assign({}, data.patientDetails),
            caseKeys,
          ),
        );

        // Get similar patients data
        const { similarCasesWithContent } = await fetch(
          `${process.env.REACT_APP_URL_CBR}/cbr/retrieveSimilarCasesWithContent?${query}`,
          {
            method: 'POST',
            body,
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
          },
        ).then((res) => res.json());

        // Decide which patients should be shown
        const similarPatients = similarCasesWithContent
          .filter((s: any) => s.outcome_01 === '1') // retrieve successful cases
          .slice(0, 3)
          .sort(
            (
              a: { patient_id: string; similarity: number },
              b: { patient_id: string; similarity: number },
            ) => (a.similarity < b.similarity ? 1 : -1),
          )
          .map((p: SimilarPatients) => ({
            [p.patient_id]: p.similarity,
          }));

        // formik saves checkboxes as array fields
        const convertedValues = Object.entries(values)
          .map(([key, value]) => {
            // if (['neck_conclusion_1'].includes(key)) {
            //   return {
            //     key,
            //     value: parseInt(value),
            //   };
            // }
            return {
              key,
              value: Array.isArray(value) ? value[0] : value,
            };
          })
          .reduce(
            (obj, item) => ({
              ...obj,
              [item.key]:
                item.value ||
                (item.value === 0 ? 0 : '') ||
                (typeof item.value === 'boolean' &&
                item.value === false
                  ? false
                  : item.value),
            }),
            {},
          );

        // Save new info to patient
        await fetch(
          `${process.env.REACT_APP_URL_CBR}/patient/savePatient`,
          {
            method: 'POST',
            body: JSON.stringify({
              ...sortByKey({ ...data }),
              similarPatients:
                Object.keys(data.similarPatients).length <= 0
                  ? {
                      ...Object.assign({}, ...similarPatients),
                    }
                  : values.psfs1_act_1 === 'RESET' // remove similar patients
                  ? {}
                  : data.similarPatients, // Add them again if they exist
              patientDetails: sortByKey({
                ...data.patientDetails,
                ...convertedValues,
                ...replaceValues,
              }),
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
          },
        );

        await window.location.reload();

        // setLoading(false);
        // setSubmitting(false);
      }
      await setLoading(false);
      await setSubmitting(false);
    } catch (err) {
      setLoading(false);
      setError(err);
      console.log('BaselineError:', err.message);
    }
  };

  const canSave = () => {
    const mandatory =
      Object.entries({
        psfs1_act_1: patientDetails.psfs1_act_1,
        psfs1_1: patientDetails.psfs1_1,
        body_main_1: patientDetails.body_main_1,
      }).filter(([key, value]) => value || `${value}` === '0')
        .length >= 3;

    const patients =
      Object.entries(similarPatients || {}).length >= 3;

    return [mandatory, patients].filter((v) => v).length >= 2;
  };

  return (
    <>
      <Helmet>
        <title>{`SupportPrim - Undersøkelse`}</title>
      </Helmet>
      <Formik
        initialValues={patientDetails}
        onSubmit={(
          values: PatientDetails,
          actions: FormikHelpers<PatientDetails>,
        ) => {
          handleSubmit(values, actions);
        }}
        validationSchema={BaselineSchema}
      >
        {(props: FormikProps<PatientDetails>) => {
          const bodyPart = getMappedValue(
            BASELINE_MAIN_PROBLEM.body_main_1,
            props.values.body_main_1,
          );

          const Component = [Neck, Shoulder, Back, Hip, Knee][
            bodyPart.value - 1
          ];

          return (
            <Form className="space-y-6">
              <div className="flex flex-col max-w-screen-md">
                <PSFS
                  values={{
                    ...props.values,
                  }}
                  initialValues={{
                    ...props.initialValues,
                  }}
                />
                <Goal />
                <Diagnose
                  values={{
                    ...props.values,
                  }}
                  initialValues={{
                    ...props.initialValues,
                  }}
                />
                {Component && (
                  <Component
                    values={{
                      ...props.values,
                    }}
                  />
                )}
                <ICPC data={data} />
                <div className="flex">
                  <div className="text-red-500 text-sm">
                    <ErrorMessage
                      name="psfs1_act_1"
                      render={(msg) => <div>* {msg}</div>}
                    />
                    <ErrorMessage
                      name="psfs1_1"
                      render={(msg) => <div>* {msg}</div>}
                    />
                    <ErrorMessage
                      name="body_main_1"
                      render={(msg) => <div>* {msg}</div>}
                    />
                  </div>
                  {canSave() ? (
                    hasTestPermission() && (
                      <FormButton
                        className="ml-auto w-32"
                        loading={loading}
                        title="Lagre"
                      />
                    )
                  ) : (
                    <FormButton
                      className="ml-auto w-32"
                      loading={loading}
                      title="Lagre"
                    />
                  )}
                </div>
              </div>

              <FormError error={error} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
