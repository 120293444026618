import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardProgressBar,
  CardAnswers,
} from 'components/Card';
import { GROUP, FUNCTION_AND_ACTIVITY_SCORE_TEXT } from 'data';
import { getMappedValue, replaceScore, variableChecker } from 'utils';
import { HoverContent } from 'components/HoverContent';
import { PatientResponseInfo } from 'types';
import { CopyText } from 'components/CopyText';

function replaceDays(days: string) {
  let str = (days || '').toLowerCase() || '';
  str = str.replace('none', 'ingen');
  str = str.replace('days', 'dager');
  str = str.replace('day', 'dag');

  return str;
}

interface FunctionAndActivityProps {
  className?: String;
  cardClassName?: String;
  data: PatientResponseInfo;
}

export const FunctionAndActivity: React.FC<FunctionAndActivityProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const { activity_1, mskhq_q7_1, mskhq_pa_1 } = patientDetails;

  // Mapped values
  const mapped_activity_1 = getMappedValue(
    FUNCTION_AND_ACTIVITY_SCORE_TEXT['activity_1'],
    activity_1,
  );
  const mapped_mskhq_q7 = getMappedValue(
    FUNCTION_AND_ACTIVITY_SCORE_TEXT['mskhq_q7_1'],
    mskhq_q7_1,
  );

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <CardLabel
          className="mb-8"
          text={GROUP.function.title.no}
          size={CardLabelType.lg}
        />
        <HoverContent
          content={replaceScore(
            GROUP.function.Activity_1.hover.no,
            mapped_activity_1.no,
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.function.Activity_1.hover.no,
              mapped_activity_1.no,
            )}
          >
            <CardProgressBar
              text={GROUP.function.Activity_1.label.no}
              progress={5 - mapped_activity_1.value}
              parseProgressValue={(value) => (value - 1) * (100 / 3)}
              min={1}
              max={4}
              indicators={[2, 2]}
              disabled={variableChecker(activity_1)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            GROUP.function.Mskhq_q7.hover.no,
            mapped_mskhq_q7.no,
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.function.Mskhq_q7.hover.no,
              mapped_mskhq_q7.no,
            )}
          >
            <CardProgressBar
              text={GROUP.function.Mskhq_q7.label.no}
              progress={4 - mapped_mskhq_q7.value}
              parseProgressValue={(value) => value * (100 / 4)}
              min={0}
              max={4}
              indicators={[1, 2]}
              disabled={variableChecker(mskhq_q7_1)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            GROUP.function.Mskhq_pa_1.hover.no,
            mskhq_pa_1,
          )}
          className="inline-block w-full"
        >
          <CopyText
            content={replaceScore(
              GROUP.function.Mskhq_pa_1.hover.no,
              mskhq_pa_1,
            )}
          >
            <CardAnswers
              text={GROUP.function.Mskhq_pa_1.label.no}
              noSpacing={true}
            >
              {replaceDays(mskhq_pa_1)}
            </CardAnswers>
          </CopyText>
        </HoverContent>
      </Card>
    </div>
  );
};
