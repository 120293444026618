import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/dist/border.css';

interface HoverContentProps {
  content?: String | React.ReactNode;
  className?: string;
}

export const HoverContent: React.FC<HoverContentProps> = ({
  content,
  children,
  className,
}) => {
  return (
    <>
      <Tippy
        content={
          <div className="p-4">
            {typeof content === 'string' ? (
              <div
                className="leading-relaxed"
                dangerouslySetInnerHTML={{
                  __html:
                    content !== null && content !== undefined
                      ? content
                      : '',
                }}
              />
            ) : (
              <div className="leading-relaxed">{content}</div>
            )}
          </div>
        }
        theme="light"
      >
        <div className={`${className ? className : ''}`}>
          {children}
        </div>
      </Tippy>
    </>
  );
};
