import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { FormInput } from 'components/Form';

interface GoalProps {}

export const Goal: React.FC<GoalProps> = () => {
  return (
    <Card>
      <CardFormText text="Hva er hovedmålet med behandlingen">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Fylles ut i samarbeid med pasienten"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-6">
        <FormInput
          id="treat_goal_1"
          name="treat_goal_1"
          placeholder=""
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
