import React from 'react';

import { Line } from 'components/Charts';
import { GRAPHS, GRAPHS_X, IBaselinePatient } from 'data';

interface ComparisonProgressProps {
  patients: IBaselinePatient[];
  legendIndex: boolean[];
  handleLegendClick: (i: number) => void;
}

export const ComparisonProgress: React.FC<ComparisonProgressProps> =
  ({ patients, legendIndex, handleLegendClick }) => {
    return (
      <div>
        <div className="flex space-x-2 justify-center">
          {patients.map((p, i) => {
            return (
              i > 0 && (
                <div
                  className="flex items-center text-sm cursor-pointer"
                  onClick={() => handleLegendClick(i)}
                  key={`${p}-${i}`}
                >
                  <span
                    className="inline-block w-3 h-3 rounded-full mr-2"
                    style={{
                      backgroundColor: p.pointBackgroundColor,
                    }}
                  />
                  <span
                    className={`text-base ${
                      !legendIndex[i] ? 'line-through' : ''
                    }`}
                  >
                    {p.title}
                  </span>
                </div>
              )
            );
          })}
        </div>
        <div className="grid grid-cols-2 gap-12">
          {GRAPHS.map(({ title, range }, index) => (
            <Line
              // ref={(ref) =>
              //   scatterLineRef.current
              //     ? (scatterLineRef.current[index] = ref)
              //     : null
              // }
              key={`${index}`}
              className="mb-8"
              height={220}
              data={{
                labels: GRAPHS_X,
                datasets: patients
                  .filter((p, i) => i > 0)
                  .map((patient, index) => {
                    if (title === 'Smerte' || title === 'Mestring') {
                      return {
                        label: patient.title,
                        data: patient.graphs[title].map(
                          (y: number, i: number) => {
                            // Omit values for week 4
                            // Map values here
                            const xMapping = [0, 2, 4, 8, 12];
                            return {
                              x: xMapping[i],
                              y: i === 2 ? null : y,
                            };
                          },
                        ),
                        ...patient,
                        pointRadius: 4,
                        showLine: true,
                        hidden: !legendIndex[index + 1],
                      };
                    }
                    return {
                      label: patient.title,
                      data: patient.graphs[title]
                        ? patient.graphs[title].map(
                            (y: number, i: number) => {
                              // Map values here
                              const xMapping = [0, 2, 4, 8, 12];
                              return { x: xMapping[i], y };
                            },
                          )
                        : [],
                      ...patient,
                      pointRadius: 4,
                      showLine: true,
                      hidden: !legendIndex[index + 1],
                    };
                  }),
              }}
              options={{
                spanGaps: true,
                events: [
                  'mouseout',
                  'click',
                  'touchstart',
                  'touchmove',
                ],
                legend: {
                  // display: index <= 0 ? true : false,
                  display: false,
                  position: 'top',
                  labels: {
                    boxWidth: 8,
                    usePointStyle: true,
                  },
                },
                maintainAspectRatio: false,
                title: {
                  display: true,
                  text: title,
                },
                scales: {
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: 'Uke',
                      },
                      ticks: {
                        stepSize: 1,
                        callback: (value) =>
                          value === 0 ? 'baseline' : value,
                      },
                      afterBuildTicks: (scale, ticks) => {
                        // Filter which x values should be shown
                        // return [0, 1, 2, 3, 4, 8, 12];
                        return [0, 2, 4, 8, 12];
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        min: range.min,
                        max: range.max,
                        reverse: ['Generell bedring'].includes(title),
                        stepSize: ['Generell bedring'].includes(title)
                          ? 1
                          : 2,
                        callback: (value) => {
                          let label = '';
                          if (range.min === value) {
                            label = `${range.minLabel}`;
                          }

                          if (
                            ['Generell bedring'].includes(title) &&
                            range.median === value
                          ) {
                            label = `${range.medianLabel}`;
                          }

                          if (range.max === value) {
                            label = `${range.maxLabel}`;
                          }

                          return `${label} ${value}`;
                        },
                      },
                    },
                  ],
                },
                tooltips: {
                  mode: 'index',
                  intersect: false,
                  callbacks: {
                    label: function (tooltipItem, data) {
                      // let label =
                      //   data.datasets &&
                      //   tooltipItem.datasetIndex >= 0
                      //     ? (
                      //         data.datasets[
                      //           tooltipItem.datasetIndex
                      //         ] || []
                      //       ).label || ''
                      //     : '';

                      // if (label) {
                      //   label += ': ';
                      // }

                      return `${tooltipItem.yLabel}` || '';
                    },
                  },
                },
                // hover: {
                //   mode: 'nearest',
                //   intersect: true,
                // },
              }}
            />
          ))}
        </div>
      </div>
    );
  };
