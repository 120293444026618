import React from 'react';

import {
  CardLabel,
  CardLabelType,
  CardAnswers,
} from 'components/Card';
import { COMPARISON_INFO, IBaselinePatient, TREATMENT } from 'data';
import { getMappedValue } from 'utils';

interface ComparisonInfoProps {
  patients: IBaselinePatient[];
  patientIndex: number;
}

export const ComparisonInfo: React.FC<ComparisonInfoProps> = ({
  patients,
  patientIndex,
}) => {
  return (
    <>
      {COMPARISON_INFO.map((info, index) => {
        let answer = Object.entries(patients[patientIndex].info).map(
          ([key, value]) => value,
        )[index];
        if (`${answer}`.length <= 0) {
          return null;
        }

        if (info === 'Utført av') {
          answer = getMappedValue(TREATMENT.physio_spec_3, answer).no;
        }

        return (
          <CardAnswers
            text={info}
            noSpacing={index === COMPARISON_INFO.length - 1}
          >
            {Array.isArray(answer) ? (
              answer.map((v) => (
                <CardLabel text={`${v}`} size={CardLabelType.sm} />
              ))
            ) : (
              <CardLabel text={`${answer}`} size={CardLabelType.sm} />
            )}
          </CardAnswers>
        );
      })}
    </>
  );
};
