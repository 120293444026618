import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardPatient,
} from 'components/Card';
import { HoverContent } from 'components/HoverContent';
import {
  BASELINE_MAIN_PROBLEM,
  EXAMINATIONS,
  GENDER,
  IBaselinePatient,
  LCA_SCORE,
  MSKT_SCORE_TEXT,
} from 'data';
import { getMappedValue, replaceScore } from 'utils';

interface ComparisonPatientsProps {
  patients: IBaselinePatient[];
  patientIndex: number;
  onClick: (index: number) => void;
}

export const ComparisonPatients: React.FC<ComparisonPatientsProps> =
  ({ patients, patientIndex, onClick }) => {
    const date = new Date();

    return (
      <div className="lg:flex justify-between -mx-3">
        {patients.map((p: any, index: number) => {
          const {
            title,
            birthYear,
            sex,
            orebro,
            mskt,
            mskhq,
            mainProblem,
            sympton,
            warningValues,
            pointBackgroundColor,
            similarPatients,
            id,
            phenotype,
          } = p;
          const mappedMSKT = getMappedValue(
            MSKT_SCORE_TEXT.mskt_risk_1,
            mskt,
          );

          const mappedMainProblem = getMappedValue(
            BASELINE_MAIN_PROBLEM.body_main_1,
            mainProblem,
          );
          const mappedPhenotype = getMappedValue(
            LCA_SCORE,
            phenotype,
          );

          const warnings = Object.entries(warningValues)
            .map(([key, value]: any) => {
              // Filter which body parts should be shown
              if (mainProblem !== key.split('_')[0]) {
                return '';
              }

              // This is comment so it is kinda special
              if (['neck_nevro_other_1'].includes(key)) {
                return value
                  ? `Ev. kommentar nevrologiske funn: ${value}`
                  : '';
              }

              let index = value === 'yes' ? 1 : value;
              return EXAMINATIONS[key][index];
            })
            .filter((v) => (v || '').length > 0)
            .join(', ');

          return (
            <Card
              className={`lg:w-1/${patients.length} mx-3 ${
                patientIndex === index ? 'shadow-lg' : false
              }`}
              key={`${index}`}
            >
              <CardPatient
                title={title}
                id={id}
                similarPatients={similarPatients}
                active={patientIndex === index ? true : false}
                onClick={() => onClick(index)}
                patientColor={pointBackgroundColor}
              >
                {/* <CardLabel
                text={`${getMappedValue(GENDER.gender_1, sex).no} - ${
                  date.getFullYear() - birthYear
                } år`}
                size={CardLabelType.sm}
                className="mb-1"
              /> */}
                {warnings.length > 0 ? (
                  <HoverContent
                    content={`${warnings}  <div>Pasientens kjønn og alder:</div>
             <div class="font-medium mb-2">${`${
               getMappedValue(GENDER.gender_1, sex).no
             } - ${date.getFullYear() - birthYear} år`}</div>
            <div>Pasientens diagnose:</div>
            <div class="font-medium">${`${
              sympton ? `  ${sympton}` : ''
            }`}</div>`}
                    className="inline-block"
                  >
                    <CardLabel
                      text={`${mappedMainProblem.no}`}
                      size={CardLabelType.sm}
                      className="mb-1 text-red-500"
                    />
                    {/* <CardLabel
                    text={`${mappedMainProblem.no}${
                      sympton ? `  ${sympton}` : ''
                    }`}
                    size={CardLabelType.sm}
                    className="mb-1 text-red-500"
                  /> */}
                  </HoverContent>
                ) : (
                  <CardLabel
                    text={`${mappedMainProblem.no}`}
                    size={CardLabelType.sm}
                    className="mb-1"
                  />
                  // <CardLabel
                  //   text={`${mappedMainProblem.no}${
                  //     sympton ? ` - ${sympton}` : ''
                  //   }`}
                  //   size={CardLabelType.sm}
                  //   className="mb-1"
                  // />
                )}

                <CardLabel
                  text={`Psykososiale faktorer: ${orebro}`}
                  size={CardLabelType.sm}
                  className="mb-1"
                />

                <CardLabel
                  text={`Risikogruppe: ${
                    mappedMSKT.no ? mappedMSKT.no : ''
                  }`}
                  size={CardLabelType.sm}
                  className="mb-1"
                />

                <CardLabel
                  text={`Muskel-og skjeletthelse: ${
                    mskhq ? mskhq : ''
                  }`}
                  size={CardLabelType.sm}
                  className="mb-1"
                />

                <HoverContent
                  content={replaceScore(
                    mappedPhenotype.hover || 'Fenotype mangler',
                    phenotype,
                  )}
                  className="inline-block"
                >
                  <CardLabel
                    text={`Fenotype: ${
                      phenotype >= 0 ? phenotype : 'mangler'
                    }`}
                    size={CardLabelType.sm}
                    className={`mb-1 ${
                      phenotype === -1 ? 'opacity-50' : ''
                    }`}
                  />
                </HoverContent>
              </CardPatient>
            </Card>
          );
        })}
      </div>
    );
  };
