import React from 'react';

interface CardProps {
  className?: string;
  noSpacing?: boolean;
}

export const Card: React.FC<CardProps> = ({
  children,
  className,
  noSpacing,
}) => {
  return (
    <div
      className={`bg-white relative overflow-hidden rounded ${
        noSpacing ? '' : 'p-6 mb-6'
      } ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};
