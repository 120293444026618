import React from 'react';

import pain from 'assets/images/pain-map.png';

interface Coordinate {
  x: number;
  y: number;
}

interface PainMapProps {
  coordinates: Coordinate[];
}

export const PainMap: React.FC<PainMapProps> = ({ coordinates }) => {
  const s = (1 / 16) * 100;
  return (
    <div className="bg-blue-600">
      <div
        className="relative bg-white"
        style={{ paddingTop: `${2.2}%` }}
      >
        {coordinates.map((c, i) => {
          return (
            <div
              key={`${c}-${i}`}
              className="absolute bg-red-300 bg-opacity-25"
              style={{
                left: `${c.x <= 0 ? 0.01 : s * c.x - 0.01}%`,
                top: `${
                  c.y <= 0
                    ? 0 + 6.5
                    : c.y >= 15
                    ? 100 - s
                    : s + s * c.y
                }%`,
                width: `${s}%`,
                height: `${s}%`,
              }}
            />
          );
        })}
        <img src={pain} alt="Pain map" />
      </div>
    </div>
  );
};
