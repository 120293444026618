import React from 'react';
import { CardLabel, CardLabelType, Card } from 'components/Card';
import { GROUP } from 'data';
import { HoverContent } from 'components/HoverContent';
import { CircleProgressBar } from 'components/CircleProgressBar';
import { replaceScore, variableChecker } from 'utils';
import { PatientResponseInfo } from 'types';
import { CopyText } from 'components/CopyText';

interface OrebroProps {
  className?: String;
  cardClassName?: String;
  data: PatientResponseInfo;
  animation?: boolean;
}

export const Orebro: React.FC<OrebroProps> = ({
  className,
  cardClassName,
  data,
  animation = true,
}) => {
  const { patientDetails } = data;
  const { oreb_score_1 } = patientDetails;
  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <div className="flex flex-col flex-1 items-center justify-center">
          <HoverContent
            content={replaceScore(
              GROUP.orebro.oreb_score_1.hover.no,
              oreb_score_1,
            )}
          >
            <CopyText
              content={replaceScore(
                GROUP.orebro.oreb_score_1.hover.no,
                oreb_score_1,
              )}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <div className="inline-block mb-4">
                  <CircleProgressBar
                    value={oreb_score_1}
                    indicators={[45, 54]}
                    animation={animation}
                  />
                </div>
                <CardLabel
                  text={GROUP.orebro.label.no}
                  size={CardLabelType.lg}
                  className={`${
                    variableChecker(oreb_score_1)
                      ? 'opacity opacity-25'
                      : ''
                  }`}
                />
              </div>
            </CopyText>
          </HoverContent>
        </div>
      </Card>
    </div>
  );
};
