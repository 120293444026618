import React from 'react';

interface CopyTextProps {
  content: any;
}

export const CopyText: React.FC<CopyTextProps> = ({
  content,
  children,
}) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setShow(false);
    }, 1000);
    return () => clearInterval(interval);
  }, [show]);

  const handleClick = () => {
    navigator.clipboard.writeText(
      `${content}`.replace(/<[^>]+>/g, ''),
    );
    setShow(true);
  };

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      {show && (
        <div className="fixed p-2 bg-green-600 w-full top-0 left-0 z-10 text-white text-center font.medium">
          Hover text copy
        </div>
      )}
      {children}
    </div>
  );
};
