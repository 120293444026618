export const CONTROL_GROUP = [
  'support31',
  'support67',
  'support72',
  'support69',
  'support66',
  'support4',
  'support14',
  'support64',
  'support53',
  'support38',
  'support62',
  'support56',
];
