import React, { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TabDescriptor } from './types';
import { HoverContent } from 'components/HoverContent';

import { ReactComponent as Info } from 'assets/svg/info.svg';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useUser } from 'context/userContext';

interface TabsProps {
  tabs: TabDescriptor[];
  className?: string;
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  className,
  children,
}) => {
  const { patientIndex } = useUser();
  const [selected, setSelected] = useState(0);
  const { trackEvent } = useMatomo();

  const handleTabChange = useCallback((selectedTabIndex) => {
    trackEvent({
      name: `Tab Click - Patient ${
        ['Current', 'A', 'B', 'C'][patientIndex]
      } - ${tabs[selectedTabIndex].content} `,
      category: 'patient-matching-page',
      action: 'click-event',
    });
    return setSelected(selectedTabIndex);
  }, []);

  const renderTabMarkup = (tab: TabDescriptor, index: number) => {
    const tabStyles =
      index === selected
        ? 'bg-indigo-500 border-indigo-500 text-white'
        : 'border-transparent opacity-50';

    return (
      <div className="flex flex-col" key={uuidv4()}>
        {tab.header && (
          <span
            onClick={() => handleTabChange(index)}
            style={{
              borderColor: tab.headerColor,
            }}
            className={`mb-6 text-center cursor-pointer ${
              tab.headerIndex?.includes(Number(selected))
                ? `border-b-4`
                : 'opacity-50'
            }`}
          >
            {tab.header}
          </span>
        )}
        <button
          key={`${index}-${tab.id}`}
          id={tab.id}
          className={`border-2 px-2 py-1 rounded-md focus:outline-none mr-6 text-medium text-lg ${tabStyles} ${
            tabs.filter((tab) => tab.header).length > 1 && !tab.header
              ? 'mt-12'
              : ''
          }`}
          onClick={() => handleTabChange(index)}
        >
          {tab.content}
        </button>
      </div>
    );
  };

  const tabsMarkup = tabs.map((tab, index) =>
    renderTabMarkup(tabs[index], index),
  );

  // Decide which react child node should be displayed
  const panelMarkup = children
    ? tabs.map((_tab, index) => {
        return selected === index ? (
          <div
            id={tabs[index].panelID || `${tabs[index].id}-panel`}
            key={tabs[index].id}
          >
            {React.Children.map(children, (child, _index) =>
              index === _index ? child : null,
            )}
          </div>
        ) : (
          <div
            id={tabs[index].panelID || `${tabs[index].id}-panel`}
            key={tabs[index].id}
            className="hidden"
          />
        );
      })
    : null;

  const infoMarkup =
    children &&
    tabs.map((_tab, index) => {
      return selected === index
        ? tabs[index].info && (
            <HoverContent
              key={`${tabs[index].info}-panel`}
              content={tabs[index].info}
              className="ml-auto cursor-pointer"
            >
              <Info className="w-5 h-5 transform rotate-180" />
              <div className="text-xs text-center mt-1">Info</div>
            </HoverContent>
          )
        : null;
    });
  return (
    <div>
      <div
        className={`flex items-center ${className ? className : ''}`}
      >
        {tabsMarkup}
        {infoMarkup}
      </div>
      {panelMarkup}
    </div>
  );
};
