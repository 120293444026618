import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { PatientDetails } from 'types';
import { BoxFields } from 'components/BoxFields';
import { BASELINE_NECK } from 'data';
import { getMappedValue } from 'utils';
import { FormInput } from 'components/Form';

interface NeckProps {
  values: PatientDetails;
}

export const Neck: React.FC<NeckProps> = ({ values }) => {
  const {
    neck_spurling_1,
    neck_conclusion_1,
    neck_move_1,
    neck_neuro_not_1,
    neck_neuro_pow_1,
    neck_neuro_reflex_1,
    neck_neuro_sens_1,
    neck_move_pain_flex_1,
    neck_move_pain_ext_1,
    neck_move_pain_rot_1,
    neck_move_pain_lat_1,
    neck_move_pain_not_1,
    neck_move_rom_flex_1,
    neck_move_rom_ext_1,
    neck_move_rom_rot_1,
    neck_move_rom_lat_1,
    neck_move_rom_not_1,
  } = values;

  // Conditional display fields
  const isNeck =
    getMappedValue(BASELINE_NECK.neck_move_1, neck_move_1).en ===
    'Yes';

  return (
    <>
      <Card>
        <CardFormText text="Nakkeundersøkelse">
          <div className="mt-2 mb-4">
            <CardLabel
              text="Reduserte og/eller smertefulle aktive bevegelsesutslag i vektbærende stilling"
              size={CardLabelType.sm}
            />
          </div>
        </CardFormText>
        <div className="mb-8">
          <BoxFields
            values={values}
            keys={BASELINE_NECK.neck_move_1}
            variables={{
              neck_move_1,
            }}
          />
        </div>

        {isNeck && (
          <>
            <div className="mb-5">
              <CardLabel
                text="Reduserte bevegelsesutslag i"
                size={CardLabelType.lg}
              />
            </div>
            <div className="mb-8">
              <BoxFields
                values={values}
                square={true}
                keys={BASELINE_NECK.limited}
                variables={{
                  neck_move_rom_flex_1,
                  neck_move_rom_ext_1,
                  neck_move_rom_rot_1,
                  neck_move_rom_lat_1,
                  neck_move_rom_not_1,
                }}
              />
            </div>
            <CardLabel
              className="mb-8"
              text="Smerter ved"
              size={CardLabelType.lg}
            />
            <BoxFields
              values={values}
              square={true}
              keys={BASELINE_NECK.painful}
              variables={{
                neck_move_pain_flex_1,
                neck_move_pain_ext_1,
                neck_move_pain_rot_1,
                neck_move_pain_lat_1,
                neck_move_pain_not_1,
              }}
            />
          </>
        )}
        <CardFormText text="Spurling test positiv">
          <div className="mt-2 mb-4">
            <CardLabel
              text="(Positiv ved smerteutstråling til arm)"
              size={CardLabelType.sm}
            />
          </div>
        </CardFormText>
        <BoxFields
          values={values}
          keys={BASELINE_NECK.spurling}
          variables={{
            neck_spurling_1,
          }}
        />
        <div className="mb-5">
          <CardLabel
            text="Angi nevrologiske funn"
            size={CardLabelType.lg}
          />
        </div>
        <div className="mb-8">
          <BoxFields
            values={values}
            keys={BASELINE_NECK.neuro}
            variables={{
              neck_neuro_not_1,
              neck_neuro_pow_1,
              neck_neuro_sens_1,
              neck_neuro_reflex_1,
            }}
            square={true}
          />
        </div>
        <div className="mb-6">
          <CardLabel
            text="Ev. kommentar nevrologiske funn"
            size={CardLabelType.lg}
          />
        </div>
        <div className="mb-6">
          <FormInput
            id="neck_neuro_other_1"
            name="neck_neuro_other_1"
            type="text"
            className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
          />
        </div>
        <CardLabel
          className="mb-8"
          text="Konklusjon av nakkevurdering"
          size={CardLabelType.lg}
        />
        <div className="mb-8">
          <BoxFields
            values={values}
            keys={BASELINE_NECK.conclusion}
            variables={{
              neck_conclusion_1,
            }}
          />
        </div>
        <div className="mb-6">
          <CardLabel
            text="Ev. kommentarer:"
            size={CardLabelType.lg}
          />
        </div>
        <div className="mb-6">
          <FormInput
            id="neck_comment_1"
            name="neck_comment_1"
            type="text"
            className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
          />
        </div>
      </Card>
    </>
  );
};
