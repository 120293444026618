import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { PatientDetails } from 'types';
import { BoxFields } from 'components/BoxFields';
import { BASELINE_HIP } from 'data';
import { getMappedValue } from 'utils';

interface HipProps {
  values: PatientDetails;
}

export const Hip: React.FC<HipProps> = ({ values }) => {
  const {
    hip_move_passive_1,
    hip_faber_1,
    hip_faddir_1,
    hip_trend_1,
    hip_pain_not_1,
    hip_pain_lat_1,
    hip_pain_med_1,
    hip_pain_post_1,
    hip_pain_ant_1,
    hip_move_1,
    hip_move_rom_flex_1,
    hip_move_rom_abd_1,
    hip_move_rom_ext_1,
    hip_move_rom_add_1,
    hip_move_rom_extrot_1,
    hip_move_rom_introt_1,
    hip_move_rom_not_1,
    hip_move_pain_flex_1,
    hip_move_pain_ext_1,
    hip_move_pain_abd_1,
    hip_move_pain_add_1,
    hip_move_pain_extrot_1,
    hip_move_pain_introt_1,
    hip_move_pain_not_1,
    hip_move_passive_rom_flex_1,
    hip_move_passive_rom_ext_1,
    hip_move_passive_rom_abd_1,
    hip_move_passive_rom_add_1,
    hip_move_passive_rom_extrot_1,
    hip_move_passive_rom_introt_1,
    hip_move_passive_rom_not_1,
    hip_move_passive_pain_flex_1,
    hip_move_passive_pain_ext_1,
    hip_move_passive_pain_abd_1,
    hip_move_passive_pain_add_1,
    hip_move_passive_pain_extrot_1,
    hip_move_passive_pain_introt_1,
    hip_move_passive_pain_not_1,
  } = values;

  // Conditional display fields
  const isHipActive =
    getMappedValue(BASELINE_HIP.hip_move_active_1, hip_move_1).en ===
    'Yes';

  const isHipPassive =
    getMappedValue(
      BASELINE_HIP.hip_move_passive_1,
      hip_move_passive_1,
    ).en === 'Yes';

  return (
    <Card>
      <CardFormText text="Hofteundersøkelse">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Reduserte og/eller smertefulle aktive bevegelsesutslag"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{ hip_move_1 }}
          keys={BASELINE_HIP.hip_move_active_1}
        />
      </div>
      {isHipActive && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                hip_move_rom_flex_1,
                hip_move_rom_ext_1,
                hip_move_rom_abd_1,
                hip_move_rom_add_1,
                hip_move_rom_extrot_1,
                hip_move_rom_introt_1,
                hip_move_rom_not_1,
              }}
              keys={BASELINE_HIP.limitedActive}
            />
          </div>

          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                hip_move_pain_flex_1,
                hip_move_pain_ext_1,
                hip_move_pain_abd_1,
                hip_move_pain_add_1,
                hip_move_pain_extrot_1,
                hip_move_pain_introt_1,
                hip_move_pain_not_1,
              }}
              keys={BASELINE_HIP.painfulActive}
            />
          </div>
        </>
      )}

      <CardFormText text="Reduserte og/eller smertefulle passive bevegelsesutslag" />
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            hip_move_passive_1,
          }}
          keys={BASELINE_HIP.hip_move_passive_1}
        />
      </div>

      {isHipPassive && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                hip_move_passive_rom_flex_1,
                hip_move_passive_rom_ext_1,
                hip_move_passive_rom_abd_1,
                hip_move_passive_rom_add_1,
                hip_move_passive_rom_extrot_1,
                hip_move_passive_rom_introt_1,
                hip_move_passive_rom_not_1,
              }}
              keys={BASELINE_HIP.limitedPassive}
            />
          </div>

          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                hip_move_passive_pain_flex_1,
                hip_move_passive_pain_ext_1,
                hip_move_passive_pain_abd_1,
                hip_move_passive_pain_add_1,
                hip_move_passive_pain_extrot_1,
                hip_move_passive_pain_introt_1,
                hip_move_passive_pain_not_1,
              }}
              keys={BASELINE_HIP.painfulPassive}
            />
          </div>
        </>
      )}
      <CardFormText text="Positiv FABER/Patricks test">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Nedsatt bevegelse i hofteleddet eller smerteprovokasjon"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            hip_faber_1,
          }}
          keys={BASELINE_HIP.hip_faber_1}
        />
      </div>
      <CardFormText text="Positiv FADDIR test (passiv fleksjon, adduksjon og innadrotasjon)">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Reproduseres pasientens anterolaterale hoftesmerte"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            hip_faddir_1,
          }}
          keys={BASELINE_HIP.hip_faddir_1}
        />
      </div>
      <CardFormText text="Positiv Trendelenburg" />
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            hip_trend_1,
          }}
          keys={BASELINE_HIP.hip_trend_1}
        />
      </div>
      <CardFormText text="Smertelokalisasjon hofte (pasientens vurdering)" />
      <div className="mb-8">
        <BoxFields
          values={values}
          square={true}
          variables={{
            hip_pain_not_1,
            hip_pain_lat_1,
            hip_pain_med_1,
            hip_pain_post_1,
            hip_pain_ant_1,
          }}
          keys={BASELINE_HIP.area}
        />
      </div>
    </Card>
  );
};
