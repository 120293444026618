import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardAnswers,
  CardProgressBar,
} from 'components/Card';
import { EDUCATION, GROUP, WORK, EMPLOY } from 'data';
import { HoverContent } from 'components/HoverContent';
import { getMappedValue, replaceScore, variableChecker } from 'utils';
import { PatientResponseInfo } from 'types';
import { CopyText } from 'components/CopyText';

interface WorkProps {
  className?: String;
  cardClassName?: String;
  data: PatientResponseInfo;
}

export const Work: React.FC<WorkProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const {
    education_1,
    work_ability_1,
    work_type_1,
    // employ_1,
    employ_paid_1,
    employ_stud_1,
    employ_retired_1,
    employ_disability_1,
    employ_sick_1,
    employ_assess_1,
    employ_unpaid_1,
    employ_unempl_1,
    employ_parentleave_1,
  } = patientDetails;

  // Map values
  const mapped_work_type_1 = getMappedValue(
    WORK['work_type_1'],
    work_type_1,
  );
  const mapped_education_1 = getMappedValue(
    EDUCATION['education_1'],
    education_1,
  );

  const employerTypes = Object.entries({
    employ_paid_1,
    employ_stud_1,
    employ_retired_1,
    employ_disability_1,
    employ_sick_1,
    employ_assess_1,
    employ_unpaid_1,
    employ_unempl_1,
    employ_parentleave_1,
  })
    .map(([key, value]) => {
      return value
        ? EMPLOY[
            key as
              | 'employ_paid_1'
              | 'employ_stud_1'
              | 'employ_retired_1'
              | 'employ_disability_1'
              | 'employ_sick_1'
              | 'employ_assess_1'
              | 'employ_unpaid_1'
              | 'employ_unempl_1'
              | 'employ_parentleave_1'
          ].no
        : {};
    })
    .filter((e) => Object.entries(e).length > 0);
  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <CardLabel
          text={GROUP.work.title.no}
          size={CardLabelType.lg}
          className="mb-8"
        />
        <HoverContent
          content={replaceScore(
            GROUP.work.work_ability_1.hover.no,
            Number.isInteger(work_ability_1)
              ? 10 - work_ability_1
              : '',
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.work.work_ability_1.hover.no,
              Number.isInteger(work_ability_1)
                ? 10 - work_ability_1
                : '',
            )}
          >
            <CardProgressBar
              text={GROUP.work.work_ability_1.label.no}
              progress={10 - work_ability_1}
              parseProgressValue={(value) => (100 / 10) * value}
              min={0}
              max={10}
              indicators={[2, 4]}
              disabled={variableChecker(work_ability_1)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={GROUP.work.Work_type_1.hover.no}
          className="inline-block"
        >
          <CopyText
            content={`${GROUP.work.Work_type_1.hover.no} ${
              mapped_work_type_1.no !== 'undefined'
                ? mapped_work_type_1.no
                : ''
            }`}
          >
            <CardAnswers
              text={GROUP.work.Work_type_1.label.no}
              className="inline-block"
            >
              {mapped_work_type_1.no !== 'undefined'
                ? mapped_work_type_1.no
                : ''}
            </CardAnswers>
          </CopyText>
        </HoverContent>

        <CardAnswers text="Arbeidssituasjon">
          {employerTypes.map((e) => (
            <div key={`${e}`}>{e}</div>
          ))}
        </CardAnswers>
        <CardAnswers text="Høyeste Utdanningsnivå">
          {mapped_education_1.no !== 'undefined'
            ? mapped_education_1.no
            : ''}
        </CardAnswers>
      </Card>
    </div>
  );
};
