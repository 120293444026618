import React from 'react';

import { parseProgress } from 'utils';

export interface ProgressBarProps {
  progress?: number | undefined;
  min?: number | undefined;
  max?: number | undefined;
  median?: number | undefined;
  indicators?: number[] | undefined;
  parseProgressValue?: (value: number) => number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress = 0,
  min,
  max = 100,
  median,
  indicators,
  parseProgressValue,
}) => {
  let parsedProgress = parseProgress((progress / max) * 100);

  let indicatorColor = 'bg-green-500';

  if (indicators) {
    if (indicators[0] < progress) {
      indicatorColor = 'bg-yellow-500';
    }

    if (indicators[1] < progress) {
      indicatorColor = 'bg-red-500';
    }
  }

  // if (min && min >= 0) {
  //   parsedProgress = (min / max) * 100;
  // }

  if (parseProgressValue) {
    parsedProgress = parseProgressValue(progress);
  }
  if (min === progress) {
    parsedProgress = 3;
  }

  if (median === progress) {
    parsedProgress = 50;
  }

  // If undefined
  if (progress === -1) {
    parsedProgress = 0;
  }

  return (
    <div className="relative">
      <div
        className="bg-gray-200 w-full rounded-full"
        style={{ height: '4px' }}
      ></div>
      <div
        className={`absolute z-10 bg-gray-600`}
        style={{
          left: `${parsedProgress}%`,
          width: '2px',
          height: '6px',
          bottom: '-1px',
        }}
      />
      <div
        className={`indicator absolute top-0 left-0 w-0 h-1 rounded-l ${indicatorColor}`}
        style={{ width: `${parsedProgress}%` }}
      >
        <span className="sr-only">{parsedProgress}%</span>
      </div>
    </div>
  );
};
