import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { PatientResponseInfo } from 'types';
import { FormInput } from 'components/Form';

interface ICPCProps {
  data: PatientResponseInfo;
}

export const ICPC: React.FC<ICPCProps> = ({ data }) => {
  const { patientDetails } = data;
  const { icpc_letter_1, icpc_nr_1, diagnose_1 } = patientDetails;

  const FIELDS = [
    { title: 'Bokstav', value: icpc_letter_1, name: 'icpc_letter_1' },
    { title: 'Nummerkode', value: icpc_nr_1, name: 'icpc_nr_1' },
    {
      title: 'Fritekst (må fylles ut)',
      value: diagnose_1,
      name: 'diagnose_1',
    },
  ];

  return (
    <Card>
      <CardFormText text="Terapeutens ICPC kode/ diagnose" />
      {FIELDS.map(({ title, value, name }) => (
        <div key={title}>
          <div className="mb-6">
            <CardLabel text={title} size={CardLabelType.lg} />
          </div>
          <div className="mb-6">
            <FormInput
              id={`${name}`}
              name={`${name}`}
              type="text"
              className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
            />
          </div>
        </div>
      ))}
    </Card>
  );
};
