import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { jsPDF } from 'jspdf';
import domtoimage from 'dom-to-image';

import { RedFlags } from './RedFlags';
import { Work } from './Work';
import { FunctionAndActivity } from './FunctionAndActivity';
import { GeneralHealth } from './GeneralHealth';
import { Pain } from './Pain';
import { BeliefAndThought } from './BeliefAndThought';
import { Orebro } from './Orebro';
import { MSKT } from './MSKT';
import { MSKHQ } from './MSKHQ';
import { useRouter } from 'hooks';
import { PatientResponseInfo } from 'types';
import { Helmet } from 'react-helmet';
import { FormButton } from 'components/Form';

interface OverviewExportProps {
  data: PatientResponseInfo;
}

export const OverviewExport: React.FC<OverviewExportProps> = ({
  data,
}) => {
  const [loading, setLoading] = React.useState(false);

  // SPA Link Tracking
  const {
    trackPageView,
    enableLinkTracking,
    trackEvent,
  } = useMatomo();
  enableLinkTracking();
  const router = useRouter();
  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Eksport`,
      href: `https://supportprim.idi.ntnu.no${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  const printDocument = async () => {
    try {
      const input = document.getElementById('print-export');
      if (input) {
        setLoading(true);
        const pdf = new jsPDF();
        if (pdf) {
          const imgData = await domtoimage.toPng(input);
          await pdf.addImage(imgData, 'PNG', 15, 20, 180, 160);
          pdf.save('download.pdf');
        }

        trackEvent({
          name: 'Download patient',
          category: 'export-page',
          action: 'click-event',
          // customDimensions: [
          //   {
          //     id: 1, // username
          //     value: localStorage.getItem('username') || '',
          //   },
          //   {
          //     id: 2, // user
          //     value: localStorage.getItem('user') || '',
          //   },
          // ], // optional
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col -mx-3">
      <Helmet>
        <title>{`SupportPrim - Eksport`}</title>
      </Helmet>
      <div onClick={printDocument}>
        <FormButton
          loading={loading}
          className="inline-flex  items-center justify-center py-2 border border-transparent text-base leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 bg-indigo-600 hover:bg-indigo-500 text-white px-4 font-medium h-12 text-center w-full mb-8"
          title="Download PDF"
        />
      </div>

      <div id="print-export" style={{ maxWidth: '1200px' }}>
        <div className="flex">
          <RedFlags className="w-1/3 px-3" data={data} />
          <div className="flex justify-between w-2/3 mb-6">
            <MSKHQ
              data={data}
              className="w-1/3 mx-3"
              cardClassName="flex flex-col h-full"
            />
            <MSKT
              data={data}
              className="w-1/3 mx-3"
              cardClassName="flex flex-col h-full"
            />
            <Orebro
              data={data}
              className="w-1/3 mx-3"
              cardClassName="flex flex-col h-full"
              animation={false}
            />
          </div>
        </div>

        <div className="flex">
          <div className="flex flex-col w-2/3">
            <div className="flex">
              <FunctionAndActivity
                data={data}
                className="w-1/2 px-3 mb-6"
                cardClassName="h-full w-full"
              />
              <BeliefAndThought
                data={data}
                className="w-1/2 px-3 mb-6"
                cardClassName="h-full w-full"
              />
            </div>
            <div className="flex">
              <GeneralHealth
                data={data}
                className="w-1/2 mx-3"
                cardClassName="h-full w-full"
              />
              <Work
                data={data}
                className="w-1/2 mx-3"
                cardClassName="h-full w-full"
              />
            </div>
          </div>
          <div className="flex w-1/3">
            <Pain
              data={data}
              className="flex flex-1 mx-3"
              cardClassName="flex flex-col flex-1 p-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
