import * as Yup from 'yup';

export const ImportPatientSchema = Yup.object().shape({
  journalId: Yup.number().required('Required'),
  birthYear: Yup.number()
    .integer()
    .required('Required')
    .min(1900, 'Birth year must be bigger than 1900')
    .max(9999, 'Birth year must be exactly 4'),
  clinicId: Yup.string().required('Required'),
});
