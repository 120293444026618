import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardFlag,
} from 'components/Card';
import { RED_FLAGS } from 'data';
import { PatientResponseInfo } from 'types';

interface RedFlagsProps {
  className?: string;
  data?: PatientResponseInfo;
}

export const RedFlags: React.FC<RedFlagsProps> = ({
  className,
  data,
}) => {
  const {
    red_flag_cancer_1,
    red_flag_nightpain_1,
    red_flag_urination_1,
    red_flag_weight_1,
  } = data!.patientDetails;

  // Remember to check order in RED_FLAGS
  const flags: {
    [key: string]: string;
  } = {
    red_flag_cancer_1,
    red_flag_nightpain_1,
    red_flag_weight_1,
    red_flag_urination_1,
  };

  return (
    <div className={`${className ? className : ''}`}>
      <Card>
        <CardLabel
          className="mb-8"
          text="Røde Flagg"
          size={CardLabelType.lg}
        />
        {RED_FLAGS.map((flag, index) => (
          <CardFlag
            key={flag.label + index}
            // flag={flags.some((f) => f === flag.value)}
            flag={flags[flag.key] === 'Ja'}
            text={flag.label}
            noSpacing={index === RED_FLAGS.length - 1}
          />
        ))}
      </Card>
    </div>
  );
};
