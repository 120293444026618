import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import { RedFlags } from './RedFlags';
import { Work } from './Work';
import { FunctionAndActivity } from './FunctionAndActivity';
import { GeneralHealth } from './GeneralHealth';
import { Pain } from './Pain';
import { BeliefAndThought } from './BeliefAndThought';
import { Orebro } from './Orebro';
import { MSKT } from './MSKT';
import { MSKHQ } from './MSKHQ';
import { useRouter } from 'hooks';
import { PatientResponseInfo } from 'types';
import { Helmet } from 'react-helmet';

interface OverviewProps {
  data: PatientResponseInfo;
}

export const Overview: React.FC<OverviewProps> = ({ data }) => {
  // SPA Link Tracking
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  const router = useRouter();

  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Pasientprofil`,
      href: `https://supportprim.idi.ntnu.no${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  return (
    <div className="flex flex-col -mx-3">
      <Helmet>
        <title>{`SupportPrim - Pasientprofil`}</title>
      </Helmet>
      <div className="flex">
        <RedFlags className="w-1/3 px-3" data={data} />
        <div className="flex justify-between w-2/3 mb-6">
          <MSKHQ
            data={data}
            className="w-1/3 mx-3"
            cardClassName="flex flex-col h-full"
          />
          <MSKT
            data={data}
            className="w-1/3 mx-3"
            cardClassName="flex flex-col h-full"
          />
          <Orebro
            data={data}
            className="w-1/3 mx-3"
            cardClassName="flex flex-col h-full"
          />
        </div>
      </div>

      <div className="flex">
        <div className="flex flex-col w-2/3">
          <div className="flex">
            <FunctionAndActivity
              data={data}
              className="w-1/2 px-3 mb-6"
              cardClassName="h-full w-full"
            />
            <BeliefAndThought
              data={data}
              className="w-1/2 px-3 mb-6"
              cardClassName="h-full w-full"
            />
          </div>
          <div className="flex">
            <GeneralHealth
              data={data}
              className="w-1/2 mx-3"
              cardClassName="h-full w-full"
            />
            <Work
              data={data}
              className="w-1/2 mx-3"
              cardClassName="h-full w-full"
            />
          </div>
        </div>
        <div className="flex w-1/3">
          <Pain
            data={data}
            className="flex flex-1 mx-3"
            cardClassName="flex flex-col flex-1 p-6"
          />
        </div>
      </div>
    </div>
  );
};
