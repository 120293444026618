export function arrayLength(length = 0) {
  return Array.from({ length }, (v, i) => i);
}

export function replaceScore(text: string, score: any) {
  return text.replace(
    '"actual score"',
    `<span class="font-bold">${score}</span>`,
  );
}

// Get mapped object from no or en string
export function getMappedValue(
  item: {
    [key: number]: {
      no: string;
      en: string;
    };
  },
  value?: any,
  name?: string,
) {
  const zeroMapped = {
    value: null,
    no: '',
    en: '',
  };

  if (!item) return zeroMapped;
  if (value === undefined || value === '') return zeroMapped;

  let v = isNaN(value) ? value.toLocaleLowerCase() : value;
  const mapped: any = Object.entries(item)
    .filter(
      ([key, { no, en }]) =>
        no.toLowerCase().trim() === v ||
        en.toLowerCase().trim() === v ||
        key === `${v}`,
    )
    .flat();

  return mapped.length > 0
    ? {
        value: parseInt(mapped[0], 10),
        ...mapped[1],
      }
    : zeroMapped;
}

export function cleanObject(obj: any) {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === '') {
      delete obj[key];
    }
  }

  return obj;
}

export function convertToCase(raw: any, allowed: string[]) {
  const cleanObj = cleanObject(raw);
  const filtered = Object.keys(cleanObj)
    .filter((key) => allowed.includes(key))
    .reduce((obj: { [key: string]: string }, key) => {
      obj[key] = cleanObj[key];
      return obj;
    }, {});

  return filtered;
}

export const isDev = process.env.NODE_ENV === 'development';

export function getValidEntries(obj: object) {
  return Object.entries(obj).filter(([key, value]) => value);
}

export function prescriptionReducer(arr: any, keys: string[]) {
  return arr
    .map((a: any) => {
      if (Array.isArray(a)) {
        return (
          parseInt(
            a.reduce((x, y) => x + y),
            10,
          ) || ''
        );
      }

      return a;
    })
    .map((m: any) => {
      if (Number.isInteger(m)) {
        return m <= 0 ? keys[m] : keys[m - 1] || '';
      }

      return m;
    });
}

export function variableChecker(value: any) {
  return value === undefined || (!value && value !== 0);
}

export function hasTestPermission() {
  const user = localStorage.getItem('username');
  const hasPermission = ['ui'].includes(
    `${user?.split('_')[0].toLowerCase()}`,
  );

  return hasPermission;
}

export function hasBaselinePermission(value?: any) {
  if (hasTestPermission()) return false;

  return value === undefined || `${value}`.length <= 0 ? false : true;
}

export function sortByKey(obj: { [key: string]: any }) {
  const ordered: { [key: string]: any } = {};
  Object.keys(obj)
    .sort()
    .forEach(function (key) {
      ordered[key] = obj[key];
    });
  return ordered;
}

export function convertVariableArray(value: any) {
  if (Array.isArray(value)) {
    return value.map((v: string | []) => {
      return Array.isArray(v) ? [...v].reverse()[0] : v;
    });
  }

  return value;
}

export function convertSingleVariableArray(value: string | string[]) {
  if (Array.isArray(value)) {
    const dict = ['ikke', 'noe', 'mye'];
    let sorted: string[] = [];
    [...value]
      .map((v) => v.toLocaleLowerCase())
      .forEach((v: string) => {
        sorted[
          dict.findIndex((d) => {
            return d === v.toLocaleLowerCase();
          })
        ] = v;
      });

    return [...sorted].reverse()[0];
  }
  return value;
}
