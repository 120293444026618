import { useMatomo } from '@datapunt/matomo-tracker-react';
import { CardLabel, CardLabelType } from 'components/Card';
import {
  COMPARISON_PRESCRIPE_DESCRIPTION,
  IBaselinePatient,
  MSKT_SCORE_TEXT,
  PATIENT_RESSOURCE,
  LCA_SCORE,
} from 'data';
import React from 'react';
import Linkify from 'react-linkify';
import { getMappedValue } from 'utils';
import { v4 as uuidv4 } from 'uuid';

interface ComparisonRessourceProps {
  patients: IBaselinePatient[];
  patientIndex: number;
}

export const ComparisonRessource: React.FC<ComparisonRessourceProps> =
  ({ patients, patientIndex }) => {
    const { ressources } = patients[patientIndex];

    // SPA Link Tracking
    const { enableLinkTracking, trackEvent } = useMatomo();
    enableLinkTracking();
    return (
      <div>
        {COMPARISON_PRESCRIPE_DESCRIPTION.map((c, index) => {
          if (c !== 'Behandlingsråd fenotype') return null;

          const prescriptionText =
            patients[0].prescriptionDescription[index];

          return index ===
            COMPARISON_PRESCRIPE_DESCRIPTION.length - 1 ? (
            <div className="mb-6" key={uuidv4()}>
              <CardLabel
                text={c.replace(
                  '"actual score"',
                  `: ${getMappedValue(
                    MSKT_SCORE_TEXT.mskt_risk_1,
                    patients[0].mskt,
                  ).no.toLocaleLowerCase()}`,
                )}
                size={CardLabelType.lg}
                className="mb-2"
              />

              <CardLabel
                text={prescriptionText}
                size={CardLabelType.sm}
              />
              <div className="border-b w-full border-gray-300 mt-2"></div>
            </div>
          ) : (
            <div className="mb-6" key={uuidv4()}>
              {prescriptionText && (
                <CardLabel
                  text={
                    c === 'Behandlingsråd fenotype'
                      ? `${c}: ${
                          getMappedValue(LCA_SCORE, prescriptionText)
                            .value || ''
                        }`
                      : c
                  }
                  size={CardLabelType.lg}
                  className="mb-2"
                />
              )}

              <CardLabel
                text={prescriptionText}
                size={CardLabelType.sm}
              />
            </div>
          );
        })}
        {PATIENT_RESSOURCE.map((r) => {
          const { label, variable, values } = r;
          const patientValue = (ressources || []).filter(
            (ressource) => {
              return variable
                .map((v) => v.name)
                .includes(ressource.variable);
            },
          );
          let title = label;
          let answers: any = [];

          // compare with 1 variable
          if (variable.length <= 1) {
            const { rules, comparison } = variable[0];
            if (comparison === 'greater') {
              answers = patientValue[0].value >= rules ? values : [];
            } else if (comparison === 'lesser') {
              answers = patientValue[0].value <= rules ? values : [];
            } else if (
              comparison === 'equal' &&
              Array.isArray(rules)
            ) {
              if (patientValue[0].variable === 'mskt_risk_1') {
                const valueIndex = [
                  'low',
                  'medium',
                  'high',
                ].findIndex((v) => v === patientValue[0].value);
                title = `${title}${
                  [': Lav', ': Medium', ': Høy'][valueIndex]
                }`;

                answers = rules.includes(patientValue[0].value)
                  ? [values[valueIndex]]
                  : [];
              } else {
                answers = rules.includes(patientValue[0].value)
                  ? values
                  : [];
              }
            }
          } else {
            // Find all valid mappings
            const validVariables = variable.filter((v, i) => {
              const { comparison, rules } = v;
              let keep = false;
              if (comparison === 'greater') {
                keep = patientValue[i].value >= rules;
              } else if (comparison === 'lesser') {
                keep =
                  Number.isInteger(patientValue[i].value) &&
                  patientValue[i].value <= rules;
              } else if (
                comparison === 'equal' &&
                Array.isArray(rules)
              ) {
                keep = rules.includes(patientValue[i].value);
              }
              return keep;
            });
            // Conditions for how many valid answers should be displayed
            answers = validVariables.length >= 1 ? values : [];
          }

          if (answers.length <= 0) {
            return null;
          }

          return (
            <div className="mb-6" key={uuidv4()}>
              <div className="mb-2 text-lg font-medium">
                <CardLabel text={title} />
              </div>
              <ul className="list-disc pl-4">
                {answers.map((a: string[]) =>
                  a.map((v) => (
                    <li className="mb-2 text-sm" key={uuidv4()}>
                      <Linkify
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key,
                        ) => (
                          <a
                            className="text-indigo-600"
                            target="blank"
                            href={decoratedHref}
                            key={key}
                            onClick={() => {
                              trackEvent({
                                name: `Tab Click - Patient ${
                                  ['Current', 'A', 'B', 'C'][
                                    patientIndex
                                  ]
                                } - Ressource - ${title} - ${decoratedText}`,
                                category: 'patient-matching-page',
                                action: 'click-event',
                              });
                            }}
                          >
                            {decoratedText}
                          </a>
                        )}
                      >
                        {v}
                      </Linkify>
                    </li>
                  )),
                )}
              </ul>
              {patientValue[0].variable === 'mskt_risk_1' && (
                <div className="border-b w-full border-gray-300 mt-2"></div>
              )}
            </div>
          );
        })}
      </div>
    );
  };
