import React from 'react';

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input: React.FC<InputProps> = ({ ...rest }) => {
  return (
    <input
      className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
      {...rest}
    />
  );
};
