import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { PatientDetails } from 'types';
import { BoxFields } from 'components/BoxFields';
import { BASELINE_BACK } from 'data';
import { getMappedValue } from 'utils';
import { FormInput } from 'components/Form';

interface BackProps {
  values: PatientDetails;
}

export const Back: React.FC<BackProps> = ({ values }) => {
  const {
    back_slr_1,
    back_move_1,
    back_move_pain_ext_1,
    back_move_pain_flex_1,
    back_move_pain_lat_1,
    back_move_pain_rot_1,
    back_move_pain_not_1,
    back_move_rom_ext_1,
    back_move_rom_flex_1,
    back_move_rom_lat_1,
    back_move_rom_not_1,
    back_move_rom_rot_1,
    back_neuro_not_1,
    back_neuro_pow_1,
    back_neuro_reflex_1,
    back_neuro_sens_1,
  } = values;

  // Conditional display fields
  const isBack =
    getMappedValue(BASELINE_BACK.back_move_1, back_move_1).en ===
    'Yes';

  return (
    <Card>
      <CardFormText text="Ryggundersøkelse">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Reduserte og/ eller smertefulle bevegelser i stående stilling"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{ back_move_1 }}
          keys={BASELINE_BACK.back_move_1}
        />
      </div>

      {isBack && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                back_move_rom_flex_1,
                back_move_rom_ext_1,
                back_move_rom_rot_1,
                back_move_rom_lat_1,
                back_move_rom_not_1,
              }}
              keys={BASELINE_BACK.limited}
            />
          </div>

          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              variables={{
                back_move_pain_flex_1,
                back_move_pain_ext_1,
                back_move_pain_rot_1,
                back_move_pain_lat_1,
                back_move_pain_not_1,
              }}
              keys={BASELINE_BACK.pain}
              square={true}
            />
          </div>
        </>
      )}

      <CardFormText text="Positiv Straight leg raise (Lasegues prøve)">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Prøven er positiv hvis de antatte nerverotsmertene reproduseres ved mindre enn 60º elevasjon, med utstråling nedenfor kneet"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            back_slr_1,
          }}
          keys={BASELINE_BACK.back_slr_1}
        />
      </div>

      <CardFormText text="Nevrologiske funn" />
      <div className="mb-8">
        <BoxFields
          values={values}
          keys={BASELINE_BACK.neurologic}
          variables={{
            back_neuro_not_1,
            back_neuro_pow_1,
            back_neuro_sens_1,
            back_neuro_reflex_1,
          }}
          square={true}
        />
        {/* <BoxFields
          values={values}
          keys={BASELINE_BACK.back_nevro_not_1}
          variables={{
            back_neuro_not_1,
          }}
          square={true}
        />
        <BoxFields
          values={values}
          keys={BASELINE_BACK.back_nevro_pow_1}
          variables={{
            back_neuro_pow_1,
          }}
          square={true}
        />
        <BoxFields
          values={values}
          keys={BASELINE_BACK.back_nevro_sens_1}
          variables={{
            back_neuro_sens_1,
          }}
          square={true}
        />
        <BoxFields
          values={values}
          keys={BASELINE_BACK.back_nevro_reflex_1}
          variables={{
            back_neuro_reflex_1,
          }}
          square={true}
        /> */}
      </div>
      <CardFormText text="Kommentar nevrologiske funn">
        <div className="mt-2 mb-4">
          <CardLabel text="" size={CardLabelType.sm} />
        </div>
      </CardFormText>
      <div className="mb-6">
        <FormInput
          id="back_comment_neuro_1"
          name="back_comment_neuro_1"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>

      <CardFormText text="Ev. andre kommentarer:">
        <div className="mt-2 mb-4">
          <CardLabel text="" size={CardLabelType.sm} />
        </div>
      </CardFormText>
      <div className="mb-6">
        <FormInput
          id="back_comment_1"
          name="back_comment_1"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
