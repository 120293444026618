import React from 'react';
import { arrayLength } from 'utils';
import { Field } from 'formik';
interface RatingScaleProps {
  max: number | 0;
  value: string;
  name: string;
  maxText?: string | undefined;
  minText?: string | undefined;
  disabled?: boolean;
}

export const RatingScale: React.FC<RatingScaleProps> = ({
  max,
  name,
  value,
  maxText,
  minText,
  disabled,
}) => {
  const range = arrayLength(max + 1);
  return (
    <div
      className="flex text-sm"
      role="group"
      aria-labelledby="my-radio-group"
    >
      {range.map((x, i) => (
        <div
          className={`flex flex-col items-center relative ${
            i >= 1 ? 'ml-3' : ''
          }`}
          key={`${i}-${x}`}
        >
          <Field
            className={`form-radio rounded-full w-8 h-8 border-2 mb-1 border-gray-500 ${
              disabled
                ? 'cursor-not-allowed bg-gray-100'
                : 'hover:bg-indigo-500 hover:border-transparent cursor-pointer'
            }`}
            type="radio"
            name={name}
            value={i}
            checked={parseInt(value, 10) === i}
            disabled={disabled}
          />
          <div className="mb-12">{x}</div>
          {x <= 0 && (
            <div className="absolute bottom-0 text-sm text-center">
              {minText}
            </div>
          )}
          {x >= max && (
            <div className="absolute bottom-0 text-sm text-center">
              {maxText}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
