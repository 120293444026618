import React from 'react';
import { RatingScale } from 'components/RatingScale';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { FormInput } from 'components/Form';
import { PatientDetails } from 'types';
import { hasBaselinePermission } from 'utils';

interface PSFSProps {
  values: PatientDetails;
  initialValues: PatientDetails;
}

export const PSFS: React.FC<PSFSProps> = ({
  values,
  initialValues,
}) => {
  return (
    <Card>
      <CardFormText text="Pasientspesifikk funksjonsskala">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Beskriv en viktig aktivitet som du har problemer med å utføre eller ikke kan utføre i det hele tatt på grunn av plagene du søker behandling for . Angi så det sifferet på skalaen som svarer til hvor vanskelig du synes det er å utføre aktiviteten."
            size={CardLabelType.sm}
          />
        </div>
        <CardLabel
          text="0 = Kan ikke utføre aktiviteten, 10 = kan utføre aktiviteten uten vansker"
          size={CardLabelType.sm}
        />
      </CardFormText>
      <div className="mb-6">
        <CardLabel text="Aktivitet 1" size={CardLabelType.lg} />
      </div>
      <div className="mb-6">
        <FormInput
          id="psfs1_act_1"
          name="psfs1_act_1"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
          disabled={hasBaselinePermission(initialValues.psfs1_act_1)}
        />
      </div>
      <div className="mb-6">
        <RatingScale
          max={10}
          name="psfs1_1"
          value={`${values.psfs1_1}`}
          maxText="Kan Utføre"
          minText="Kan ikke"
          disabled={hasBaselinePermission(initialValues.psfs1_1)}
        />
      </div>
    </Card>
  );
};
