import React from 'react';

import { BoxField } from 'components/BoxField';
import { PatientDetails } from 'types';

interface BoxFieldsProps {
  variables: any;
  keys: any;
  square?: boolean;
  values: PatientDetails;
  isValueIndex?: boolean; // if value is an index/number
}

export const BoxFields: React.FC<BoxFieldsProps> = ({
  variables,
  keys,
  square,
  values,
  isValueIndex,
}) => {
  return (
    <>
      {Object.entries(keys).map(
        ([key, value]: any, index: number) => {
          const variableKeys = Object.keys(variables);
          const currentValue =
            variableKeys.length <= 1
              ? values[variableKeys[0]]
              : values[variableKeys[index]];

          // const checked = value;

          const checked =
            value.no.toLowerCase() ===
            (`${currentValue}` || '').toLowerCase();

          const checkedIndex =
            parseInt(key) === parseInt(currentValue);

          if (square) {
            // Check if array/string matches field value
            // const squareChecked =
            //   value.no.toLowerCase() ===
            //   (
            //     (Array.isArray(currentValue)
            //       ? currentValue[0]
            //       : currentValue || '') || ''
            //   ).toLowerCase();
            const squareChecked = currentValue;

            if (key.length <= 1) {
              return (
                <BoxField
                  key={key}
                  type="checkbox"
                  name={variableKeys[index] || variableKeys[0]}
                  value={isValueIndex ? parseInt(key) : value.no}
                  checked={
                    isValueIndex ? checkedIndex : squareChecked
                  }
                  text={value.no}
                  square={square}
                />
              );
            } else {
              return (
                <BoxField
                  key={key}
                  type="checkbox"
                  name={variableKeys[index] || variableKeys[0]}
                  value={variables[variableKeys[index]] || false}
                  text={value.no}
                  checked={squareChecked}
                  square={square}
                />
              );
            }
          }

          return (
            <BoxField
              key={key}
              type="radio"
              name={variableKeys[index] || variableKeys[0]}
              value={isValueIndex ? parseInt(key) : value.no}
              checked={isValueIndex ? checkedIndex : checked}
              text={value.no}
            />
          );
        },
      )}
    </>
  );
};
