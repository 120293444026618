import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';

export const UserContext = createContext();

export default function UserContextComp({ children }) {
  const [patientIndex, setPatientIndex] = useState(0);

  useEffect(() => {}, []);

  return (
    <UserContext.Provider
      value={{
        patientIndex,
        setPatientIndex,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

// Custom hook that shorhands the context!
export const useUser = () => useContext(UserContext);
