import React from 'react';
import { CardLabel, CardLabelType, Card } from 'components/Card';
import { GROUP } from 'data';
import { HoverContent } from 'components/HoverContent';
import { replaceScore, variableChecker } from 'utils';
import { VerticalBar } from 'components/Charts';
import { PatientResponseInfo } from 'types';
import { CopyText } from 'components/CopyText';

interface MSKHQProps {
  className?: String;
  cardClassName?: String;
  data: PatientResponseInfo;
}

export const MSKHQ: React.FC<MSKHQProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const { mskhq_score_1 } = patientDetails;

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <div className="flex flex-col flex-1 items-center justify-center">
          <HoverContent
            content={replaceScore(
              GROUP.mskhq_score.mskhq_score_1.hover.no,
              mskhq_score_1 || '',
            )}
          >
            <CopyText
              content={replaceScore(
                GROUP.mskhq_score.mskhq_score_1.hover.no,
                mskhq_score_1 || '',
              )}
            >
              <div className="flex flex-col items-center justify-center h-full ">
                <div className="inline-block h-20 mb-4 relative">
                  <VerticalBar
                    progress={mskhq_score_1}
                    max={56}
                    indicators={[29, 37]}
                  />
                  <span className="absolute top-0 ml-6 text-sm">
                    God
                  </span>
                  <span className="absolute bottom-0 ml-6 text-sm">
                    Dårlig
                  </span>
                </div>
                <CardLabel
                  text={GROUP.mskhq_score.label.no}
                  size={CardLabelType.lg}
                  className={`${
                    variableChecker(mskhq_score_1)
                      ? 'opacity opacity-25'
                      : ''
                  }`}
                />
              </div>
            </CopyText>
          </HoverContent>
        </div>
      </Card>
    </div>
  );
};
