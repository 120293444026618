import React from 'react';

interface FormErrorProps {
  error: {
    message: string;
  } | null;
}

export const FormError: React.FC<FormErrorProps> = ({ error }) => {
  return (
    error && (
      <div className="mt-2 text-center">
        <small className="text-red-500 text-xs italic">
          {error.message
            ? error.message.replace('GraphQL error: ', '')
            : error}
        </small>
      </div>
    )
  );
};
