import React from 'react';
import { CardLabel, CardLabelType } from 'components/Card';
import {
  COMPARISON_PRESCRIPE_DESCRIPTION,
  IBaselinePatient,
  MSKT_SCORE_TEXT,
} from 'data';
import { getMappedValue } from 'utils';

interface ComparisonPrescriptionProps {
  patients: IBaselinePatient[];
  patientIndex: number;
}

export const ComparisonPrescription: React.FC<ComparisonPrescriptionProps> = ({
  patients,
  patientIndex,
}) => {
  return (
    <>
      {COMPARISON_PRESCRIPE_DESCRIPTION.map((c, index) => {
        if (index >= 3) return null;

        if (!patients[patientIndex].prescriptionDescription[index])
          return null;

        return index ===
          COMPARISON_PRESCRIPE_DESCRIPTION.length - 1 ? (
          <div className="mb-6">
            <div className="border-b w-full border-gray-300 mb-2"></div>
            <CardLabel
              text={c.replace(
                '"actual score"',
                ` - ${getMappedValue(
                  MSKT_SCORE_TEXT.mskt_risk_1,
                  patients[patientIndex].mskt,
                ).no.toLocaleLowerCase()}`,
              )}
              size={CardLabelType.lg}
              className="mb-2"
            />

            <CardLabel
              text={
                patients[patientIndex].prescriptionDescription[index]
              }
              size={CardLabelType.sm}
            />
          </div>
        ) : (
          <div className="mb-6">
            <CardLabel
              text={c.replace(
                '"actual score"',
                ` - ${getMappedValue(
                  MSKT_SCORE_TEXT.mskt_risk_1,
                  patients[patientIndex].mskt,
                ).no.toLocaleLowerCase()}`,
              )}
              size={CardLabelType.lg}
              className="mb-2"
            />

            <CardLabel
              text={
                patients[patientIndex].prescriptionDescription[index]
              }
              size={CardLabelType.sm}
            />
          </div>
        );
      })}
    </>
  );
};
