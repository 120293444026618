import React from 'react';

import { Card } from 'components/Card';
import { Tabs } from 'components/Tabs';
import { IBaselinePatient } from 'data';
import { ComparisonRadar } from './ComparisonRadar';
import { ComparisonProgress } from './ComparisonProgress';
import { ComparisonRessource } from './ComparisonRessource';

interface ComparisonCommonProps {
  patients: IBaselinePatient[];
  legendIndex: boolean[];
  handleLegendClick: (i: number) => void;
}

export const ComparisonCommon: React.FC<ComparisonCommonProps> = ({
  patients,
  legendIndex,
  handleLegendClick,
}) => {
  return (
    <div style={{ height: '200px' }}>
      <Card>
        <Tabs
          className="w-full mb-8"
          tabs={[
            {
              id: 'all-radar',
              content: 'Pasientprofiler',
              panelID: 'all-radar-content',
              info: 'Figuren viser pasientens score på 10 ulike faktorer. En skår som ligger inn mot midten av figuren betyr en god skår og dårligere skår desto lengre ut.',
            },
            patients.length > 1
              ? {
                  id: 'all-graphs',
                  content: 'Forløp',
                  panelID: 'all-graphs-content',
                  info: 'Figurene viser pasientens svar på ulike faktorer gjennom det kliniske forløpet fra oppstart til 3 måneder.',
                }
              : {
                  id: 'patient-ressource',
                  content: 'Ressurser',
                  panelID: 'patient-ressource-content',
                },
          ]}
        >
          <ComparisonRadar
            patients={patients}
            legendIndex={legendIndex}
            handleLegendClick={handleLegendClick}
          />
          {patients.length > 1 ? (
            <ComparisonProgress
              patients={patients}
              legendIndex={legendIndex}
              handleLegendClick={handleLegendClick}
            />
          ) : (
            <ComparisonRessource
              patients={patients}
              patientIndex={0}
            />
          )}
        </Tabs>
      </Card>
    </div>
  );
};
