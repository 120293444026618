import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { PatientDetails } from 'types';
import { BoxFields } from 'components/BoxFields';
import { BASELINE_SHOULDER } from 'data';
import { getMappedValue } from 'utils';
import { FormInput } from 'components/Form';

interface ShoulderProps {
  values: PatientDetails;
}

export const Shoulder: React.FC<ShoulderProps> = ({ values }) => {
  const {
    shoulder_passive_extrot_1,
    shoulder_painfularc_1,
    shoulder_hawkins_1,
    shoulder_iso_abdpain_1,
    shoulder_iso_extrotpain_1,
    shoulder_iso_abdpow_1,
    shoulder_iso_extrotpow_1,
    shoulder_move_1,
    shoulder_move_pain_flex_1,
    shoulder_move_pain_abd_1,
    shoulder_move_pain_add_1,
    shoulder_move_pain_extrot_1,
    shoulder_move_pain_introt_1,
    shoulder_move_pain_not_1,
    shoulder_move_rom_flex_1,
    shoulder_move_rom_abd_1,
    shoulder_move_rom_add_1,
    shoulder_move_rom_extrot_1,
    shoulder_move_rom_introt_1,
    shoulder_move_rom_not_1,
  } = values;

  // Conditional display fields
  const isShoulder =
    getMappedValue(
      BASELINE_SHOULDER.shoulder_move_active_1,
      shoulder_move_1,
    ).en === 'Yes';

  return (
    <Card>
      <CardFormText text="Skulderundersøkelse">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Reduserte og/eller smertefulle aktive bevegelsesutslag"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <BoxFields
        values={values}
        variables={{ shoulder_move_1 }}
        keys={BASELINE_SHOULDER.shoulder_move_active_1}
      />
      {isShoulder && (
        <>
          <div className="mb-5">
            <CardLabel
              text="Reduserte bevegelsesutslag i"
              size={CardLabelType.lg}
            />
          </div>
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                shoulder_move_rom_flex_1,
                shoulder_move_rom_abd_1,
                shoulder_move_rom_add_1,
                shoulder_move_rom_extrot_1,
                shoulder_move_rom_introt_1,
                shoulder_move_rom_not_1,
              }}
              keys={BASELINE_SHOULDER.limited}
            />
          </div>

          <div className="mb-5">
            <CardLabel text="Smerter ved" size={CardLabelType.lg} />
          </div>
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                shoulder_move_pain_flex_1,
                shoulder_move_pain_abd_1,
                shoulder_move_pain_add_1,
                shoulder_move_pain_extrot_1,
                shoulder_move_pain_introt_1,
                shoulder_move_pain_not_1,
              }}
              keys={BASELINE_SHOULDER.pain}
            />
          </div>
        </>
      )}
      <CardFormText text="Redusert passiv utadrotasjon">
        <div className="mt-2 mb-4">
          <CardLabel
            text="mer enn 50% nedsatt sammenlignet med motsatt side"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{ shoulder_passive_extrot_1 }}
          keys={BASELINE_SHOULDER.shoulder_passive_extrot_1}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Smertebue">
          <div className="mt-2 mb-4">
            <CardLabel
              text="smerter i området ca 60-120 grader"
              size={CardLabelType.sm}
            />
          </div>
        </CardFormText>
        <BoxFields
          values={values}
          variables={{ shoulder_painfularc_1 }}
          keys={BASELINE_SHOULDER.shoulder_painfularc_1}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Smerter ved Hawkins test" />
        <BoxFields
          values={values}
          variables={{ shoulder_hawkins_1 }}
          keys={BASELINE_SHOULDER.shoulder_hawkins_1}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Smerter ved isometrisk abduksjon" />
        <BoxFields
          values={values}
          variables={{ shoulder_iso_abdpain_1 }}
          keys={BASELINE_SHOULDER.shoulder_iso_abdpain_1}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Smerter ved isometrisk utadrotasjon" />
        <BoxFields
          values={values}
          variables={{ shoulder_iso_extrotpain_1 }}
          keys={BASELINE_SHOULDER.shoulder_iso_extrotpain_1}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Nedsatt kraft ved isometrisk abduksjon" />
        <BoxFields
          values={values}
          variables={{ shoulder_iso_abdpow_1 }}
          keys={BASELINE_SHOULDER.shoulder_iso_abdpow_1}
        />
      </div>
      <div className="mb-8">
        <CardFormText text="Nedsatt kraft ved isometrisk utadrotasjon" />
        <BoxFields
          values={values}
          variables={{ shoulder_iso_extrotpow_1 }}
          keys={BASELINE_SHOULDER.shoulder_iso_extrotpow_1}
        />
      </div>
      <div className="mb-6">
        <CardLabel text="Ev. kommentarer:" size={CardLabelType.lg} />
      </div>
      <div className="mb-6">
        <FormInput
          id="shoulder_comment_1"
          name="shoulder_comment_1"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
