import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardProgressBar,
  CardAnswers,
} from 'components/Card';
import {
  GROUP,
  COMORBIDITIES,
  SLEEP,
  VITALITY,
  PROBLEM_ANXIETY_DEPRESSION,
  GENERAL_HEALTH,
} from 'data';
import { getMappedValue, replaceScore, variableChecker } from 'utils';
import { HoverContent } from 'components/HoverContent';
import { PatientResponseInfo } from 'types';
import { CopyText } from 'components/CopyText';

interface GeneralHealthProps {
  className?: String;
  cardClassName?: String;
  data: PatientResponseInfo;
}

export const GeneralHealth: React.FC<GeneralHealthProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const {
    qol15d_q5_sleep_1,
    qol15d_q14_vital_1,
    eq5d_depr_1,
    como_asthma_1,
    como_cancer_1,
    como_diabetes_1,
    como_fatigue_1,
    como_headache_1,
    como_heart_1,
    como_osteoporosis_1,
    como_other_1,
    como_other_spec_1,
    como_psych_1,
    como_ra_1,
    hscl_score_1,
    como_stomach_pain_1,
  } = patientDetails;

  const mapped_qol15d_q5_sleep_1 = getMappedValue(
    SLEEP.qol15d_q5_sleep_1,
    qol15d_q5_sleep_1,
  );
  const mapped_qol15d_q14_vital_1 = getMappedValue(
    VITALITY.qol15d_q14_vital_1,
    qol15d_q14_vital_1,
  );
  const mapped_eq5d_depr_1 = getMappedValue(
    PROBLEM_ANXIETY_DEPRESSION.eq5d_depr_1,
    eq5d_depr_1,
  );

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <CardLabel
          className="mb-8"
          text={GROUP.general.title.no}
          size={CardLabelType.lg}
        />

        <HoverContent
          content={replaceScore(
            GROUP.general.qol15d_q5_sleep_1.hover.no,
            mapped_qol15d_q5_sleep_1.no,
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.general.qol15d_q5_sleep_1.hover.no,
              mapped_qol15d_q5_sleep_1.no,
            )}
          >
            <CardProgressBar
              text={GROUP.general.qol15d_q5_sleep_1.label.no}
              progress={mapped_qol15d_q5_sleep_1.value}
              parseProgressValue={(value) => (value - 1) * (100 / 4)}
              min={1}
              median={3}
              max={5}
              indicators={[2, 3]}
              disabled={variableChecker(
                mapped_qol15d_q5_sleep_1.value,
              )}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            GROUP.general.qol15d_q14_vital_1.hover.no,
            mapped_qol15d_q14_vital_1.no,
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.general.qol15d_q14_vital_1.hover.no,
              mapped_qol15d_q14_vital_1.no,
            )}
          >
            <CardProgressBar
              text={GROUP.general.qol15d_q14_vital_1.label.no}
              progress={mapped_qol15d_q14_vital_1.value}
              parseProgressValue={(value) => (value - 1) * (100 / 4)}
              min={1}
              median={3}
              max={5}
              indicators={[2, 3]}
              disabled={variableChecker(
                mapped_qol15d_q14_vital_1.value,
              )}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={`${replaceScore(
            GROUP.general.Hscl10_score_1.hover.no,
            hscl_score_1,
          )}${GENERAL_HEALTH.Hscl10_score_1.hover.no}`}
        >
          <CopyText
            content={replaceScore(
              GROUP.general.Hscl10_score_1.hover.no,
              hscl_score_1,
            )}
          >
            <CardProgressBar
              text={GROUP.general.Hscl10_score_1.label.no}
              progress={hscl_score_1}
              parseProgressValue={(value) => (value - 1) * (100 / 4)}
              min={1}
              max={4}
              indicators={[1.7, 2]}
              disabled={variableChecker(hscl_score_1)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            GROUP.general.eq5d_depr_1.hover.no,
            mapped_eq5d_depr_1.no,
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.general.eq5d_depr_1.hover.no,
              mapped_eq5d_depr_1.no,
            )}
          >
            <CardProgressBar
              text={GROUP.general.eq5d_depr_1.label.no}
              progress={mapped_eq5d_depr_1.value}
              parseProgressValue={(value) => (value - 1) * (100 / 4)}
              min={1}
              median={3}
              max={5}
              indicators={[2, 3]}
              disabled={variableChecker(mapped_eq5d_depr_1)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={GROUP.general.comorbidities.hover.no}
          className="inline-block"
        >
          <CopyText
            content={`${
              GROUP.general.comorbidities.hover.no
            }: ${Object.entries({
              como_asthma_1,
              como_cancer_1,
              como_diabetes_1,
              como_fatigue_1,
              como_headache_1,
              como_heart_1,
              como_osteoporosis_1,
              como_other_1,
              como_other_spec_1,
              como_psych_1,
              como_ra_1,
              como_stomach_pain_1,
            })
              .map(([key, value]) => {
                return (
                  (value && COMORBIDITIES[key]) ||
                  (key === 'como_other_spec_1' && value)
                );
              })
              .filter((p) => p)}`}
          >
            <CardAnswers text={GROUP.general.comorbidities.label.no}>
              {Object.entries({
                como_asthma_1,
                como_cancer_1,
                como_diabetes_1,
                como_fatigue_1,
                como_headache_1,
                como_heart_1,
                como_osteoporosis_1,
                como_other_1,
                como_other_spec_1,
                como_psych_1,
                como_ra_1,
                como_stomach_pain_1,
              }).map(([key, value]) => (
                <div className="mb-1" key={key}>
                  {(value && COMORBIDITIES[key]) ||
                    (key === 'como_other_spec_1' && value)}
                </div>
              ))}
              {/* <div>Revmatisk Sykdom</div>
            <div>Osteoporose</div>
            <div>Revmatisk Sykdom</div> */}
            </CardAnswers>
          </CopyText>
        </HoverContent>
      </Card>
    </div>
  );
};
