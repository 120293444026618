export interface IWork {
  education: string;
  employment: string;
  work_ability_1: number;
  Work_type_1: string;
}

export interface IGeneralHealth {
  comorbidities: [];
  qol15d_q5_sleep_1: number;
  qol15d_q14_vital_1: number;
  Hscl10_score_1: number;
  eq5d_depr_1: number;
}

export interface IFunctionAndActivity {
  Activity_1: number;
  Mskhq_q7: number;
  Mskhq_pa_1: number;
}

export interface IBeliefAndThought {
  oreb_q10_1: number;
  pseq_score_1: number;
  Oreb_q7_1: number;
}

export interface IPain {
  oreb_q2_1: number;
  oreb_q1_1: number;
  Painsite_1: string[];
}

export interface IPatient {
  title: string;
  birthYear: number;
  sex: string;
  smoking: string;
  bmi: number;
  redFlags: string[];
  orebro: number;
  mskt: number;
  mskhq: number;
  phenotype: number;
  mainProblem: string;
  sympton: string;
  warning: boolean;
  warningValues: {
    [key: string]: any;
  };
  fill: boolean;
  radar: {
    [key: string]: any;
  };
  graphs: {
    [key: string]: any;
  };
  info: {
    practioner: string;
    type: string[];
    continuationOfTreament: string;
    endOfTreatment: string[];
    consultation: number;
    exerciseProgress: string;
  };
  prescription: {
    [key: string]: any[];
  };
  prescriptionDescription: string[];
  backgroundColor: string;
  borderColor: string;
  pointBackgroundColor: string;
  pointBorderColor: string;
  pointHoverBackgroundColor: string;
  pointHoverBorderColor: string;
}

export interface IBaselinePatient extends IPatient {
  id?: string;
  work: IWork;
  generalHealth: IGeneralHealth;
  functionAndActivity: IFunctionAndActivity;
  beliefAndThought: IBeliefAndThought;
  pain: IPain;
  similarPatients?: { [key: string]: any };
  ressources?: any[];
}

export const BASELINE_PATIENT: IBaselinePatient = {
  title: 'Aktuell Pasient',
  birthYear: 1980,
  sex: 'Mann',
  smoking: 'nei',
  bmi: 25,
  redFlags: ['Store nattsmerter', 'Uforklarlig Vekttap / Feber'],
  orebro: 50,
  mskt: 5,
  phenotype: 2,
  mskhq: 25,
  work: {
    education: 'Mer enn 4 år høyere utdanning',
    employment: 'Ulønnet arbeid',
    work_ability_1: 9,
    Work_type_1: 'Arbeid hvor du går og løfter mye',
  },
  generalHealth: {
    comorbidities: [],
    qol15d_q5_sleep_1: 3,
    qol15d_q14_vital_1: 1,
    Hscl10_score_1: 1.4,
    eq5d_depr_1: 1,
  },
  functionAndActivity: {
    Activity_1: 4,
    Mskhq_q7: 4,
    Mskhq_pa_1: 3,
  },
  beliefAndThought: {
    oreb_q10_1: 8,
    pseq_score_1: 4,
    Oreb_q7_1: 9,
  },
  pain: {
    oreb_q2_1: 7,
    oreb_q1_1: 9,
    Painsite_1: ['back', 'neck', 'shoulder'],
  },
  mainProblem: 'Rygg',
  sympton: 'L03 Uspesifikk rygg',
  warning: false,
  warningValues: {
    neck_spurling_1: 1,
    neck_nevro_pow_1: 1,
    neck_nevro_sens_1: 1,
    neck_nevro_reflex_1: 1,
  },
  fill: false,
  radar: {
    'Pain intensity': 70,
    // 'Continuous pain': 100,
    'Pain sites': 30,
    'Pain duration': 80,
    'Recovery expectations': 90,
    'Pain self-efficacy': 66.7,
    'Fear avoidance': 80,
    'Mentral distress': 33.3,
    'Sleep problems': 50,
    'Work ability': 10,
    'Daily activity level': 0,
  },
  graphs: {
    Funksjon: [],
    Arbeidsevne: [],
    Smerte: [],
    Mestring: [],
    'Generell bedring': [],
  },
  info: {
    practioner: '',
    type: [],
    continuationOfTreament: '',
    endOfTreatment: [''],
    consultation: 0,
    exerciseProgress: '',
  },
  prescription: {
    'Råd og veiledning': [],
    'Aktive øvelser og tiltak': [],
    Hjemmeøvelser: [],
    'Passive tiltak': [],
    'Pasienten er drøftet med': [],
    'Kontakt med andre under behandlingen': [],
  },
  prescriptionDescription: ['', '', '', ''],
  backgroundColor: 'rgba(255, 99, 132, 0)',
  borderColor: 'rgb(0, 0, 0)',
  pointBackgroundColor: 'rgb(0, 0, 0)',
  pointBorderColor: '#fff',
  pointHoverBackgroundColor: '#fff',
  pointHoverBorderColor: 'rgb(0, 0, 0)',
};

export const PATIENTS: IPatient[] = [
  {
    ...BASELINE_PATIENT,
  },
  {
    title: 'Pasient A',
    birthYear: 1970,
    sex: 'Kvinne',
    smoking: 'Nei',
    bmi: 28,
    redFlags: [],
    orebro: 45,
    mskt: 5,
    phenotype: 2,
    mskhq: 20,
    mainProblem: 'Rygg',
    sympton: 'L02 Lumbago',
    warning: false,
    warningValues: {},
    fill: false,
    radar: {
      'Pain intensity': 60,
      // 'Continuous pain': 100,
      'Pain sites': 20,
      'Pain duration': 80,
      'Recovery expectations': 80,
      'Pain self-efficacy': 66.7,
      'Fear avoidance': 50,
      'Mentral distress': 33.3,
      'Sleep problems': 50,
      'Work ability': 0,
      'Daily activity level': 33.3,
    },
    graphs: {
      Funksjon: [1, 2, 3, 6, 7, 10, 10],
      Arbeidsevne: [10, 10, 10, 10, 10, 10, 10],
      Smerte: [6, null, 5, null, null, 3, 1],
      Mestring: [11, null, 12, null, null, 12, 12],
      'Generell bedring': [null, null, 4, null, 3, 2, 1],
    },
    info: {
      practioner: 'Allmenn fysioterapeut',
      type: ['Individuell behandling; Egentrening'],
      continuationOfTreament: 'Behandlingen fortsatte utover 3 mnd',
      endOfTreatment: [''],
      consultation: 10,
      exerciseProgress: 'Veldig bra',
    },
    prescription: {
      'Råd og veiledning': [
        'some',
        '',
        'much',
        'much',
        '',
        'much',
        '',
        '',
        '',
        'some',
      ],
      'Aktive øvelser og tiltak': [
        'some',
        '',
        '',
        '',
        '',
        '',
        'some',
      ],
      Hjemmeøvelser: ['some'],
      'Passive tiltak': ['', '', '', ''],
      'Pasienten er drøftet med': ['yes', '', '', 'yes', ''],
      'Kontakt med andre under behandlingen': ['', '', '', '', ''],
    },
    prescriptionDescription: [
      'At fysioterapeuten fikk meg til å tørre å løfte igjen. Gikk fra å tro at ryggen min var ødelagt, til at jeg nå kan løfte det jeg vil.',
      'Lang tid på forventningsavklaring og alliansebygging ved første og andre konsultasjon.',
      'Pasienten har vært gjennom konsultasjoner ca annenhver uke. I starten brukte vi mye tid på å avklare forventninger, forklare smerter og bygge realistiske målsetninger. Vi gjennomgikk MR-bilder og pasienten fikk en forståelse av hva som er normalfunn og at man ikke kan se smerter på bilder. Stort fokus på pasientens sterke sider og hva han likte å gjøre. Dette ble grunnlaget for hjemmetreningen og hvilke mål han satte seg. Pasienten fikk spesifikke oppgaver for å bedre søvnen og dette var å legge seg og stå opp til samme tid hver kveld, legge treningen tidligere på ettermiddagen og å dempe belysningen kl 2100. 2 spesifikke treningsøvelser ble valgt etter pasientens ønske. Disse ble gjennomført 3 ganger i uka. Startet i avlastet stilling, gikk videre til å jobbe mot tyngekraft og har etter hvert lagt på ekstern vektbelastning. Opplever mestring og trygging ved dette. Fortsetter fysioterapi her framover i ca en mnd.',
      'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc.',
    ],
    backgroundColor: 'rgba(255, 99, 132, 0)',
    borderColor: 'rgb(255, 99, 132)',
    pointBackgroundColor: 'rgb(255, 99, 132)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(255, 99, 132)',
  },
  {
    title: 'Pasient B',
    birthYear: 1985,
    sex: 'Mann',
    smoking: 'nei',
    bmi: 30,
    redFlags: [],
    orebro: 55,
    mskt: 5,
    phenotype: 2,
    mskhq: 30,
    mainProblem: 'Nakke',
    sympton: 'L01 Myalgi',
    warning: true,
    warningValues: {
      neck_spurling_1: 1,
      neck_nevro_pow_1: 1,
      neck_nevro_sens_1: 1,
      neck_nevro_reflex_1: 1,
    },
    fill: false,
    radar: {
      'Pain intensity': 40,
      // 'Continuous pain': 0,
      'Pain sites': 20,
      'Pain duration': 80,
      'Recovery expectations': 90,
      'Pain self-efficacy': 75,
      'Fear avoidance': 90,
      'Mentral distress': 66.7,
      'Sleep problems': 50,
      'Work ability': 10,
      'Daily activity level': 33.3,
    },
    graphs: {
      Funksjon: [2, 3, 3, 4, 6, 8, 8],
      Arbeidsevne: [9, 9, 9, 9, 10, 10, 10],
      Smerte: [4, null, 4, null, null, 3, 0],
      Mestring: [3, null, 5, null, null, 8, 8],
      'Generell bedring': [null, null, 3, null, 3, 2, 2],
    },
    info: {
      practioner: 'Allmenn fysioterapeut',
      type: ['Gruppebehandling; Egentrening'],
      continuationOfTreament: '',
      endOfTreatment: ['Behov opphørt'],
      consultation: 4,
      exerciseProgress: 'Ganske bra',
    },
    prescription: {
      'Råd og veiledning': [
        '',
        'much',
        'some',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
      'Aktive øvelser og tiltak': ['', 'some', '', '', '', 'much'],
      Hjemmeøvelser: ['much'],
      'Passive tiltak': ['', '', '', '', ''],
      'Pasienten er drøftet med': ['', '', '', 'yes', ''],
      'Kontakt med andre under behandlingen': ['', '', '', ''],
    },
    prescriptionDescription: [
      'At pasienten ble med på at dette ikke var en quick fix, men fokus på mestring og tilbakegang til normal aktivitet',
      'Grundig undersøkelse og avklaring av diagnose. Pasienten fikk delta aktivt i valg av behandlingsretning. Ønsket å øke sin forståelse for hva dette var og hvorfor hun hadde fått det. Vi jobbet kognitivt med forventninger, frykt og smerter. Benyttet retrainpain.org som hjemmeverktøy. PAsienten ønsket å gjennomøfra ktive øvelser hjemme. Øvelser hun kjenner godt frra før, men som man nå har hatt bedre kontinuitet på. Fokus på søvn, ikke sitte oppe å se på tv, men heller ta seg et bad og legge seg å lese. Avsluttet etter ca 2 mnd, følte hun hadde verktøyene for å fortsette på egen hånd.',
      'Egentrening veiledet av terapeut',
      'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc.',
    ],
    backgroundColor: 'rgba(54, 162, 235, 0)',
    borderColor: 'rgb(54, 162, 235)',
    pointBackgroundColor: 'rgb(54, 162, 235)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(54, 162, 235)',
  },
  {
    title: 'Pasient C',
    birthYear: 1982,
    sex: 'Kvinne',
    smoking: 'nei',
    bmi: 24,
    redFlags: [],
    orebro: 60,
    mskt: 9,
    phenotype: 2,
    mskhq: 31,
    mainProblem: 'Utbredte plager/ sammensatt',
    sympton: 'L18 Utbredte smerter',
    warning: false,
    warningValues: {},
    fill: false,
    radar: {
      'Pain intensity': 80,
      // 'Continuous pain': 100,
      'Pain sites': 60,
      'Pain duration': 100,
      'Recovery expectations': 100,
      'Pain self-efficacy': 91.7,
      'Fear avoidance': 30,
      'Mentral distress': 66.7,
      'Sleep problems': 75,
      'Work ability': 40,
      'Daily activity level': 66.7,
    },
    graphs: {
      Funksjon: [0, 2, 2, 2, 3, 6, 8],
      Arbeidsevne: [6, 6, 7, 7, 8, 8, 9],
      Smerte: [8, null, 7, null, null, 5, 3],
      Mestring: [1, null, 2, null, null, 4, 5],
      'Generell bedring': [null, null, 5, null, 4, 3, 2],
    },
    info: {
      practioner: 'Psykomotoriker',
      type: ['Individuell behandling; Egentrening'],
      continuationOfTreament: 'Behandlingen fortsatte utover 3 mnd',
      endOfTreatment: [''],
      consultation: 6,
      exerciseProgress: 'Ikke hatt øvelser',
    },
    prescription: {
      'Råd og veiledning': [
        'some',
        '',
        'some',
        '',
        'some',
        'some',
        'some',
        '',
        'much',
        'some',
      ],
      'Aktive øvelser og tiltak': [
        '',
        '',
        'some',
        '',
        'some',
        'some',
      ],
      Hjemmeøvelser: ['much'],
      'Passive tiltak': ['much', '', 'some', '', ''],
      'Pasienten er drøftet med': ['yes', '', '', 'yes', ''],
      'Kontakt med andre under behandlingen': ['', '', '', 'yes'],
    },
    prescriptionDescription: [
      'At pasienten innså at hun måtte bruke tid til seg selv og ikke bare bruke tid på alt annet',
      'Psykoeduskasjon, veiledning av strategier for smertemestring og frykt for aktivitet som gjør vondt. Introduksjon av alternative strategier. Fokus i behandlingen har vært pust og pasienten har gjennomført aktive øvelser i dette og fått konkrete oppgaver i hverdagen. Hjemmeøvelser har vært mye vektlagt og man har gitt større og større oppgaver for å praktisere mellom timene. Sortering i tanker og følelser og betyndingen av disse for smerten som varierer i kroppen. Pasienten har også økt sin daglige aktivitet, med mer lystbetont trening og mindre intrevalltrening på treningssenter. ',
      'Avspenning og sortering av utfordringer.',
      'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc. Vurder tverrfaglig samarbeid, viderehenvisning, smertemestring, henvise spesialisthelsetjenesten, vurder henvisning billedtaking',
    ],
    backgroundColor: 'rgba(54, 162, 235, 0)',
    borderColor: 'rgb(54, 225, 235)',
    pointBackgroundColor: 'rgb(54, 225, 235)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(54, 225, 235)',
  },
];

export const PATIENT_TEMPLATE_BASE = {
  birthYear: 1980,
  sex: 'Mann',
  smoking: 'nei',
  bmi: 25,
  redFlags: [],
  orebro: 50,
  mskt: 5,
  phenotype: 2,
  mskhq: 25,
  work: {
    education: 'Mer enn 4 år høyere utdanning',
    employment: 'Ulønnet arbeid',
    work_ability_1: 9,
    Work_type_1: 'Arbeid hvor du går og løfter mye',
  },
  generalHealth: {
    comorbidities: [] as [],
    qol15d_q5_sleep_1: 3,
    qol15d_q14_vital_1: 1,
    Hscl10_score_1: 1.4,
    eq5d_depr_1: 1,
  },
  functionAndActivity: {
    Activity_1: 4,
    Mskhq_q7: 4,
    Mskhq_pa_1: 3,
  },
  beliefAndThought: {
    oreb_q10_1: 8,
    pseq_score_1: 4,
    Oreb_q7_1: 9,
  },
  pain: {
    oreb_q2_1: 7,
    oreb_q1_1: 9,
    Painsite_1: ['back', 'neck', 'shoulder'],
  },
  mainProblem: 'Rygg',
  sympton: 'L03 Uspesifikk rygg',
  warning: false,
  warningValues: {},
  fill: false,
  radar: {
    'Pain intensity': 70,
    // 'Continuous pain': 100,
    'Pain sites': 30,
    'Pain duration': 80,
    'Recovery expectations': 90,
    'Pain self-efficacy': 66.7,
    'Fear avoidance': 80,
    'Mentral distress': 33.3,
    'Sleep problems': 50,
    'Work ability': 10,
    'Daily activity level': 0,
  },
  graphs: {
    Funksjon: [1, 2, 3, 6, 7, 10, 10],
    Arbeidsevne: [10, 10, 10, 10, 10, 10, 10],
    Smerte: [6, null, 5, null, null, 3, 1],
    Mestring: [11, null, 12, null, null, 12, 12],
    'Generell bedring': [null, null, 4, null, 3, 2, 1],
  },
  info: {
    practioner: 'Allmenn fysioterapeut',
    type: ['Gruppebehandling; Egentrening'],
    continuationOfTreament: '',
    endOfTreatment: ['Behov opphørt'],
    consultation: 4,
    exerciseProgress: 'Ganske bra',
  },
  prescription: {
    'Råd og veiledning': [],
    'Aktive øvelser og tiltak': [],
    Hjemmeøvelser: [],
    'Passive tiltak': [],
    'Pasienten er drøftet med': [],
    'Kontakt med andre under behandlingen': [],
  },
  prescriptionDescription: [],
};

export const PATIENT_TEMPLATE: IBaselinePatient[] = [
  {
    ...PATIENT_TEMPLATE_BASE,
    title: 'Aktuell Pasient',
    backgroundColor: 'rgba(255, 99, 132, 0)',
    borderColor: 'rgb(0, 0, 0)',
    pointBackgroundColor: 'rgb(0, 0, 0)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(0, 0, 0)',
  },
  {
    ...PATIENT_TEMPLATE_BASE,
    title: 'Pasient A',
    backgroundColor: 'rgba(255, 99, 132, 0)',
    borderColor: 'rgb(255, 99, 132)',
    pointBackgroundColor: 'rgb(255, 99, 132)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(255, 99, 132)',
  },
  {
    ...PATIENT_TEMPLATE_BASE,
    title: 'Pasient B',
    backgroundColor: 'rgba(54, 162, 235, 0)',
    borderColor: 'rgb(54, 162, 235)',
    pointBackgroundColor: 'rgb(54, 162, 235)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(54, 162, 235)',
  },
  {
    ...PATIENT_TEMPLATE_BASE,
    title: 'Pasient C',
    backgroundColor: 'rgba(54, 162, 235, 0)',
    borderColor: 'rgb(236, 146, 54)',
    pointBackgroundColor: 'rgb(236, 146, 54)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgb(236, 146, 54)',
  },
];

export const PATIENT_ZERO = {
  id: 'Xhf9LHUBPCTLibsEIF6f',
  patientId: 'ui_1000001_1981',
  clinicId: 'ui',
  dateCreated: '2020-10-14T16:34:40.162+02:00',
  dateUpdated: '2020-10-15T17:59:47.760+02:00',
  status: 'INITIAL',
  similarPatients: {
    adam_saso_39442_1989: '0.5993333333333333',
    vidar_guttormsen_30160_1992: '0.541301136440701',
    vidar_guttormsen_26296_1962: '0.531',
  },
  patientDetails: {
    painsite_neck_1: 2,
    oreb_q6_1: 5,
    medic_1: 'yes',
    mskhq_q5_1: 'slightly',
    oreb_score_1: 54,
    birth_year: 1981,
    marital_1: 'married',
    bmi_1: 24.816326,
    education_1: 'more than 4 years higher education',
    mskt_q7_1: '0-4',
    pain_duration_1: '3-6m',
    hscl_q8_1: 'little',
    height_1: 175,
    mskhq_q6_1: 'slightly',
    oreb_q7_1: 8,
    painsite_thigh_1: 0,
    como_psych_1: 1,
    hscl_score_1: 2.3,
    clinic_id: 'ui',
    qol15d_q14_vital_1: 'very weary',
    mskt_q8_1: '5-6',
    hscl_q7_1: 'much',
    oreb_q10_1: 2,
    eq5d_care_1: 'moderate problems',
    mskhq_q7_1: 'moderately',
    painsite_hand_1: 0,
    hscl_q10_1: 'much',
    painsite_knee_1: 0,
    oreb_q4_1: 7,
    hscl_q1_1: 'little',
    pseq_q2_1: 1,
    mskt_q10_1: '5-6',
    red_flag_urination_1: 'no problem',
    mskt_q1_1: '0-4',
    work_type_1: 'mostly seated',
    mskhq_score_1: 33,
    painsite_lback_1: 5,
    mskhq_q14_1: 'very much',
    mskt_q9_1: '0-4',
    painsite_number_1: 4,
    red_flag_nightpain_1: 'no night pain',
    body_map: {
      back: [
        {
          x: 2,
          y: 3,
        },
        {
          x: 2,
          y: 4,
        },
        {
          x: 3,
          y: 2,
        },
        {
          x: 3,
          y: 6,
        },
        {
          x: 3,
          y: 7,
        },
        {
          x: 4,
          y: 2,
        },
        {
          x: 4,
          y: 6,
        },
        {
          x: 4,
          y: 7,
        },
      ],
      front: [
        {
          x: 2,
          y: 3,
        },
        {
          x: 4,
          y: 6,
        },
        {
          x: 6,
          y: 5,
        },
      ],
    },
    mskhq_q8_1: 'rarely',
    oreb_q5_1: 3,
    pseq_q1_1: 2,
    health_lit_info_1: 'never',
    journal_id: 1000001,
    employ_1: 'sick leave',
    childhood_1: 'very good',
    mskt_q2_1: 'yes',
    red_flag_cancer_1: 'no former cancer',
    hscl_q9_1: 'little',
    qol15d_q5_sleep_1: 'slight problem',
    family_1: 'own home together with others',
    pain_continuous_1: 'yes',
    mskhq_q9_1: 'sometimes',
    oreb_q2_1: 4,
    hscl_q3_1: 'little',
    mskhq_q1_1: 'moderately',
    eq5d_adl_1: 'slight problem',
    employ_sickp_1: 50,
    red_flag_weight_1: 'weight loss or poor appetite',
    treat_belief_1: 'totally agree',
    como_stomach_pain_1: 1,
    medic_paracet_1: 1,
    activity_1: 'quite reduced',
    work_ability_1: 2,
    ethnicity_1: 'Norway',
    smoking_1: 'no',
    family_child_1: 2,
    mskt_q3_1: '5-6',
    painsite_shou_1: 2,
    fear_1: 3,
    mskhq_q12_1: 'very well',
    hscl_q4_1: 'not',
    oreb_q3_1: 8,
    mskt_score_1: 6,
    hscl_q2_1: 'much',
    painsite_feet_1: 0,
    mskhq_q2_1: 'moderately',
    employ_paidp_1: 100,
    medic_number_1: '1-4',
    employ_sick_1: 1,
    mskhq_pa_1: '3 days',
    mskt_q4_1: '0-4',
    mskhq_q13_1: 'moderately',
    age_1: 39,
    physio_id: 'ui',
    painsite_head_1: 0,
    mskt_risk_1: 'medium risk',
    oreb_q8_1: 6,
    mskhq_q3_1: 'slightly',
    eq5d_pain_1: 'moderate',
    pseq_score_1: 3,
    date_baseline_patient: '14 - 10 - 2020 13:14:16',
    mskt_q5_1: '5-6',
    weight_1: 76,
    comorbidity_count_1: '2-3 comorbidities',
    eq5d_walk_1: 'slight problem',
    mskhq_q10_1: 'slight',
    hscl_q6_1: 'little',
    como_headache_1: 1,
    patient_id: 'ui_1000001_1981',
    health_lit_sit_1: 'good',
    oreb_q1_1: 7,
    oreb_q9_1: 4,
    mskhq_q4_1: 'moderately',
    pain_last_week_1: 4,
    painsite_uback_1: 0,
    employ_paid_1: 1,
    pain_vary_1: 'no, stable',
    mskt_q6_1: '5-6',
    eq5d_depr_1: 'moderately',
    gender_1: 'male',
    mskhq_q11_1: 'moderately',
    hscl_q5_1: 'much',
    painsite_elbow_1: 2,
  },
};
