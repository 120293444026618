export const GROUP = {
  pain: {
    title: {
      en: '',
      no: 'Smerte',
    },
    oreb_q2_1: {
      label: {
        en: 'Pain last week',
        no: 'Smerter siste uke',
      },
      hover: {
        en: '',
        no:
          'Hvor mye smerter har du hatt den SISTE UKEN? 0 = Ingen smerte, 10= Verst tenkelig smerte. Pasientsvar: "actual score"',
      },
    },
    oreb_q1_1: {
      label: {
        en: 'Pain duration',
        no: 'Smertevarighet',
      },
      hover: {
        en: '',
        no:
          'Hvor lenge har du hatt dine nåværende plager? Fra under en måned til over ett år. Pasientsvar: "actual score"',
      },
    },
    Painsite_1: {
      label: {
        en: 'Number of pain sites',
        no: 'Smertesteder',
      },
      hover: {
        en: '',
        no:
          'Antall smertesteder, fra 0 til 10. Pasientsvar: "actual score"',
      },
    },
  },
  //
  function: {
    title: {
      en: '',
      no: 'Funksjon og aktivitet',
    },
    Activity_1: {
      label: {
        en: 'Reduction in daily activity level',
        no: 'Generelt aktivitetsnivå',
      },
      hover: {
        en: '',
        no:
          'Hvor redusert er ditt daglige aktivitetsnivå på grunn av smerter eller plager? Fire-delt skala fra ikke redusert til svært redusert. Pasientsvar:  "actual score"',
      },
    },
    Mskhq_q7: {
      label: {
        en: 'Social activities and hobbies',
        no: 'Sosial aktivitet',
      },
      hover: {
        en: '',
        no:
          'Hvor mye har dine ledd-/muskelsymptomer hindret deg i å være sosialt aktiv og å holde på med hobbyer de siste to ukene? Fem-delt skala fra ikke i det hele tatt til ekstremt. Pasientsvar: "actual score"',
      },
    },
    Mskhq_pa_1: {
      label: {
        en: 'Physical activity',
        no: 'Fysisk aktivitet siste uke',
      },
      hover: {
        en: '',
        no:
          'Hvor mange dager i løpet av den siste uken har du vært fysisk aktiv i 30 minutter eller mer? Aktiviteten kan være idrett, trening, rask gange eller sykling. Husarbeid eller fysisk aktivitet som er en del av jobben din, skal ikke regnes med. Score fra 0 til 7 dager.',
      },
    },
  },
  //
  work: {
    title: {
      en: '',
      no: 'Arbeid',
    },
    work_ability_1: {
      label: {
        en: 'Work ability',
        no: 'Arbeidsevne',
      },
      hover: {
        en: '',
        no:
          'Beskriv din nåværende arbeidsevne sammenlignet med når den var som best i ditt liv. 0 = Best arbeidsevne, 10 = Ingen arbeidsevne. Pasientsvar: "actual score".',
      },
    },
    none: {
      label: {
        en: 'Employment',
        no: 'Arbeid',
      },
      hover: {
        en: '',
        no: '',
      },
    },
    Work_type_1: {
      label: {
        en: 'Type of work',
        no: 'Type arbeid',
      },
      hover: {
        en: '',
        no:
          'Hvordan vil du beskrive arbeidet ditt (lønnet eller ulønnet)?',
      },
    },
  },
  //
  thoughts: {
    title: {
      en: '',
      no: 'Tanker og mestring',
    },
    oreb_q10_1: {
      label: {
        en: 'Fear avoidance',
        no: 'Bevegelsesfrykt',
      },
      hover: {
        en: '',
        no:
          'Jeg bør ikke utføre mine normale aktiviteter eller arbeid med den smerten jeg har nå. 0= ikke enig, 10= helt enig. Pasientsvar: "actual score"',
      },
    },
    pseq_score_1: {
      label: {
        en: 'Pain self-efficacy',
        no: 'Smertemestring',
      },
      hover: {
        en: '',
        no:
          'Pain self-efficacy sumscore av to spørsmål. 0= God smertemestring, 12= Lav smertemestring. Pasientsvar: "actual score".',
      },
    },
    Oreb_q7_1: {
      label: {
        en: 'Expectations of recovery',
        no: 'Forventning om langvarige plager',
      },
      hover: {
        en: '',
        no:
          'Hvor stor risiko mener du det er for at dine nåværende plager kan bli langvarige? 0 = Ingen risiko, 10 = Svært stor risiko. Pasientsvar: "actual score"',
      },
    },
  },
  //
  general: {
    title: {
      en: '',
      no: 'Generell helse',
    },
    comorbidities: {
      label: {
        en: 'Comorbidities',
        no: 'Komorbiditet',
      },
      hover: {
        en: '',
        no: 'Andre nåværende sykdommer eller plager',
      },
    },
    qol15d_q5_sleep_1: {
      label: {
        en: 'Sleep difficulties',
        no: 'Søvnvansker',
      },
      hover: {
        en: '',
        no:
          'Quality of life 15D søvn. Fem-delt skala fra normal søvn til alvorlige søvnløshet. Pasientsvar: "actual score"',
      },
    },
    qol15d_q14_vital_1: {
      label: {
        en: 'Fatigue',
        no: 'Utmattet',
      },
      hover: {
        en: '',
        no:
          'Quality of life 15D vitalitet. Fem-delt skala fra ikke til ekstremt. Pasientsvar: "actual score"',
      },
    },
    Hscl10_score_1: {
      label: {
        en: 'Psychologic distress sum score',
        no: 'Psykisk belastning',
      },
      hover: {
        en: '',
        no:
          'Symptomer på psykisk stress målt ved Hopkins Symptom Check-list. Skala fra 1.0 til 4.0. Over 1,85 er tegn på symptomer på psykisk stress. Pasientsvar: "actual score".',
      },
    },
    eq5d_depr_1: {
      label: {
        en: 'Anxiety and depression',
        no: 'Engstelig eller deprimert',
      },
      hover: {
        en: '',
        no:
          'EQ5D Angst/ depresjon. Fem-delt skala fra ikke til ekstremt engstelig eller deprimert. Pasientsvar: "actual score"',
      },
    },
  },
  //
  msk_tool: {
    label: {
      en: '',
      no: 'Risikogruppe',
    },
    Mskt_risk_1: {
      label: {
        en: 'Sleep difficulties',
        no: 'Risikogruppe',
      },
      hover: {
        en: '',
        no:
          'Risikogruppering for langvarighet av plager målt ved Start MSK Tool. 0-4= Lav risiko for langvarige plager. 5-8= Middels risiko for langvarige plager. 9-12= Høy risiko for langvarige plager. Pasientsvar: "actual score"',
      },
    },
  },
  //
  orebro: {
    label: {
      en: '',
      no: 'Psykososiale faktorer',
    },
    oreb_score_1: {
      label: {
        en: 'Örebro sum score(0-100)',
        no: 'Ørebro',
      },
      hover: {
        en: '',
        no:
          'Psykososiale risikofaktorer for utvikling av arbeidsnedsettelse eller uførhet på grunn av smerter målt med Örebro screeningskjema. 0 = Lav risiko, 100= Høy risiko, økt risiko over 50 poeng. Pasientsvar: "actual score"',
      },
    },
  },
  //
  mskhq_score: {
    label: {
      en: '',
      no: 'Muskel- og skjeletthelse',
    },
    mskhq_score_1: {
      label: {
        en: 'MSK-HQ sum score',
        no: 'Muskel- og skjeletthelse',
      },
      hover: {
        en: '',
        no:
          'Generell muskel- og skjeletthelse målt med ved MSK-HQ. 0 = Verst helse, 56 = Best helse. Pasientsvar: "actual score"',
      },
    },
  },
};

export const RADAR_LABELS = [
  { en: 'Pain intensity', no: 'Smerteintensitet' },
  // { en: 'Continuous pain', no: 'Kontinuerlig smerte?' },
  { en: 'Pain duration', no: 'Smertevarighet' },
  { en: 'Pain sites', no: 'Antall smertesteder' },
  { en: 'Recovery expectations', no: 'Forventninger om bedring' },
  { en: 'Pain self-efficacy', no: 'Smertemestring' },
  { en: 'Fear avoidance', no: 'Bevegelsesfrykt' },
  { en: 'Mentral distress', no: 'Psykisk belastning' },
  { en: 'Sleep problems', no: 'Søvnvansker' },
  { en: 'Work ability', no: 'Arbeidsevne' },
  { en: 'Daily activity level', no: 'Generelt aktivitetsnivå' },
];

export const GRAPHS = [
  {
    title: 'Funksjon',
    range: {
      min: 0,
      max: 10,
      minLabel: 'Dårlig',
      maxLabel: 'God',
    },
  },
  {
    title: 'Arbeidsevne',
    range: {
      min: 0,
      max: 10,
      minLabel: 'Dårlig',
      maxLabel: 'God',
    },
  },
  {
    title: 'Smerte',
    range: {
      min: 0,
      max: 10,
      minLabel: 'Lav',
      maxLabel: 'Høy',
    },
  },
  {
    title: 'Mestring',
    range: {
      min: 0,
      max: 12,
      minLabel: 'Dårlig',
      maxLabel: 'God',
    },
  },
  {
    title: 'Generell bedring',
    range: {
      min: 1,
      median: 4,
      max: 7,
      minLabel: 'Bedre',
      medianLabel: 'Uendret',
      maxLabel: 'Verre',
    },
  },
];

export const GRAPHS_X = ['Baseline', 1, 2, 3, 4, 8, 12];

export const RED_FLAGS = [
  {
    key: 'red_flag_cancer_1',
    label: 'Tidligere kreftdiagnose',
    value: 'former cancer',
  },
  {
    key: 'red_flag_nightpain_1',
    label: 'Store nattsmerter',
    value: 'night pain',
  },
  {
    key: 'red_flag_weight_1',
    label: 'Uforklarlig vekttap / feber',
    value: 'weight loss or poor appetite',
  },
  {
    key: 'red_flag_urination_1',
    label: 'Urin- og avføringsproblemer',
    value: 'problem with urination or sexual function',
  },
];

export const COMPARISON_INFO = [
  'Pasientens kjønn og alder',
  'Pasientens diagnose',
  'Antall konsultasjoner',
  'Utført av',
  'Behandlingen ble gitt hovedsakelig som',
  'Behandlingsvarighet',
  'Årsak til avslutning',
  'Compliance til øvelser gitt av fysioterapeuten',
];

export const COMPARISON_PRESCRIPE: { [key: string]: string[] } = {
  'Råd og veiledning': [
    'Redusert aktivitet og hvile',
    'Økt fysisk aktivitet',
    'Frykt for bevegelse',
    'Egenmestring',
    'Arbeid og tilpasning av arbeid',
    'Søvn',
    'Vektendring',
    'Sosial deltakelse',
    'Mestring av stress, angst eller depresjon',
    'Forventninger, prognose og forløp',
  ],
  'Aktive øvelser og tiltak': [
    'Spesifikk styrketrening',
    'Utholdenhetstrening',
    'Balanse eller koordinasjonstrening',
    'Muskel tøyning eller leddmobilisering',
    'Trening for postural kontroll, holdning eller avspenning',
    'Generelle øvelser eller tøyninger',
    'Hjemmeøvelser',
    'Spesifikke oppgaver eller øvelser hjemme (skriftlig)',
  ],
  Hjemmeøvelser: [
    'Spesifikke oppgaver eller øvelser hjemme (skriftlig)',
  ],
  'Passive tiltak': [
    'Massasje og bløtdelsbehandling',
    'Leddmobilisering og manipulajon',
    'Tøyning',
    'Triggerpunkt, nålebehandling',
    'Trykkbølge',
  ],
  'Pasienten er drøftet med': [
    'Primærlege',
    'Spesialist',
    'Psykolog',
    'Annen fysioterapeut',
    'Annet helsepersonell',
  ],
  'Kontakt med andre under behandlingen': [
    'NAV',
    'Bedriftshelsetjeneste',
    'Arbeidsgiver',
    'Familie',
  ],
};

export const COMPARISON_PRESCRIPE_DESCRIPTION = [
  'Viktigste faktor pasient',
  'Viktigste bakgrunnsinfo terapeut',
  'Beskrivelse av behandling',
  'Behandlingsråd fenotype',
  'Retningslinjer risikogruppe "actual score"',
];

export const GENERAL_HEALTH_SCORE_TEXT: {
  [key: string]: { [key: string]: string };
} = {
  qol15d_q5_sleep_1: {
    1: 'Jeg er i stand til å sove normalt, dvs. jeg har ingen problemer med å sove.',
    2: 'Jeg har lette søvnproblemer, f.eks. vanskelig for å falle i søvn eller våkner av og til om natten.',
    3: 'Jeg har moderate søvnproblemer, f.eks. forstyrret søvn eller føler jeg ikke har sovet nok.',
    4: 'Jeg har store søvnproblemer, f.eks.må bruke sovemedisiner ofte eller rutinemessig, eller våkner om natten og/ eller for tidlig om morgenen.',
    5: 'Jeg lider av alvorlig søvnløshet, f.eks. er søvn nesten umulig selv med bruk av sovemedisiner, eller jeg forblir våken det meste av natten.',
  },
  qol15d_q14_vital_1: {
    1: 'Ikke',
    2: 'Litt',
    3: 'Middels',
    4: 'Svært',
    5: 'Ekstremt',
  },
  eq5d_depr_1: {
    1: 'Ikke',
    2: 'Litt',
    3: 'Middels',
    4: 'Svært',
    5: 'Ekstremt',
  },
};

export const PAIN_SCORE_TEXT: {
  [key: string]: { [key: string]: { [key: string]: any } };
} = {
  oreb_q1_1: {
    // 1: 'Under en måned',
    // 2: 'Under en måned',
    // 3: 'Under en måned',
    // 4: 'Under en måned',
    // 5: '1 - 3 måneder',
    // 6: '1 - 3 måneder',
    // 7: '3 - 6 måneder',
    // 8: '6 - 9 måneder',
    // 9: '9 - 12 måneder',
    // 10: 'Over ett år',
    '0 to 1 week': { value: 1, no: 'Under en måned' },
    '1 to 2 weeks': { value: 2, no: 'Under en måned' },
    '3 to 4 weeks': { value: 3, no: 'Under en måned' },
    '4 to 5 weeks': { value: 4, no: 'Under en måned' },
    '6 to 8 weeks': { value: 5, no: '1 - 3 måneder' },
    '9 to 11 weeks': { value: 6, no: '1 - 3 måneder' },
    '3 to 6 months': { value: 7, no: '3 - 6 måneder' },
    '6 to 9 months': { value: 8, no: '6 - 9 måneder' },
    '9 to 12 months': { value: 9, no: '9 - 12 måneder' },
    'more than 12 months': { value: 10, no: 'Over ett år' },
  },
};

export const FUNCTION_AND_ACTIVITY_SCORE_TEXT: {
  [key: string]: {
    [key: number]: {
      no: string;
      en: string;
    };
  };
} = {
  activity_1: {
    1: { no: 'Svært redusert', en: 'Very reduced' },
    2: { no: 'Ganske redusert', en: 'Quite reduced' },
    3: { no: 'Litt redusert', en: 'Slightly reduced' },
    4: { no: 'Ikke redusert', en: 'Not reduced' },
  },
  mskhq_q7_1: {
    0: { no: 'Ekstremt', en: 'Extremely' },
    1: { no: 'Veldig', en: 'Severely' },
    2: { no: 'Moderat', en: 'Moderately' },
    3: { no: 'Litt', en: 'Slightly' },
    4: { no: 'Ikke i det hele tatt ', en: 'Not at all' },
  },
  Mskhq_q7: {
    0: { no: 'Ekstremt', en: 'Extremely' },
    1: { no: 'Veldig', en: 'Severely' },
    2: { no: 'Moderat påvirket', en: 'Moderately' },
    3: { no: 'Litt', en: 'Slightly' },
    4: { no: 'Ikke i det hele tatt ', en: 'Not at all' },
  },
};

export const MSKT_SCORE_TEXT: {
  [key: string]: {
    [key: number]: { no: string; en: string; value: number };
  };
} = {
  mskt_risk_1: {
    0: { no: 'Lav', en: 'Low risk', value: 0 },
    1: { no: 'Lav', en: 'Low risk', value: 1 },
    2: { no: 'Lav', en: 'Low risk', value: 2 },
    3: { no: 'Lav', en: 'Low risk', value: 3 },
    4: { no: 'Lav', en: 'Low risk', value: 4 },
    5: { no: 'Middels', en: 'Medium risk', value: 5 },
    6: { no: 'Middels', en: 'Medium risk', value: 6 },
    7: { no: 'Middels', en: 'Medium risk', value: 7 },
    8: { no: 'Middels', en: 'Medium risk', value: 8 },
    9: { no: 'Høy', en: 'High risk', value: 9 },
    10: { no: 'Høy', en: 'High risk', value: 10 },
    11: { no: 'Høy', en: 'High risk', value: 11 },
    12: { no: 'Høy', en: 'High risk', value: 12 },
  },
  mskt_risk_1_long: {
    0: {
      no:
        'Ikke sette i gang stor intervensjon. Utdanning og råd, inkluderer øvelser og aktivitet',
      en: 'Low risk',
      value: 0,
    },
    1: {
      no:
        'Ikke sette i gang stor intervensjon. Utdanning og råd, inkluderer øvelser og aktivitet',
      en: 'Low risk',
      value: 1,
    },
    2: {
      no:
        'Ikke sette i gang stor intervensjon. Utdanning og råd, inkluderer øvelser og aktivitet',
      en: 'Low risk',
      value: 2,
    },
    3: {
      no:
        'Ikke sette i gang stor intervensjon. Utdanning og råd, inkluderer øvelser og aktivitet',
      en: 'Low risk',
      value: 3,
    },
    4: {
      no:
        'Ikke sette i gang stor intervensjon. Utdanning og råd, inkluderer øvelser og aktivitet',
      en: 'Low risk',
      value: 4,
    },
    5: {
      no:
        'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc.',
      en: 'Medium risk',
      value: 5,
    },
    6: {
      no:
        'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc.',
      en: 'Medium risk',
      value: 6,
    },
    7: {
      no:
        'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc.',
      en: 'Medium risk',
      value: 7,
    },
    8: {
      no:
        'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc.',
      en: 'Medium risk',
      value: 8,
    },
    9: {
      no:
        'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc. Vurder tverrfaglig samarbeid, viderehenvisning, smertemestring, henvise spesialisthelsetjenesten, vurder henvisning billedtaking',
      en: 'High risk',
      value: 9,
    },
    10: {
      no:
        'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc. Vurder tverrfaglig samarbeid, viderehenvisning, smertemestring, henvise spesialisthelsetjenesten, vurder henvisning billedtaking',
      en: 'High risk',
      value: 10,
    },
    11: {
      no:
        'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc. Vurder tverrfaglig samarbeid, viderehenvisning, smertemestring, henvise spesialisthelsetjenesten, vurder henvisning billedtaking',
      en: 'High risk',
      value: 11,
    },
    12: {
      no:
        'Oppfølging hos fysioterapeut. Utdanning og råd, inkluderer øvelser og aktivitet. Veiledet egenmestring, persontilpassede øvelser, grupptrening etc. Vurder tverrfaglig samarbeid, viderehenvisning, smertemestring, henvise spesialisthelsetjenesten, vurder henvisning billedtaking',
      en: 'High risk',
      value: 12,
    },
  },
  // short: {
  //   0: 'Lav',
  //   1: 'Lav',
  //   2: 'Lav',
  //   3: 'Lav',
  //   4: 'Lav',
  //   5: 'Middels',
  //   6: 'Middels',
  //   7: 'Middels',
  //   8: 'Middels',
  //   9: 'Høy',
  //   10: 'Høy',
  //   11: 'Høy',
  //   12: 'Høy',
  // },
  // long: {
  //   0: 'Lav risiko for langvarige plager.',
  //   1: 'Lav risiko for langvarige plager.',
  //   2: 'Lav risiko for langvarige plager.',
  //   3: 'Lav risiko for langvarige plager.',
  //   4: 'Lav risiko for langvarige plager.',
  //   5: 'Middels risiko for langvarige plager.',
  //   6: 'Middels risiko for langvarige plager.',
  //   7: 'Middels risiko for langvarige plager.',
  //   8: 'Middels risiko for langvarige plager.',
  //   9: 'Høy risiko',
  //   10: 'Høy risiko',
  //   11: 'Høy risiko',
  //   12: 'Høy risiko',
  // },
};

export const RADAR_VALUE_NORMALIZE: {
  [key: string]: { [key: number]: number };
} = {
  'Pain intensity': {
    1: 10,
    2: 20,
    3: 30,
    4: 40,
    5: 50,
    6: 60,
    7: 70,
    8: 80,
    9: 90,
    10: 100,
  },
  'Pain sites': {
    1: 10,
    2: 20,
    3: 30,
    4: 40,
    5: 50,
    6: 60,
    7: 70,
    8: 80,
    9: 90,
    10: 100,
  },
  'Pain duration': {
    1: 0,
    2: 0,
    3: 0,
    4: 20,
    5: 20,
    6: 20,
    7: 40,
    8: 60,
    9: 80,
    10: 100,
  },
  'Recovery expectations': {
    1: 10,
    2: 20,
    3: 30,
    4: 40,
    5: 50,
    6: 60,
    7: 70,
    8: 80,
    9: 90,
    10: 100,
  },
  'Pain self-efficacy': {
    0: 100,
    1: 91.7,
    10: 16.7,
    11: 8.3,
    12: 0,
    2: 83.3,
    3: 75.0,
    4: 66.7,
    5: 58.3,
    6: 50.0,
    7: 41.7,
    8: 33.3,
    9: 25.0,
  },
  'Fear avoidance': {
    1: 10,
    2: 20,
    3: 30,
    4: 40,
    5: 50,
    6: 60,
    7: 70,
    8: 80,
    9: 90,
    10: 100,
  },
  'Mentral distress': {
    1: 0.0,
    2: 33.3,
    3: 66.7,
    4: 100,
  },
  'Sleep problems': {
    1: 0,
    2: 25,
    3: 50,
    4: 75,
    5: 100,
  },
  'Work ability': {
    0: 100,
    1: 90,
    2: 80,
    3: 70,
    4: 60,
    5: 50,
    6: 40,
    7: 30,
    8: 20,
    9: 10,
    10: 0,
  },
  'Daily activity level': {
    1: 100,
    2: 66.7,
    3: 33.3,
    4: 0.0,
  },
};

export const COMORBIDITIES: { [key: string]: string } = {
  como_ra_1: 'Revmatisk sykdom',
  como_heart_1: 'Hjertesykdom',
  como_asthma_1: 'Astma/ lungesykdom',
  como_neuro_1:
    'Nevrologisk sykdom (f.eks tidligere hjerneslag eller MS)',
  como_cancer_1: 'Kreft/ tidligere kreft',
  como_psych_1: 'Psykiske plager',
  como_dementhia_1: 'Demens/ kognitiv svikt',
  como_osteoporosis_1: 'Osteoporose',
  como_diabetes_1: 'Diabetes',
  como_stomach_pain_1: 'Magesmerter',
  como_headache_1: 'Hodepine/svimmelhet',
  como_fatigue_1: 'Utmattelse',
  como_other_1: 'Annet',
  como_other_spec_1: '',
};

export const PAIN_SITES: {
  [key: string]: {
    no: string;
    en: string;
  };
} = {
  painsite_head_1: { no: 'Hode', en: 'Painsite head' },
  painsite_neck_1: { no: 'Nakke', en: 'Painsite neck' },
  painsite_shou_1: { no: 'Skuldre', en: 'Painsite shoulders' },
  painsite_uback_1: {
    no: 'Øvre del rygg',
    en: 'Painsite upper back',
  },
  painsite_elbow_1: { no: 'Albuer', en: 'Painsite elbows' },
  painsite_hand_1: {
    no: 'Håndledd / hender',
    en: 'Painsite wrists/ hands',
  },
  painsite_thigh_1: {
    no: 'Hofter / lår',
    en: 'Painsite hips/ tighs',
  },
  painsite_lback_1: { no: 'Korsrygg', en: 'Painsite low back' },
  painsite_feet_1: {
    no: 'Ankler / føtter',
    en: 'Painsite ankles/ feet',
  },
  painsite_knee_1: { no: 'Knær', en: 'Painsite knees' },
  painsite_number_1: {
    no: 'Antall affiserte regioner:',
    en: 'Number of painsites',
  },
};

export const SLEEP = {
  qol15d_q5_sleep_1: {
    1: {
      no:
        'Jeg er i stand til å sove normalt, dvs. jeg har ingen problemer med å sove.',
      en: 'sleep normally',
    },
    2: {
      no:
        'Jeg har lette søvnproblemer, f.eks. vanskelig for å falle i søvn eller våkner av og til om natten.',
      en: 'slight problem',
    },
    3: {
      no:
        'Jeg har moderate søvnproblemer, f.eks. forstyrret søvn eller føler jeg ikke har sovet nok.',
      en: 'moderate problems',
    },
    4: {
      no:
        'Jeg har store søvnproblemer, f.eks.må bruke sovemedisiner ofte eller rutinemessig, eller våkner om natten og/ eller for tidlig om ',
      en: 'great problems',
    },
    5: {
      no:
        'Jeg lider av alvorlig søvnløshet, f.eks. er søvn nesten umulig selv med bruk av sovemedisiner, eller jeg forblir våken det meste av natten.',
      en: 'severe sleeplessness',
    },
  },
};

export const VITALITY = {
  qol15d_q14_vital_1: {
    1: {
      no: 'Jeg føler meg frisk og energisk.',
      en: 'healthy and energetic',
    },
    2: {
      no: 'Jeg føler meg litt sliten, trett eller svak.',
      en: 'slightly weary',
    },
    3: {
      no: 'Jeg føler meg middels sliten, trett eller svak.',
      en: 'moderately weary',
    },
    4: {
      no:
        'Jeg føler meg svært sliten, trett eller svak, nesten utslitt.',
      en: 'very weary',
    },
    5: {
      no:
        'Jeg føler meg ekstremt sliten, trett eller svak, totalt utslitt.',
      en: 'extremely weary',
    },
  },
};

export const HSCL: {
  [key: string]: {
    [key: number]: {
      no: string;
      en: string;
    };
  };
} = {
  hscl10_q1_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q2_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q3_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q4_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q5_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q6_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q7_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q8_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q9_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
  hscl10_q10_1: {
    1: { no: 'ikke i det hele tatt', en: 'not' },
    2: { no: 'litt', en: 'little' },
    3: { no: 'en god del', en: 'much' },
    4: { no: 'svært mye', en: 'very much' },
  },
};

export const PROBLEM_ANXIETY_DEPRESSION = {
  eq5d_depr_1: {
    1: { no: 'Jeg er verken engstelig eller deprimert', en: 'not' },
    2: {
      no: 'Jeg er litt engstelig eller deprimert',
      en: 'slightly',
    },
    3: {
      no: 'Jeg er middels engstelig eller deprimert',
      en: 'moderately',
    },
    4: {
      no: 'Jeg er svært engstelig eller deprimert',
      en: 'severely',
    },
    5: {
      no: 'Jeg er ekstremt engstelig eller deprimert',
      en: 'extremely',
    },
  },
};

export const BASELINE_MAIN_PROBLEM = {
  body_main_1: {
    1: { no: 'Nakke', en: 'Neck' },
    2: { no: 'Skulder', en: 'Shoulder' },
    3: { no: 'Rygg', en: 'Back' },
    4: { no: 'Hofte', en: 'Hip' },
    5: { no: 'Kne', en: 'Knee' },
    6: { no: 'Utbredte plager/ sammensatt', en: 'multisite' },
  },
};

export const BASELINE_NECK = {
  neck_move_1: {
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Nei', en: 'No' },
  },
  limited: {
    neck_flexlim_1: { no: 'Fleksjon', en: 'Limited flexion' },
    neck_extlim_1: { no: 'Ekstensjon', en: 'Limited extension' },
    neck_rotlim_1: { no: 'Rotasjon', en: 'Limited rotation' },
    neck_latlim_1: {
      no: 'Lateralfleksjon',
      en: 'Limited lateralflexion',
    },
    neck_notlim_1: {
      no: 'Ikke reduserte bevegelsesutslag',
      en: 'Not limited movements',
    },
  },
  painful: {
    neck_flexpain_1: { no: 'Fleksjon', en: 'Pain with flexion' },
    neck_extpain_1: { no: 'Ekstensjon', en: 'Pain with extension' },
    neck_rotpain_1: { no: 'Rotasjon', en: 'Pain with rotation' },
    neck_latpain_1: {
      no: 'Lateralfleksjon',
      en: 'Pain with lateralflexion',
    },
    neck_notpain_1: {
      no: 'Ingen smerter ved aktive bevgelsesutslag',
      en: 'Not pain with movements',
    },
  },
  spurling: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },

  neuro: {
    neck_neuro_not_1: {
      no: 'Ikke aktuelt',
      en: 'Nevrologic signs not applicable',
    },
    neck_neuro_pow_1: {
      no: 'Redusert kraft',
      en: 'Nevrologic sign, redused power',
    },
    neck_neuro_sens_1: {
      no: 'Redusert sensibilitet',
      en: 'Nevrologis sign, redused sensibility',
    },
    neck_neuro_reflex_1: {
      no: 'Unormale reflekser',
      en: 'Nevrologic sign, not normal reflexes',
    },
  },

  conclusion: {
    1: {
      no:
        'Ingen tegn til alvorlige patologiske forandringer. Liten eller ingen innvirkning på dagliglivets funksjon på grunn av nakkeproblemene',
      en:
        'No evidence of severe pathological changes. Little or no impact on daily functioning due to neck problems',
    },
    2: {
      no:
        'Ingen tegn på alvorlige patologiske forandringer, men med sikker nedsettelse av dagliglivets funksjon',
      en:
        'No evidence of serious pathological changes, but with a certain reduction in the functioning of daily life',
    },
    3: {
      no: 'Tegn på nerverotsaffeksjon',
      en: 'Signs of nerve root affection',
    },
    4: {
      no:
        'Tegn på alvorlig patologisk forandring: brudd, myelopati, neoplasme, infeksjoner eller systemisk sykdom',
      en:
        'Signs of severe pathological change: rupture, myelopathy, neoplasm, infections or systemic disease',
    },
  },
};

export const BASELINE_SHOULDER = {
  shoulder_move_active_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
  },
  limited: {
    shoulder_move_rom_flex_1: {
      no: 'Fleksjon',
      en: 'Limited flexion',
    },
    shoulder_move_rom_abd_1: {
      no: 'Abduksjon',
      en: 'Limited abduction',
    },
    shoulder_move_rom_add_1: {
      no: 'Adduksjon',
      en: 'Limited adduction',
    },
    shoulder_move_rom_extrot_1: {
      no: 'Utadrotasjon',
      en: 'Limited external rotation',
    },
    shoulder_move_rom_introt_1: {
      no: 'Innadrotasjon',
      en: 'Limited internal rotation',
    },
    shoulder_move_rom_not_1: {
      no: 'Ikke reduserte bevegelsesutslag',
      en: 'Not limited active movements',
    },
  },
  pain: {
    shoulder_move_pain_flex_1: {
      no: 'Fleksjon',
      en: 'Pain with flexion',
    },
    shoulder_move_pain_abd_1: {
      no: 'Abduksjon',
      en: 'Pain with abduction',
    },
    shoulder_move_pain_add_1: {
      no: 'Adduksjon',
      en: 'Pain with adduction',
    },
    shoulder_move_pain_extrot_1: {
      no: 'Utadrotasjon',
      en: 'Pain with external rotation',
    },
    shoulder_move_pain_introt_1: {
      no: 'Innadrotasjon',
      en: 'Pain with internal rotation',
    },
    shoulder_move_pain_not_1: {
      no: 'Ikke reduserte bevegelsesutslag',
      en: 'Not pain with movements',
    },
  },
  shoulder_passive_extrot_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  shoulder_painfularc_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  shoulder_hawkins_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  shoulder_iso_abdpain_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  shoulder_iso_extrotpain_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  shoulder_iso_abdpow_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  shoulder_iso_extrotpow_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  shoulder_comments_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
};

export const BASELINE_BACK = {
  back_move_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
  },
  limited: {
    back_flexlim_1: { no: 'Fleksjon', en: 'Limited flexion' },
    back_extlim_1: { no: 'Ekstensjon', en: 'Limited extension' },
    back_rotlim_1: { no: 'Rotasjon', en: 'Limited rotation' },
    back_latlim_1: {
      no: 'Lateralfleksjon',
      en: 'Limited lateralflexion',
    },
    back_notlim_1: {
      no: 'Ikke reduserte bevegelsesutslag',
      en: 'Not limited movements',
    },
  },
  pain: {
    back_flexpain_1: { no: 'Fleksjon', en: 'Pain with flexion' },
    back_extpain_1: { no: 'Ekstensjon', en: 'Pain with extension' },
    back_rotpain_1: { no: 'Rotasjon', en: 'Pain with rotation' },
    back_latpain_1: {
      no: 'Lateralfleksjon',
      en: 'Pain with lateralflexion',
    },
    back_notpain_1: {
      no: 'Ingen smerter ved aktive bevgelsesutslag',
      en: 'Not pain with movements',
    },
  },
  back_slr_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },

  neurologic: {
    back_neuro_not_1_2: {
      no: 'Ikke aktuelt',
      en: 'Nevrologic signs not applicable',
    },
    back_neuro_pow_1_2: {
      no: 'Redusert kraft',
      en: 'Nevrologic sign, redused power',
    },
    back_neuro_sens_1_2: {
      no: 'Redusert sensibilitet',
      en: 'Nevrologis sign, redused sensibility',
    },
    back_neuro_reflex_1_2: {
      no: 'Unormale reflekser',
      en: 'Nevrologic sign, not normal reflexes',
    },
  },

  back_nevro_not_1: {
    1: {
      no: 'Ikke aktuelt',
      en: 'Nevrologic signs not applicable',
    },
  },
  back_nevro_pow_1: {
    1: {
      no: 'Redusert kraft',
      en: 'Nevrologic sign, redused power',
    },
  },
  back_nevro_sens_1: {
    1: {
      no: 'Redusert sensibilitet',
      en: 'Nevrologis sign, redused sensibility',
    },
  },
  back_nevro_reflex_1: {
    1: {
      no: 'Unormale reflekser',
      en: 'Nevrologic sign, not normal reflexes',
    },
  },
};

export const BASELINE_HIP = {
  hip_move_active_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
  },
  hip_move_passive_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
  },
  limitedActive: {
    hip_move_rom_flex_1: {
      no: 'Fleksjon',
      en: 'Limited active flexion',
    },
    hip_move_rom_ext_1: {
      no: 'Ekstensjon',
      en: 'Limited active extension',
    },
    hip_move_rom_abd_1: {
      no: 'Abduksjon',
      en: 'Limited active abduction',
    },
    hip_move_rom_add_1: {
      no: 'Adduksjon',
      en: 'Limited active adduction',
    },
    hip_move_rom_extrot_1: {
      no: 'Utadrotasjon',
      en: 'Limited active external rotation',
    },
    hip_move_rom_introt_1: {
      no: 'Innadrotasjon',
      en: 'Limited active internal rotation',
    },
    hip_move_rom_not_1: {
      no: 'Ikke reduserte bevegelsesutslag',
      en: 'Not limited active movements',
    },
  },
  painfulActive: {
    hip_move_pain_flex_1: {
      no: 'Fleksjon',
      en: 'Painful active flexion',
    },
    hip_move_pain_ext_1: {
      no: 'Ekstensjon',
      en: 'Painful active extension',
    },
    hip_move_pain_abd_1: {
      no: 'Abduksjon',
      en: 'Painful active  abduction',
    },
    hip_move_pain_add_1: {
      no: 'Adduksjon',
      en: 'Painful active  adduction',
    },
    hip_move_pain_extrot_1: {
      no: 'Utadrotasjon',
      en: 'Painful active external rotation',
    },
    hip_move_pain_introt_1: {
      no: 'Innadrotasjon',
      en: 'Limited active internal rotation',
    },
    hip_move_pain_not_1: {
      no: 'Ingen smerter ved aktive bevegelser',
      en: 'Not limited active movements',
    },
  },
  limitedPassive: {
    hip_move_passive_rom_flex_1: {
      no: 'Fleksjon',
      en: 'Limited passive flexion',
    },
    hip_move_passive_rom_ext_1: {
      no: 'Ekstensjon',
      en: 'Limited passive extension',
    },
    hip_move_passive_rom_abd_1: {
      no: 'Abduksjon',
      en: 'Limited passive  abduction',
    },
    hip_move_passive_rom_add_1: {
      no: 'Adduksjon',
      en: 'Limited passive adduction',
    },
    hip_move_passive_rom_extrot_1: {
      no: 'Utadrotasjon',
      en: 'Limited passive external rotation',
    },
    hip_move_passive_rom_introt_1: {
      no: 'Innadrotasjon',
      en: 'Limited passive internal rotation',
    },
    hip_move_passive_rom_not_1: {
      no: 'Ikke reduserte bevegelsesutslag',
      en: 'Not limited passive  movements',
    },
  },
  painfulPassive: {
    hip_move_passive_pain_flex_1: {
      no: 'Fleksjon',
      en: 'Painful passive flexion',
    },
    hip_move_passive_pain_ext_1: {
      no: 'Ekstensjon',
      en: 'Painful passive extension',
    },
    hip_move_passive_pain_abd_1: {
      no: 'Abduksjon',
      en: 'Painful passive  abduction',
    },
    hip_move_passive_pain_add_1: {
      no: 'Adduksjon',
      en: 'Painful passive  adduction',
    },
    hip_move_passive_pain_extrot_1: {
      no: 'Utadrotasjon',
      en: 'Painful passive external rotation',
    },
    hip_move_passive_pain_introt_1: {
      no: 'Innadrotasjon',
      en: 'Limited passive internal rotation',
    },
    hip_move_passive_pain_not_1: {
      no: 'Ingen smerter ved aktive bevegelser',
      en: 'Not limited passive movements',
    },
  },
  area: {
    // Fix new updated mapping
    hip_pain_not_1: {
      no: 'Ikke aktuelt',
      en: 'Not pain in the hip',
    },
    hip_pain_lat_1: {
      no: 'Lateralt',
      en: 'Localisation hip: Lateral pain',
    },
    hip_pain_med_1: {
      no: 'Medial/lyske',
      en: 'Localisation hip: Medial pain',
    },
    hip_pain_post_1: {
      no: 'Posteriort',
      en: 'Localisastion hip: Postreior pain',
    },
    hip_pain_ant_1: {
      no: 'Anteriort',
      en: 'Localisastion hip: Anterior pain',
    },
    // hip_notpain_1: { no: 'Ikke aktuelt', en: 'Not pain in the hip' },
    // hip_latpain_1: {
    //   no: 'Lateralt',
    //   en: 'Localisation hip: Lateral pain',
    // },
    // hip_medpain_1: {
    //   no: 'Medial/lyske',
    //   en: 'Localisation hip: Medial pain',
    // },
    // hip_postpain_1: {
    //   no: 'Posteriort',
    //   en: 'Localisastion hip: Postreior pain',
    // },
    // hip_antpain_1: {
    //   no: 'Anteriort',
    //   en: 'Localisastion hip: Anterior pain',
    // },
  },
  hip_faber_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    3: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  hip_faddir_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  hip_trend_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
};
export const BASELINE_KNEE = {
  knee_move_active_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
  },
  knee_move_passive_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
  },
  knee_hydrops_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_traumatic_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_pivot_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_lachman_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_sag_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_mcmurray_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_palp_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_valgus_ext_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_valgus_flex_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_varus_ext_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  knee_varus_flex_1: {
    0: { no: 'Nei', en: 'No' },
    1: { no: 'Ja', en: 'Yes' },
    2: { no: 'Ikke aktuelt', en: 'Not applicable' },
  },
  limitedActive: {
    knee_move_rom_flex_1: {
      no: 'Fleksjon',
      en: 'Limited active flexion',
    },
    knee_move_rom_ext_1: {
      no: 'Ekstensjon',
      en: 'Limited active extension',
    },
    knee_move_rom_not_1: {
      no: 'Ikke reduserte bevegelsesutslag',
      en: 'Not limited active movements',
    },
  },
  painActive: {
    knee_active_flexpain_1: {
      no: 'Fleksjon',
      en: 'Painful active flexion',
    },
    knee_active_extpain_1: {
      no: 'Ekstensjon',
      en: 'Painful active extension',
    },
    knee_active_notpain_1: {
      no: 'Ingen smerter ved aktive bevegelser',
      en: 'Not painful active movements',
    },
  },
  limitedPassive: {
    knee_passive_flexlim_1: {
      no: 'Fleksjon',
      en: 'Limited passive flexion',
    },
    knee_passive_extlim_1: {
      no: 'Ekstensjon',
      en: 'Limited passive extension',
    },
    knee_passive_notlim_1: {
      no: 'Ikke reduserte bevegelsesutslag',
      en: 'Not limited passive movements',
    },
  },
  painPassive: {
    knee_passive_flexpain_1: {
      no: 'Fleksjon',
      en: 'Painful passive flexion',
    },
    knee_passive_extpain_1: {
      no: 'Ekstensjon',
      en: 'Painful passive extension',
    },
    knee_passive_notpain_1: {
      no: 'Ingen smerter ved passive bevegelser',
      en: 'Not painful passive movements',
    },
  },
};

export const CASE_STRUCTURE = {
  activeAttributesWithWeights: {
    activity_1: 2.0,
    age_1: 1.0,
    bmi_1: 1.0,
    body_main_1: 4.0,
    comorbidity_count_1: 1.0,
    education_1: 2.0,
    employ_1: 1.0,
    eq5d_care_1: 1.0,
    eq5d_depr_1: 2.0,
    eq5d_walk_1: 1.0,
    fear_1: 1.0,
    gender_1: 1.0,
    hscl_score_1: 8.0,
    mskhq_pa_1: 2.0,
    mskhq_q7_1: 1.0,
    mskt_risk_1: 4.0,
    oreb_q10_1: 1.0,
    oreb_q7_1: 4.0,
    pain_continuous_1: 1.0,
    pain_duration_1: 4.0,
    pain_last_week_1: 1.0,
    painsite_number_1: 2.0,
    pseq_score_1: 2.0,
    qol15d_q14_vital_1: 1.0,
    qol15d_q5_sleep_1: 4.0,
    smoking_1: 1.0,
    walk_aid_1: 4.0,
    work_ability_1: 4.0,
    work_type_1: 2.0,
  },
  allAttributes: [
    'mskhq_q7_1',
    'oreb_q7_1',
    'mskt_risk_1',
    'outcome_01',
    'hscl_score_1',
    'pseq_score_1',
    'walk_aid_1',
    'activity_1',
    'work_ability_1',
    'id',
    'smoking_1',
    'mskhq_pa_1',
    'employ_1',
    'work_type_1',
    'qol15d_q5_sleep_1',
    'eq5d_depr_1',
    'gender_1',
    'bmi_1',
    'qol15d_q14_vital_1',
    'fear_1',
    'education_1',
    'body_main_1',
    'eq5d_walk_1',
    'pain_duration_1',
    'painsite_number_1',
    'oreb_q10_1',
    'age_1',
    'eq5d_care_1',
    'patient_id',
    'pain_continuous_1',
    'pain_last_week_1',
    'comorbidity_count_1',
  ],
};

export const GENDER = {
  gender_1: {
    0: { no: 'Kvinne', en: 'female' },
    1: { no: 'Mann', en: 'male' },
  },
};

export const TREATMENT = {
  treat_expect_3: {
    1: { no: 'I svært stor grad', en: 'Very much' },
    2: { no: 'I stor grad', en: 'Much' },
    3: { no: 'I noen grad', en: 'Some' },
    4: { no: 'I liten grad', en: 'Little' },
    5: { no: 'I svært liten grad', en: 'Very little' },
  },
  treat_contin_3: {
    0: { no: 'Mindre enn tre måneder', en: 'No' },
    1: { no: 'Over tre måneder', en: 'Yes' },
  },
  physio_spec_3: {
    1: { no: 'Allmenn fysioterapeut', en: 'general physiotherapist' },
    2: { no: 'Psykomotoriker', en: 'psychomotoric' },
    3: { no: 'Manuell terapeut', en: 'manual therapist' },
  },
};

export const TREATMENT_END: {
  [key: string]: { no: string; en: string };
} = {
  end_patient_3: { no: 'Behov opphørt', en: 'Needs ceased' },
  end_effect_3: { no: 'Effektstagnasjon', en: 'Effect stagnation' },
  end_noeffect_3: { no: 'Ingen effekt', en: 'No effect' },
  end_followup_3: {
    no: 'Pasienten ønsker ikke videre behandling',
    en: 'The patient does not want further treatment',
  },
  end_move_3: {
    no: 'Flyttet ut av kommune',
    en: 'moved out of municipality',
  },
  end_medic_3: {
    no: 'Forverret medsinsk tilstand',
    en: 'worsening medical condition',
  },
  end_death_3: { no: 'Død', en: 'Dead' },
  end_other_3: { no: 'Annet', en: 'Other' },
};

export const WORK = {
  work_type_1: {
    1: {
      no:
        'For det meste stillesittende arbeid (f.eks skrivebordsarbeid, montering)',
      en: 'mostly seated',
    },
    2: {
      no:
        'Arbeid som krever at du går mye (f.eks ekspeditørarbeid, lett industriarbeid, undervisning)',
      en: 'much walking',
    },
    3: {
      no:
        'Arbeid hvor du går og løfter mye (f.eks postbud, pleier, bygningsarbeider)',
      en: 'much walking and lifting',
    },
    4: {
      no:
        'Tungt kroppsarbeid (f.eks skogsarbeider, tungt jordbruksarbeid, tungt bygningsarbeid)',
      en: 'heavy work using the body',
    },
  },
  employ_1: {
    1: { no: 'I lønnet arbeid', en: 'Employed paid work' },
    2: { no: 'Skoleelev / Student', en: 'Student' },
    3: { no: 'Pensjonist', en: 'Retired' },
    4: {
      no: 'Uføretrygdet',
      en: 'disability pension or work assessment',
    },
    5: { no: 'Sykmeldt', en: 'Sick leave' },
    6: { no: 'Arbeidsavklaring', en: 'Work allowance assessment' },
    7: {
      no: 'Ulønnet arbeid (eks. hjemme/frivillighetsarbeid)',
      en: 'Unpaid work',
    },
    8: { no: 'Arbeidsledig', en: 'Unemployed' },
    9: { no: 'Foreldrepermisjon', en: 'Parental leave' },
  },
};

export const EDUCATION = {
  education_1: {
    1: { no: 'Grunnskole eller lavere', en: 'primary school' },
    2: { no: 'Videregående skole', en: 'high school' },
    3: {
      no: 'Inntil 4 år høyere utdanning',
      en: 'up to 4 years higher education',
    },
    4: {
      no: 'Mer enn 4 år høyere utdanning',
      en: 'more than 4 years higher education',
    },
    5: { no: 'Annet', en: 'other' },
  },
};

export const COMPLIANCE = {
  compliance_3: {
    0: { no: 'Ikke gjennomført', en: 'Not at all' },
    1: { no: 'Noe gjennomført', en: 'Some' },
    2: {
      no: 'Ganske regelmessig gjennomført',
      en: 'quite regularly',
    },
    3: { no: 'Veldig regelmessig gjennomført', en: 'very regularly' },
    4: {
      no: 'Har ikke fått øvelser',
      en: 'Has not received any exercises by the physical therapist',
    },
  },
};

export const GPE = {
  gpe_3: {
    1: { no: 'Veldig mye bedre', en: 'very much better' },
    2: { no: 'Mye bedre', en: 'much better' },
    3: { no: 'Minimalt bedre', en: 'minimal better' },
    4: { no: 'Uforandret', en: 'unchanged' },
    5: { no: 'Minimalt verre', en: 'minimal worse' },
    6: { no: 'Mye verre', en: 'much worse' },
    7: { no: 'Veldig mye verre', en: 'very much worse' },
  },
};

export const EMPLOY = {
  employ_paid_1: { no: 'I lønnet arbeid', en: 'Employed paid work' },
  employ_stud_1: { no: 'Skoleelev / Student', en: 'Student' },
  employ_retired_1: { no: 'Pensjonist', en: 'Retired' },
  employ_disability_1: {
    no: 'Uføretrygdet',
    en: 'disability pension',
  },
  employ_sick_1: { no: 'Sykmeldt', en: 'Sick leave' },
  employ_assess_1: {
    no: 'Arbeidsavklaring',
    en: 'Work allowance assessment',
  },
  employ_unpaid_1: {
    no: 'Ulønnet arbeid (eks. hjemme/frivillighetsarbeid)',
    en: 'Unpaid work',
  },
  employ_unempl_1: { no: 'Arbeidsledig', en: 'Unemployed' },
  employ_parentleave_1: {
    no: 'Foreldrepermisjon',
    en: 'Parental leave',
  },
};

export const CONTINOUS_PAIN = {
  yes: {
    no: 'Smerten er kontinuerlig',
    en: '',
  },
  no: {
    no: 'Smerten er av og på',
    en: '',
  },
};

export const GENERAL_HEALTH = {
  Hscl10_score_1: {
    label: {
      en: 'Psychologic distress sum score',
      no: 'Psykisk belastning',
    },
    hover: {
      en: '',
      no:
        '<div class="mt-4">Spørreskjemaet har svaralternativene <br/>"Ikke i det hele tatt" <br/>"Litt"<br/> "En god del"<br/> "Svært mye" <br/>for følgende spørsmål: <br/><br/> 1. Plutselig skremt uten grunn.<br/> 2. Føler du deg svimmel eller kraftesløs.<br/> 3. Føler deg anspent eller opphisset.<br/> 4. Anfall av redsel eller panikk.<br/> 5. Anklager deg selv for ting.<br/> 6. Vanskelig for å sove.<br/> 7. Følelse av håpløshet mhp. framtiden.<br/> 8. Føler deg nedfor.<br/> 9. Føler at alt krever stor anstrengelse.<br/> 10. Føler at du ikke er noe verd.</div>',
    },
  },
};

export const PATIENT_RESSOURCE = [
  {
    label: 'Risikogruppe basert på MSK-tool',
    variable: [
      {
        name: 'mskt_risk_1',
        rules: ['low', 'medium', 'high'],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'Legg vekt på minimal behandlingspakke bestående av råd og veiledning, inkludert instruksjon av øvelser og egentrening',
        'Unngå overmedikalisering av symptomer (sterke medisiner, billeddiagnostiske undersøkelser)',
      ],
      [
        'Fysioterapi rettet mot å redusere smerte og forbedre funksjon',
        'Fremme egenmestring og forståelse av muskel. og skjelettplagene',
        'Diskuter måter å håndtere framtidige episoder med plager (spesielt hvis det er et gjentakende problem)',
        'Der det er indikasjoner, kan en liten andel vurderes for videre henvisning for undersøkelser eller behandling',
      ],
      [
        'Bygge tillit, etablere realistiske forventninger til behandlingseffekt',
        'Gjennomføre full biopsykososial vurdering',
        'Identifisere de viktigste barrierer for bedring/restitusjon (f.eks. feilaktige forestillinger, ubegrunnet frykt, komorbiditeter, sentral sensitivering)',
        'Skille mellom smerterelaterte og ikke-smerterelatert bekymringer og angst (der det er mulig)',
        'Der det er nødvendig, endre fokus fra smertedemping til å forbedre livskvalitet og hvordan man kan fungere med smerter, inkludert smertemestring',
        'Vurdere å utvikle en spesifikk plan for å håndtere arbeidskrav eller vende tilbake til arbeid',
        'Der dette er indikasjoner, vurder dialog med fastlege, tverrfaglig samarbeid og henvisning for undersøkelser eller behandling i spesialisthelsetjenesten',
      ],
    ],
  },
  {
    variable: [
      {
        name: 'health_lit_info_1',
        rules: ['Av og til', 'Ofte', 'Alltid'],
        comparison: 'equal',
      },
    ],
    label: 'Styrke helsekompetanse / Engelsk: health literacy',
    values: [
      [
        'Forklar rolig og bruk enkelt og tydelig språk',
        'Still åpne spørsmål for å evaluere pasientens forståelse',
        'Be pasienten stille spørsmål',
        'Be pasienten gjenfortelle med sine egne ord det som er forklart',
      ],
    ],
  },
  {
    label: 'Psykososiale faktorer (Örebro)',
    variable: [
      { name: 'oreb_score_1', rules: 50, comparison: 'greater' },
    ],
    values: [
      [
        'Kartlegg psykososiale faktorer som stress, anspenthet, nedstemthet, søvn og forventninger om langvarige plager og arbeidsdeltakelse',
        'Kan totalbelastningen endres? Komorbiditeter?',
        'Behov for kontakt med fastlege, verneombud på arbeidsplassen, tverrfaglig samarbeid eller videre henvisning hvis fare for varig arbeidsnedsettelse eller uførhet',
        'Web: https://www.revmatiker.no/lev-bedre-med-usynlig-sykdom/kronikere-i-arbeidslivet/',
        'Web: https://www.arbeidstilsynet.no/tema/psykososialt-arbeidsmiljo/',
      ],
    ],
  },
  {
    label: 'Generelt aktivitetsnivå og fysisk aktivitet',
    variable: [
      {
        name: 'activity_1',
        rules: ['very reduced', 'quite reduced'],
        comparison: 'equal',
      },
      {
        name: 'mskhq_pa_1',
        rules: ['not reduced', '1 day'],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'All form for aktivitet er bedre enn ingenting, og fysisk aktivitet er bra for helsa.',
        'Diskuter med pasienten muligheter for å øke aktivitetsnivået i hverdagen. Legg en plan',
        'Web: https://fysio.no/Pasientinfo/OEvelser',
        'Web: https://www.helsenorge.no/trening-og-fysisk-aktivitet/',
      ],
    ],
  },
  {
    label: 'Sosial aktivitet',
    variable: [
      {
        name: 'mskhq_q7_1',
        rules: ['severely', 'extremely'],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'Viktig å kartlegge barrierer og muligheter for sosiale aktiviteter, diskutere med pasient, og legge en konkret plan for å øke sosial aktivitet',
        'Viktig å knytte bånd og delta i arbeid',
        'https://www.helsenorge.no/psykisk-helse/fem-rad-for-sterkere-psykisk-helse/',
      ],
    ],
  },
  {
    label: 'Søvnvansker',
    variable: [
      {
        name: 'qol15d_q5_sleep_1',
        rules: [
          'moderate problems',
          'great problems',
          'severe sleeplessness',
        ],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'Har pasienten noen formening om årsak til søvnplagene',
        'Hvilke tiltak har vedkommende forsøkt?',
        'Diskuter ulike søvnråd med pasienten (skjermbruk, avslapping, fysisk arbeid og trening, tilstrekkelig søvn (7-8 timer), rutiner for innsovningstid, søvn og våkentid alle dager, søvndeprivering etc.)',
        'Pasientinfo: https://www.helsebiblioteket.no/243597.cms',
        'Videoer til selvhjelp: https://nhi.no/psykisk-helse/kognitiv-terapi/langvarige-sovnvansker/langvarige-sovnvansker-selvhjelpskurs/',
        'Web: https://helse-bergen.no/nasjonal-kompetansetjeneste-for-sovnsykdommer-sovno#quiz-om-norsk-soveromsvaner',
      ],
    ],
  },
  {
    label: 'Utmattet',
    variable: [
      {
        name: 'qol15d_q14_vital_1',
        rules: ['moderately weary', 'very weary', 'extremely weary'],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'Kartlegge totalbelastning i hverdag og jobb',
        'Hva er viktig å prioritere i hverdagen og hvordan balansere aktivitet og hvile.',
        'Web: https://www.kognitiv.no/psykisk-helse/ulike-lidelser/utmattelse/veiledet-selvhjelp/ ',
      ],
    ],
  },
  {
    label: 'Engstelig eller deprimert',
    variable: [
      {
        name: 'eq5d_depr_1',
        rules: ['moderately', 'severely', 'extremely'],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'Er hovedproblemet angst eller depresjon?',
        'Har pasienten noen formening om årsak til angsten/depresjonen? Er det noe fysioterapeuten kan bidra med?',
        'Vurder videre henvisning eller samhandling med andre helsepersonell ved høy grad av angst og/eller depresjon',
        'Info om depresjon: https://www.helsebiblioteket.no/243269.cms',
        'Videoer til selvhjelp ved depresjon: https://www.kognitiv.no/psykisk-helse/ulike-lidelser/depresjon/videobasert-selvhjelp-ved-depresjon/',
        'Info om angst: https://www.helsebiblioteket.no/pasientinformasjon/psykisk-helse/generalisert-angstlidelse',
        'Videoer til selvhjelp ved ulike angstlidelser: https://nhi.no/psykisk-helse/kognitiv-terapi/',
      ],
    ],
  },
  {
    label: 'Psykisk belastning',
    variable: [
      { name: 'hscl_score_1', rules: 1.85, comparison: 'greater' },
    ],
    values: [
      [
        'Hva er pasientens formening om årsaker knyttet til den psykiske belastningen?',
        'Vurder videre henvisning eller samhandling med andre helsepersonell ved høy grad av psykisk belastning',
        'Ulike videoer til selvhjelp: https://nhi.no/psykisk-helse/kognitiv-terapi/',
      ],
    ],
  },
  {
    label: 'Bevegelsesfrykt',
    variable: [
      { name: 'oreb_q10_1', rules: 5, comparison: 'greater' },
    ],
    values: [
      [
        'Kartlegg frykten, om den er rasjonell eller irrasjonell',
        'Gradvis eksponering for frykten, viktig å adressere spesifikke bevegelser man er redd for og ikke generell bevegelse.',
        'Hvis ikke spesifikk frykt, kan tilpasset generell trening og aktivitet for å minske frykten være fornuftig',
      ],
    ],
  },
  {
    label: 'Smertemestring',
    variable: [
      { name: 'pseq_score_1', rules: 5, comparison: 'lesser' },
    ],
    values: [
      [
        'Fokuser på styrkene til pasienten, det pasienten får til',
        'Gjør øvelser så enkle at pasienten opplever å mestre oppgaven',
        'Litt om smertemestring: https://www.revmatiker.no/lev-bedre-med-usynlig-sykdom/smertemestring/',
        'Web: https://www.psykologforeningen.no/publikum/informasjonsvideoer/videoer-om-livsutfordringer/hvordan-mestre-smerter',
        'Web: https://www.youtube.com/watch?v=gJWvhvQxm_k&feature=youtu.be',
      ],
    ],
  },
  {
    label: 'Forventning om langvarige plager',
    variable: [
      { name: 'oreb_q7_1', rules: 5, comparison: 'greater' },
    ],
    values: [
      [
        'Diskuter forventninger til behandling, prognose og forløp.',
        'Gjennomgå forløpsgrafene, vis hvordan det har gått med pasientene som ligner mest på aktuelle pasient. Bruk det til å berolige og forklare forventet prognose',
        'Hvilken informasjon har pasienten med seg fra annet helsepersonell eller andre, diskuter eventuell motstridende informasjon?',
      ],
    ],
  },
  {
    label: 'Arbeidsevne',
    variable: [
      { name: 'work_ability_1', rules: 5, comparison: 'lesser' },
      {
        name: 'employ_1',
        rules: ['sick leave'],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'Diskuter årsaker til nedsatt arbeidsevne (arbeidsplassen, sykdom, private årsaker osv.) og muligheter for tilrettelegging',
        'Hva er pasientens forventninger til å komme tilbake til jobb og er pasienten motivert for å returnere til arbeid?',
        'Arbeidsdeltakelse er terapeutisk i seg selv og viktig for selvfølelsen, og langvarig fravær er sjeldent nødvendig eller til særlig hjelp. Det er urealistisk og heller ikke nødvendig med fullstendig fravær av symptomer før man vender tilbake til arbeid',
        'Vurder behov for samhandling med arbeidsgiver, bedriftshelsetjeneste, fastlege, NAV, andre.',
        'Det kan være nyttig å lage en plan for tilbakeføring til arbeid, og der man legger vekt på hva man kan gjøre tross plager',
        'Web: https://www.revmatiker.no/lev-bedre-med-usynlig-sykdom/kronikere-i-arbeidslivet/',
        'Web: https://www.psykologforeningen.no/publikum/informasjonsvideoer/videoer-om-arbeidsliv/hvordan-mestre-stress-i-arbeidslivet',
      ],
    ],
  },
  {
    label: 'Smerter',
    variable: [
      { name: 'oreb_q2_1', rules: 6, comparison: 'greater' },
      {
        name: 'oreb_q1_1',
        rules: [
          '3 to 6 months',
          '6 to 9 months',
          '9 to 12 month',
          'more than 1 year',
          'more than 12 months',
        ],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'Forklar enkel smertefysiologi (viktig sans/varslingssystem som vi alle er utstyrt med)',
        'Akutt vs langvarig smerte (når smertesansen er hensiktsmessig og når den taper sin verdi)',
        'Web: https://www.smertenettverk.no/pasienter',
        'Web: https://www.retrainpain.org/norsk',
      ],
    ],
  },
  {
    label: 'Utbredte smerter',
    variable: [
      {
        name: 'painsite_number_1',
        rules: 4,
        comparison: 'greater',
      },
    ],
    values: [
      [
        'Er det en naturlig forklaring for utbredte plager',
        'Hvordan er totalbelastningen av fritid og arbeid? Rom for endringer?',
        'Stress, lite søvn, psykososiale belastninger, familiære forhold og psykiske faktorer kan alle medføre utbredte kroppslige plager',
      ],
    ],
  },
  {
    label: 'Røyking',
    variable: [
      {
        name: 'smoking_1',
        rules: ['yes'],
        comparison: 'equal',
      },
    ],
    values: [
      [
        'Er pasienten motivert for røykeslutt?',
        'Web: https://www.lhl.no/gardermoen/hjerteloftet/livsstilsendring/roykeavvenning/',
        'Web: https://www.helsedirektoratet.no/retningslinjer/roykeavvenning/strukturert-hjelp-til-roykeavvenning',
      ],
    ],
  },
  {
    label: 'BMI(overvekt)',
    variable: [{ name: 'bmi_1', rules: 30, comparison: 'greater' }],
    values: [
      [
        'Er pasienten motivert for livsstilsendring og vektreduksjon?',
        'Hva er prøvd tidligere?',
        'Web: https://www.helsebiblioteket.no/pasientinformasjon/mage-og-tarm/overvekt-hvordan-ga-ned-i-vekt',
        'Web: https://www.helsenorge.no/kosthold-og-ernaring/overvekt/unnga-overvekt/',
      ],
    ],
  },
];

export const LCA_SCORE = {
  1: {
    no:
      'Lavt symptomtrykk, god prognose og godt behandlingsutfall kjennetegner fenotype 1. Unngå medikalisering, fokus påminimal behandlingspakke,råd, veiledning og informasjon om god prognose. ',
    en: '',
    hover:
      'Høyere fenotype (fra 1 til 5) er assosiert med et generelt høyere symptomtrykk på tvers av biopsykososiale faktorer, med større behandlingsutfordringer og mulig dårligere behandlingseffekt. Fenotype: "actual score"',
  },
  2: {
    no:
      'Generelt lavt symptomtrykk. Noe forhøyet psykososiale faktorer (gule flagg) som indikerer fokus på enkeltfaktorer i behandlingen. Unngå medikalisering ved å fokuser på råd, veiledning og informasjon om god prognose.',
    en: '',
    hover:
      'Høyere fenotype (fra 1 til 5) er assosiert med et generelt høyere symptomtrykk på tvers av biopsykososiale faktorer, med større behandlingsutfordringer og mulig dårligere behandlingseffekt. Fenotype: "actual score"',
  },
  3: {
    no:
      'Moderat symptomtrykk. Skiller seg fra fenotype 4 ved at de har lite mentale plager og mer lokaliserte plager. Fokuspå aktiv tilnærming, råd og veiledning mot spesifikke risikofaktorer, spesielt bevegelsesfrykt eller unngåelsesatferd. Fokus på arbeidsevne/arbeidsstatus/vurdering av arbeidsplass.',
    en: '',
    hover:
      'Høyere fenotype (fra 1 til 5) er assosiert med et generelt høyere symptomtrykk på tvers av biopsykososiale faktorer, med større behandlingsutfordringer og mulig dårligere behandlingseffekt. Fenotype: "actual score"',
  },
  4: {
    no:
      'Moderat symptomtrykk, langvarige og utbredte plager, økt mentale plager. Fokus på psykologisk rettet fysioterapi. Fokus på arbeidsevne/arbeidsstatus/vurdering av arbeidsplass.Vurder behov for samhandling med annen fysioterapeut og/eller fastlege.',
    en: '',
    hover:
      'Høyere fenotype (fra 1 til 5) er assosiert med et generelt høyere symptomtrykk på tvers av biopsykososiale faktorer, med større behandlingsutfordringer og mulig dårligere behandlingseffekt. Fenotype: "actual score"',
  },
  5: {
    no:
      'Høyt symptomtrykk på tvers av biopsykososiale faktorer. Fokus på psykologisk rettet fysioterapi. Vurder behov for samhandling med annen fysioterapeut, fastlege eller andre helseprofesjoner. ',
    en: '',
    hover:
      'Høyere fenotype (fra 1 til 5) er assosiert med et generelt høyere symptomtrykk på tvers av biopsykososiale faktorer, med større behandlingsutfordringer og mulig dårligere behandlingseffekt. Fenotype: "actual score"',
  },
};
