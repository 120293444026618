import React from 'react';

import { Radar, Line } from 'components/Charts';
import { Card } from 'components/Card';
import { Tabs } from 'components/Tabs';
import {
  PATIENTS,
  RADAR_LABELS,
  GRAPHS,
  GRAPHS_X,
  IBaselinePatient,
  RADAR_VALUE_NORMALIZE,
} from 'data';
import { ComparisonAction } from './ComparisonAction';
import { ComparisonInfo } from './ComparisonInfo';
import { ComparisonPrescription } from './ComparisonPrescription';
import { ComparisonRessource } from './ComparisonRessource';

interface ComparisonPatientProps {
  patients: IBaselinePatient[];
  patientIndex: number;
}

export const ComparisonPatient: React.FC<ComparisonPatientProps> = ({
  patients,
  patientIndex,
}) => {
  return (
    <div className="flex -mx-3 items-stretch">
      <div className="w-1/2 mx-3">
        <Card className="h-full">
          <Tabs
            className="mb-4"
            tabs={[
              {
                id: 'tiltak',
                content: 'Tiltak',
                header: `Matchet ${patients[patientIndex].title}`,
                headerIndex: [0, 1, 2],
                headerColor: `${patients[patientIndex].borderColor}`,
                info: 'Fylt sirkel hos "Alle" betyr at dette er "mye vektlagt" hos minst 2 av de 3 like pasientene. Fylt sirkel hos Valgte pasient betyr "mye vektlagt", og åpen sirkel betyr "noe vektlagt"',
                panelID: 'tiltak-content',
              },
              {
                id: 'info',
                content: 'Info',
                headerIndex: [0, 1, 2],
                headerColor: `${patients[patientIndex].borderColor}`,

                panelID: 'info-content',
              },
              {
                id: 'beskrivelse-av-tiltak',
                content: 'Beskrivelse av tiltak',
                headerIndex: [0, 1, 2],
                headerColor: `${patients[patientIndex].borderColor}`,

                panelID: 'beskrivelse-av-tiltak-content',
              },
              {
                id: 'ressurser',
                content: 'Ressurser',
                header: 'Aktuell pasient',
                headerIndex: [3],
                headerColor: `${patients[0].borderColor}`,
                panelID: 'ressurser',
              },
            ]}
          >
            <div>
              <ComparisonAction
                patients={patients}
                patientIndex={patientIndex}
              />
            </div>
            <div>
              <ComparisonInfo
                patients={patients}
                patientIndex={patientIndex}
              />
            </div>
            <div>
              <ComparisonPrescription
                patients={patients}
                patientIndex={patientIndex}
              />
            </div>
            <div>
              <ComparisonRessource
                patients={patients}
                patientIndex={patientIndex}
              />
            </div>
          </Tabs>
        </Card>
      </div>
      <div className="w-1/2 mx-3">
        <Card className="h-full">
          <Tabs
            className="mb-4"
            tabs={[
              {
                id: 'radar',
                content: 'Pasientprofiler',
                panelID: 'radar-content',
                info: 'Figuren viser pasientens score på 10 ulike faktorer. En skår som ligger inn mot midten av figuren betyr en god skår og dårligere skår desto lengre ut.',
              },
              {
                id: 'graphs',
                content: 'Forløp',
                panelID: 'graphs-content',
                info: 'Figurene viser pasientens svar på ulike faktorer gjennom det kliniske forløpet fra oppstart til 3 måneder.',
              },
            ]}
          >
            <div>
              <Radar
                height={200}
                data={{
                  labels: RADAR_LABELS.map((r) => r.no),
                  datasets: [
                    {
                      ...patients[0],
                      data: Object.entries(patients[0].radar).map(
                        ([key, value]) => {
                          const normalizedValue =
                            RADAR_VALUE_NORMALIZE[key][value];

                          if (key === 'Mentral distress') {
                            return (value - 1) * 33.3;
                          }

                          if (normalizedValue !== undefined) {
                            return normalizedValue;
                          }

                          return value;
                        },
                      ),
                      label: PATIENTS[0].title,
                      pointRadius: 4,
                      borderDash: [4, 2],
                    },
                    {
                      ...patients[patientIndex],
                      data: Object.entries(
                        patients[patientIndex].radar,
                      ).map(([key, value]) => {
                        const normalizedValue =
                          RADAR_VALUE_NORMALIZE[key][value];

                        if (key === 'Mentral distress') {
                          return (value - 1) * 33.3;
                        }

                        if (normalizedValue !== undefined) {
                          return normalizedValue;
                        }

                        return value;
                      }),
                      label: patients[patientIndex].title,
                      pointRadius: 4,
                    },
                  ],
                }}
                options={{
                  scale: {
                    pointLabels: {
                      fontSize: 16,
                      fontColor: '#000',
                      lineHeight: 1,
                    },
                    ticks: {
                      suggestedMin: -5,
                      suggestedMax: 100,
                      stepSize: 10,
                      fontSize: 14,
                    },
                    gridLines: {
                      color: 'rgba(0, 0, 0, 0.15)',
                    },
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      boxWidth: 8,
                      usePointStyle: true,
                      fontSize: 16,
                      fontColor: '#000',
                    },
                  },
                  tooltips: {
                    enabled: false,
                  },
                }}
              />
            </div>
            <div className="grid grid-cols-2">
              {GRAPHS.map(({ title, range }) => (
                <Line
                  key={title}
                  className="mb-4"
                  height={200}
                  // legend={{ display: false }}
                  data={{
                    labels: GRAPHS_X,
                    datasets: [
                      {
                        data: patients[patientIndex].graphs[
                          title
                        ].map((y: number, i: number) => {
                          if (
                            title === 'Smerte' ||
                            title === 'Mestring'
                          ) {
                            // Omit values for week 4 // index 2
                            // Map values here
                            const xMapping = [0, 2, 4, 8, 12];
                            return {
                              x: xMapping[i],
                              y: i === 2 ? null : y,
                            };
                          }

                          // Map values here
                          const xMapping = [0, 2, 4, 8, 12];
                          return { x: xMapping[i], y };
                        }),
                        ...patients[patientIndex],
                        pointRadius: 4,
                        showLine: true,
                      },
                    ],
                  }}
                  options={{
                    spanGaps: true,
                    events: [
                      'mouseout',
                      'click',
                      'touchstart',
                      'touchmove',
                    ],
                    legend: {
                      display: false,
                      position: 'bottom',
                      labels: {
                        boxWidth: 8,
                        usePointStyle: true,
                      },
                    },
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: title,
                    },
                    scales: {
                      xAxes: [
                        {
                          scaleLabel: {
                            display: true,
                            labelString: 'Uke',
                          },
                          ticks: {
                            stepSize: 1,
                            callback: (value) =>
                              value === 0 ? 'baseline' : value,
                          },
                          afterBuildTicks: (scale, ticks) => {
                            // Filter which x values should be shown
                            return [0, 2, 4, 8, 12];
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            min: range.min,
                            max: range.max,
                            reverse: ['Generell bedring'].includes(
                              title,
                            ),
                            stepSize: ['Generell bedring'].includes(
                              title,
                            )
                              ? 1
                              : 2,
                            callback: (value) => {
                              let label = '';
                              if (range.min === value) {
                                label = `${range.minLabel}`;
                              }

                              if (range.max === value) {
                                label = `${range.maxLabel}`;
                              }

                              if (
                                ['Generell bedring'].includes(
                                  title,
                                ) &&
                                range.median === value
                              ) {
                                label = `${range.medianLabel}`;
                              }

                              return `${label} ${value}`;
                            },
                          },
                        },
                      ],
                    },
                    tooltips: {
                      mode: 'index',
                      intersect: false,
                      callbacks: {
                        label: (tooltipItem, data) =>
                          `${tooltipItem.yLabel}` || '',
                      },
                    },
                    // hover: {
                    //   mode: 'nearest',
                    //   intersect: true,
                    // },
                  }}
                />
              ))}
            </div>
          </Tabs>
        </Card>
      </div>
    </div>
  );
};
