import { PatientResponseInfo } from 'types';

export const getAllPatients = async () => {
  try {
    const token = localStorage.getItem('token') || '';
    return fetch(
      `${process.env.REACT_APP_URL_CBR}/patient/getAllPatients`,
      {
        headers: {
          Authorization: token,
        },
      },
    ).then((response) => {
      return response.json();
    });
    // return await Promise.all(
    //   Object.entries(PATIENTS_TEMP).map(async ([key, value]) => {
    //     const q = new URLSearchParams({
    //       patientId: value.id || value.patientId,
    //     });
    //     return fetch(
    //       `${process.env.REACT_APP_URL_CBR}/patient/getPatientById?${q}`,
    //       {
    //         headers: {
    //           Authorization: token,
    //         },
    //       },
    //     )
    //       .then((response) => {
    //         return response.json();
    //       })
    //       .catch((err) => console.log(err));
    //   }),
    // );
  } catch (error) {}
};

export const getPatientArrayValues = async () => {
  try {
    const data = await getAllPatients();
    if (!data) return [];

    return data
      .map((p: PatientResponseInfo) => {
        if (!p) return null;
        const { patientId, patientDetails } = p;
        const entries = Object.entries(patientDetails)
          .map(([key, value]) => {
            if (
              Array.isArray(value) &&
              !['resources'].includes(key)
            ) {
              return `${key}: ${value}`;
            }

            return null;
          })
          .filter((b) => b);

        return {
          patientId,
          entries,
        };
      })
      .filter((p: any) => {
        return (p?.entries || []).length > 0;
      });
  } catch (error) {
    console.log('getPatientArrayValues', error);
  }
};

export const getPatientMappedBodyValues = async () => {
  const data = await getAllPatients();
  if (!data) return [];

  return data
    .map((p: PatientResponseInfo) => {
      if (!p) return null;
      const { patientId, patientDetails } = p;
      const { body_main_1 } = patientDetails;
      const bodyParts = Object.entries(patientDetails)
        .map(([key, value]) => {
          if (
            key.split('_')[0].toLocaleLowerCase() ===
            body_main_1.toLowerCase()
          ) {
            return `${key}: ${value}`;
          } else return null;
        })
        .filter((b) => b);
      return {
        patientId,
        body_main_1,
        data: bodyParts,
      };
    })
    .filter((p: any) => p);
};
