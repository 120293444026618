export interface IExamination {
  [key: number]: string;
}

export interface IExaminations {
  neck_spurling_1: IExamination;
  neck_nevro_pow_1: IExamination;
  neck_nevro_sens_1: IExamination;
  neck_nevro_reflex_1: IExamination;
  // neck_nevro_other_1: IExamination;
  neck_conclusion_1: IExamination;
  shoulder_passive_extrot_1: IExamination;
  back_slr_1: IExamination;
  back_nevro_pow_1: IExamination;
  back_nevro_sens_1: IExamination;
  back_nevro_reflex_1: IExamination;
  knee_hydrops_1: IExamination;
  knee_trumatic_1: IExamination;
  [key: string]: IExamination;
}

export const EXAMINATIONS: IExaminations = {
  neck_spurling_1: {
    0: '',
    1: 'Positiv spurling test',
    2: '',
  },
  neck_nevro_pow_1: { 1: 'Redusert kraft' },
  neck_nevro_sens_1: { 1: 'Redusert sensibilitet' },
  neck_nevro_reflex_1: { 1: 'Unormale reflekser' },
  // neck_nevro_other_1: { 1: 'Ev. kommentar nevrologiske funn' }, // this is comment and should not be mapped
  neck_conclusion_1: {
    1: '',
    2: '',
    3: 'Tegn på nerverotaffeksjon',
    4: 'Tegn på alvorlig patologisk forandring',
  },
  shoulder_passive_extrot_1: {
    0: '',
    1: 'Redusert passiv utadrotasjon mer enn 50% sammenlignet med motsatt side',
  },
  back_slr_1: {
    0: '',
    1: 'Positiv straight leg raise (Lasegues prøve) ',
    2: '',
  },
  back_nevro_pow_1: { 1: 'Redusert kraft' },
  back_nevro_sens_1: { 1: 'Redusert sensibilitet' },
  back_nevro_reflex_1: { 1: 'Unormale reflekser' },
  knee_hydrops_1: { 1: 'Hydrops i kne ved undersøkelse' },
  knee_trumatic_1: { 1: 'Pasienten har vært utsatt for traume' },
};
