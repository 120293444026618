import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardProgressBar,
} from 'components/Card';
import {
  CONTINOUS_PAIN,
  GROUP,
  PAIN_SCORE_TEXT,
  PAIN_SITES,
} from 'data';
import {
  getValidEntries,
  replaceScore,
  variableChecker,
} from 'utils';
import { HoverContent } from 'components/HoverContent';

// import { ReactComponent as Body } from 'assets/svg/body.svg';
// import { ReactComponent as BodyPain } from 'assets/svg/body-pain.svg';
import { PainMap } from 'components/PainMap';
import { Front, Back, PatientResponseInfo } from 'types';
import { CopyText } from 'components/CopyText';

interface PainProps {
  className?: String;
  cardClassName?: String;
  data: PatientResponseInfo;
}

export const Pain: React.FC<PainProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const {
    oreb_q1_1,
    oreb_q2_1,
    painsite_elbow_1,
    painsite_feet_1,
    painsite_hand_1,
    painsite_head_1,
    painsite_knee_1,
    painsite_lback_1,
    painsite_neck_1,
    painsite_shou_1,
    painsite_thigh_1,
    painsite_uback_1,
    pain_continuous_1,
    body_map_1,
  } = patientDetails;

  // Default to an array
  const { front = [], back = [] } = body_map_1 || {};

  // Pain map
  const coordinates = [
    ...(!Array.isArray(front)
      ? [(front as unknown) as Front]
      : front
    ).map((c: Front) => c),
    ...(!Array.isArray(back)
      ? [(back as unknown) as Back]
      : back
    ).map((c: Back) => ({
      x: c.x + 8,
      y: c.y,
    })),
  ];

  // Pain areas
  const painsites = getValidEntries({
    painsite_elbow_1,
    painsite_feet_1,
    painsite_hand_1,
    painsite_head_1,
    painsite_knee_1,
    painsite_lback_1,
    painsite_neck_1,
    painsite_shou_1,
    painsite_thigh_1,
    painsite_uback_1,
  })
    .map(([key, value]) => PAIN_SITES[key].no)
    .filter((v, i, a) => a.indexOf(v) === i);

  return (
    <div className={`${className ? className : ''}`}>
      <Card
        noSpacing={true}
        className={`${cardClassName ? cardClassName : ''}`}
      >
        <CardLabel
          className="mb-8"
          text={GROUP.pain.title.no}
          size={CardLabelType.lg}
        />
        <div>
          <HoverContent
            content={`${replaceScore(
              GROUP.pain.oreb_q2_1.hover.no,
              oreb_q2_1,
            )} ${
              pain_continuous_1
                ? `(${
                    CONTINOUS_PAIN[
                      pain_continuous_1.toLowerCase() as 'yes' | 'no'
                    ].no
                  })`
                : ''
            }`}
          >
            <CopyText
              content={`${replaceScore(
                GROUP.pain.oreb_q2_1.hover.no,
                oreb_q2_1,
              )} ${
                pain_continuous_1
                  ? `(${
                      CONTINOUS_PAIN[
                        pain_continuous_1.toLowerCase() as
                          | 'yes'
                          | 'no'
                      ].no
                    })`
                  : ''
              }`}
            >
              <CardProgressBar
                text={GROUP.pain.oreb_q2_1.label.no}
                progress={oreb_q2_1}
                parseProgressValue={(value) => (100 / 10) * value}
                min={0}
                max={10}
                indicators={[4, 8]}
                disabled={variableChecker(oreb_q2_1)}
              />
            </CopyText>
          </HoverContent>

          <HoverContent
            content={`${replaceScore(
              GROUP.pain.oreb_q1_1.hover.no,
              (PAIN_SCORE_TEXT.oreb_q1_1[oreb_q1_1] || {}).no,
            )}`}
          >
            <CopyText
              content={replaceScore(
                GROUP.pain.oreb_q1_1.hover.no,
                (PAIN_SCORE_TEXT.oreb_q1_1[oreb_q1_1] || {}).no,
              )}
            >
              <CardProgressBar
                text={GROUP.pain.oreb_q1_1.label.no}
                progress={
                  (PAIN_SCORE_TEXT.oreb_q1_1[oreb_q1_1] || {}).value
                }
                parseProgressValue={(value) => {
                  if (value <= 4) {
                    return 3;
                  }

                  if (value <= 6) {
                    return 25;
                  }

                  if (value === 7) {
                    return 50;
                  }

                  if (value <= 9) {
                    return 75;
                  }

                  return 100;
                }}
                min={1}
                median={7}
                max={10}
                indicators={[6, 8]}
                disabled={variableChecker(oreb_q1_1)}
              />
            </CopyText>
          </HoverContent>

          <HoverContent
            content={replaceScore(
              GROUP.pain.Painsite_1.hover.no,
              painsites.length,
            )}
            className="inline-block"
          >
            <CopyText
              content={replaceScore(
                GROUP.pain.Painsite_1.hover.no,
                painsites.length,
              )}
            >
              <CardLabel
                text="Smertesteder"
                className="mb-5 inline-block"
                size={CardLabelType.lg}
              />
            </CopyText>
          </HoverContent>
        </div>
        <div className="flex items-center justify-center flex-1">
          <HoverContent
            content={
              <div>
                {painsites.map((v) => (
                  <div key={v}>{v}</div>
                ))}
              </div>
            }
            className="relative flex-1 h-full flex justify-center items-center"
          >
            <CopyText content={painsites.map((p) => p)}>
              <PainMap coordinates={coordinates} />
              {/* <Body className="absolute" style={{ height: '450px' }} />
            <BodyPain
              className="absolute z-10"
              style={{
                height: '450px',
                marginTop: '1px',
                transform: 'scale(0.98)',
                marginLeft: '3px',
              }}
            /> */}
            </CopyText>
          </HoverContent>
        </div>
      </Card>
    </div>
  );
};
