import React from 'react';
import { CardLabel } from './CardLabel';
import {
  ProgressBar,
  ProgressBarProps,
} from 'components/ProgressBar';

interface CardProgressBarProps extends ProgressBarProps {
  text: string;
  noSpacing?: boolean;
  min?: number;
  disabled?: boolean;
  median?: number;
  parseProgressValue?: (value: number) => number;
}

export const CardProgressBar: React.FC<CardProgressBarProps> = ({
  text,
  progress,
  max,
  indicators,
  noSpacing,
  min,
  disabled,
  median,
  parseProgressValue,
}) => {
  return (
    <div className={`${noSpacing ? '' : 'mb-8'}`}>
      <div className="mb-2">
        <CardLabel
          text={text}
          className={`font-normal${
            disabled ? 'opacity opacity-25' : ''
          }`}
        />
      </div>
      <ProgressBar
        progress={disabled ? -1 : progress}
        min={min}
        median={median}
        max={max}
        indicators={indicators}
        parseProgressValue={parseProgressValue}
      />
    </div>
  );
};
