import React from 'react';

import { ReactComponent as Flag } from 'assets/svg/flag.svg';
import { ReactComponent as RedFlag } from 'assets/svg/red-flag.svg';

interface CardFlagProps {
  flag: boolean;
  text?: string;
  noSpacing?: boolean;
}

export const CardFlag: React.FC<CardFlagProps> = ({
  flag,
  text,
  noSpacing,
}) => {
  const Component = flag ? RedFlag : Flag;

  return (
    <div
      className={`flex items-center text-sm ${
        !flag ? 'text-gray-500' : ''
      } ${noSpacing ? '' : 'mb-6'}`}
    >
      <Component
        className={`stroke-current ${
          flag ? 'text-red-500' : ''
        } mr-6`}
      />
      {text}
    </div>
  );
};
