import React from 'react';
import { NavLink, LinkProps, useRouteMatch } from 'react-router-dom';

import { ReactComponent as Dashboard } from 'assets/svg/dashboard.svg';
import { ReactComponent as Checklist } from 'assets/svg/check-list.svg';
import { ReactComponent as Chart } from 'assets/svg/p-chart.svg';
import { ReactComponent as Document } from 'assets/svg/document.svg';
import { ReactComponent as User } from 'assets/svg/user.svg';
import { ReactComponent as Logout } from 'assets/svg/logout.svg';
import { HoverContent } from 'components/HoverContent';

const ICONS = {
  dashboard: Dashboard,
  checklist: Checklist,
  chart: Chart,
  document: Document,
  user: User,
  logout: Logout,
};

export enum SidebarItemType {
  dashboard = 'dashboard',
  checklist = 'checklist',
  chart = 'chart',
  document = 'document',
  user = 'user',
  logout = 'logout',
}

interface SidebarItemProps extends Pick<LinkProps, 'to'> {
  title: string;
  type?: SidebarItemType | undefined;
  onClick?(): void;
  disabled?: boolean;
  disableText?: string;
  target?: string;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  title,
  type,
  to,
  onClick,
  disabled,
  disableText,
  target,
}) => {
  const match = useRouteMatch(String(to));
  const active = match?.isExact ? '' : 'text-gray-500';

  let Icon = null;
  if (type) {
    Icon = ICONS[type];
  }

  if (disabled) {
    return (
      <HoverContent content={disableText}>
        <div
          className={`flex items-center p-6 group cursor-not-allowed focus:outline-none text-gray-500`}
        >
          {Icon && <Icon className="mr-4 w-6 stroke-current" />}
          <span className="font-medium text-sm">{title}</span>
        </div>
      </HoverContent>
    );
  }

  return onClick ? (
    <button
      onClick={onClick}
      className={`flex items-center p-6 group cursor-pointer focus:outline-none text-gray-500 hover:text-black`}
    >
      {Icon && (
        <Icon className="mr-4 w-6 stroke-current group-hover:text-indigo-600" />
      )}
      <span className="font-medium text-sm group-hover:text-black">
        {title}
      </span>
    </button>
  ) : (
    <NavLink
      to={to}
      target={target ? target : '_self'}
      className={`flex items-center p-6 group cursor-pointer ${active}`}
    >
      {Icon && (
        <Icon
          className={`mr-4 w-6 stroke-current group-hover:text-indigo-600 ${
            !active ? 'text-indigo-600' : ''
          }`}
        />
      )}
      <span className="font-medium text-sm group-hover:text-black">
        {title}
      </span>
    </NavLink>
  );
};
