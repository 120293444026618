import React, { useContext, useEffect, useState } from 'react';
import * as JsSearch from 'js-search';

import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { useRouter } from 'hooks';
import { PatientResponseInfo } from 'types';
import { PATIENTS_TEMP } from 'components/Patients';
import { Helmet } from 'react-helmet';
import { UserContext } from 'context/userContext';
import { ImportPatient } from 'components/ImportPatient';
import { getAllPatients } from 'services';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const stemmer = require('porter-stemmer').stemmer;

interface ChangePatientProps {}

export const ChangePatient: React.FC<ChangePatientProps> = () => {
  const { setPatientIndex } = useContext(UserContext);
  const [data, setData] = useState<PatientResponseInfo[]>();
  const router = useRouter();
  const [keyword, setKeyword] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const username = localStorage.getItem('username');

  // SPA Link Tracking
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Velg patient`,
      href: `https://supportprim.idi.ntnu.no${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  // Fetch users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token') || '';
        const query = username
          ? new URLSearchParams({
              clinicId: username,
            })
          : '';
        const response = await fetch(
          `${process.env.REACT_APP_URL_CBR}/patient/getPatientByClinicId?${query}`,
          {
            headers: {
              Authorization: token,
            },
          },
        );
        const json = await response.json();
        if (username === 'ui') {
          const patients = (await getAllPatients()) || [];
          setData(
            [...json, ...patients].sort((a, b) => {
              return (
                Number(new Date(b.dateUpdated)) -
                Number(new Date(a.dateUpdated))
              );
            }),
          );
        } else if (
          ['support200', 'support300'].includes(username || '')
        ) {
          setData(
            [...json]
              .filter((p) => {
                return !PATIENTS_TEMP.map((c) => c.id).includes(
                  p.patientId,
                );
              })
              .sort((a, b) => {
                return (
                  Number(new Date(a.dateUpdated)) -
                  Number(new Date(b.dateUpdated))
                );
              }),
          );
        } else {
          setData(
            [...json].filter((p) => {
              return !PATIENTS_TEMP.map((c) => c.id).includes(
                p.patientId,
              );
            }),
          );
        }
      } catch (error) {
        console.log('PatientsError', error);
      }
    };
    fetchData();
  }, []);

  if (!data) return null;

  const search = new JsSearch.Search('patientId');
  search.tokenizer = new JsSearch.StemmingTokenizer(
    stemmer, // Function should accept a string param and return a string
    new JsSearch.SimpleTokenizer(),
  );
  search.addIndex('patientId');
  search.addIndex('birthYear');

  search.addDocuments(data!);

  const handleInputSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    const result: any = search.search(value);
    setKeyword(value);
    setSearchResult(result);
  };

  const handleClickPatient = (id: string) => {
    localStorage.setItem('user', `${id}`);
    setPatientIndex(0);
    router.history.push('/app');
  };

  return (
    <div>
      <Helmet>
        <title>{`SupportPrim - Velg pasient`}</title>
      </Helmet>
      <ImportPatient />
      <Card>
        <Input
          placeholder="Søk pasient"
          onChange={handleInputSearch}
        />
      </Card>
      <Card noSpacing={true} className="py-6 mb-6">
        <div className="flex px-6 mb-4">
          <div className="w-1/3 text-sm font-medium">Id</div>
          <div className="w-1/3 text-sm font-medium">Fødselsår</div>
          <div className="w-1/3 text-sm font-medium"></div>
        </div>

        {(keyword ? searchResult : data).map(
          (
            {
              patientId,
              patientDetails,
              dateUpdated,
              similarPatients,
            },
            index,
          ) => {
            const { birth_year, consent_1 } = patientDetails;

            if (!birth_year) return null;
            return (
              <div
                className={`flex text-gray-800 hover:bg-gray-200 opacity hover:bg-opacity-25 py-3 px-6 cursor-pointer ${
                  localStorage.getItem('user') === patientId
                    ? 'font-bold text-indigo-600'
                    : ''
                }`}
                key={`${patientId}+${index}`}
                onClick={() => {
                  if (
                    consent_1?.toLowerCase() === 'jeg samtykker ikke'
                  ) {
                    alert('Pasient samtykker ikke');
                  } else {
                    handleClickPatient(patientId);
                  }
                }}
              >
                <div className="w-1/3 text-sm">
                  <div>{patientId}</div>
                  {username === 'ui' &&
                  dateUpdated &&
                  Object.keys(similarPatients || {}).length >= 3 ? (
                    <div className="text-gray-600 font-normal">
                      {new Date(dateUpdated).toLocaleDateString(
                        'da-DK',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        },
                      )}
                    </div>
                  ) : null}

                  {(username === 'support300' ||
                    username === 'support200') && (
                    // Display created at date for a specific user
                    <div>
                      <div className="text-gray-600 font-normal">
                        {new Date(
                          data[index].dateUpdated,
                        ).toLocaleDateString('da-DK', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </div>
                      <div className="text-red-600 font-normal">
                        {Object.keys(data[index].similarPatients)
                          .length <= 0
                          ? 'Missing Similar Patients'
                          : ''}
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-1/3 text-sm">{birth_year}</div>
                <div className="w-1/3 text-sm text-right cursor-pointer hover:text-indigo-500">
                  {consent_1?.toLowerCase() === 'jeg samtykker ikke'
                    ? 'Paisenten har ikke samtykket til deltakelse.'
                    : 'Velg'}
                </div>
              </div>
            );
          },
        )}
      </Card>
    </div>
  );
};
