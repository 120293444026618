// Remove all the patients similar patients

import { sortByKey } from 'utils';

export const removeSimilarPatient = async (patients: string[]) => {
  try {
    const token = localStorage.getItem('token') || '';

    await Promise.all(
      patients.map((v) => {
        return fetch(
          `${
            process.env.REACT_APP_URL_CBR
          }/patient/getPatientById?${new URLSearchParams({
            patientId: v,
          })}`,
          {
            headers: {
              Authorization: token,
            },
          },
        )
          .then(async (response) => {
            const data = await response.json();

            // Save new info to patient
            const saveRes = await fetch(
              `${process.env.REACT_APP_URL_CBR}/patient/savePatient`,
              {
                method: 'POST',
                body: JSON.stringify({
                  ...sortByKey({ ...data }),
                  similarPatients: {},
                  patientDetails: sortByKey({
                    ...data.patientDetails,
                  }),
                }),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: token,
                },
              },
            );

            return await saveRes.json();
          })
          .catch((err) => console.log(err));
      }),
    );
  } catch (error) {
    console.log('savePatientBaseline error: ', error);
  }
};
