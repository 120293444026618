import React from 'react';

import { CardLabel, CardLabelType } from './CardLabel';

interface CardFormTextProps {
  text: string;
}

export const CardFormText: React.FC<CardFormTextProps> = ({
  text,
  children,
}) => {
  return (
    <div className="mb-8">
      <CardLabel text={text} size={CardLabelType.xl} />
      {children}
    </div>
  );
};
