import React from 'react';

import {
  CardLabel,
  CardCheckmark,
  CardLabelType,
} from 'components/Card';
import { COMPARISON_PRESCRIPE, IBaselinePatient } from 'data';

interface ComparisonActionProps {
  patients: IBaselinePatient[];
  patientIndex: number;
}

export const ComparisonAction: React.FC<ComparisonActionProps> = ({
  patients,
  patientIndex,
}) => {
  return (
    <>
      <div className="flex mt-8">
        <div className="w-8">
          <CardLabel
            text="Alle"
            size={CardLabelType.sm}
            className="mb-3"
          />
        </div>
        <div className="w-16">
          <CardLabel
            text={`${patients[patientIndex].title}`}
            size={CardLabelType.sm}
            className="mb-3"
          />
        </div>
        <div>
          <CardLabel
            text=""
            size={CardLabelType.sm}
            className="mb-3"
          />
        </div>
      </div>
      {Object.entries(patients[patientIndex].prescription).map(
        ([key, value], keyIndex) => {
          // Compare prescription across several users
          const allPrescription = value.map((p, i) => {
            const a = patients[1].prescription[key][i];
            const b = patients[2].prescription[key][i];
            const c = patients[3].prescription[key][i];
            return [a, b, c].filter((v) => v === 'Mye').length >= 2;
          });
          const hasValues = value.filter((v) => {
            return ['ja', 'noe', 'mye'].includes(
              (v || '').toLowerCase(),
            );
          });
          return (
            (hasValues.length >= 1 ||
              allPrescription.filter((v) => v).length >= 1) && (
              <div className="mb-8" key={`${key}-${keyIndex}`}>
                <CardLabel
                  text={`${key}:`}
                  size={CardLabelType.sm}
                  className="ml-24 mb-3 font-medium h-5"
                />
                {value.map((v, index) => {
                  const isSimilar = allPrescription[index];
                  const isCheck = ['ja', 'noe', 'mye'].includes(
                    (v || '').toLowerCase(),
                  );

                  return isCheck || isSimilar ? (
                    <div key={`${v}-${index}`}>
                      <CardCheckmark
                        similar={isSimilar}
                        text={`${COMPARISON_PRESCRIPE[key][index]}`}
                        check={isCheck}
                        emphasize={v === 'Mye' || v === 'Ja'}
                        color={
                          patients[patientIndex].pointBackgroundColor
                        }
                      />
                    </div>
                  ) : null;
                })}
              </div>
            )
          );
        },
      )}
    </>
  );
};
