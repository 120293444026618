import React from 'react';

import { parseProgress } from 'utils';

interface PyramidProps {
  progress?: number | undefined;
  max?: number | undefined;
  indicators?: number[] | undefined;
}

export const Pyramid: React.FC<PyramidProps> = ({
  progress,
  max = 100,
  indicators,
}) => {
  let parsedProgress = 0;
  let indicatorColor = 'bg-green-500';
  if (progress === undefined || (!progress && progress !== 0)) {
    indicatorColor = '';
  } else if (indicators) {
    if (indicators[0] < progress && indicators[1] >= progress) {
      indicatorColor = 'bg-yellow-500';
      parsedProgress = parseProgress((indicators[1] / max) * 100);
    } else if (indicators[1] < progress) {
      indicatorColor = 'bg-red-500';
      parsedProgress = parseProgress(max * 100);
    } else {
      parsedProgress = parseProgress((indicators[0] / max) * 100);
    }
  }

  const riskLevel = [
    'bg-green-500',
    'bg-yellow-500',
    'bg-red-500',
  ].findIndex((v) => v === indicatorColor);

  return (
    <div className="relative pr-10 -ml-4">
      <div className="arrow-up overflow-hidden h-20 w-20">
        <div
          className={`indicator-vertical absolute left-0 bottom-0 w-full ${indicatorColor}`}
          style={{ height: `${parsedProgress}%` }}
        ></div>
        <div
          className={`indicator-vertical absolute left-0 bottom-0 w-full`}
          style={{
            height: `${
              (100 / 3) * (riskLevel > 2 ? riskLevel + 1 : riskLevel)
            }%`,
            backgroundColor: '#e2e8f0',
          }}
        ></div>
      </div>
      <span
        className={`absolute top-0 text-black text-sm leading-none ${
          riskLevel === 2 ? 'font-medium' : 'opacity-25'
        }`}
        style={{ height: `${parsedProgress}%`, left: '75%' }}
      >
        Høy
      </span>
      <div
        className={`indicator-vertical absolute w-full z-10 border-b border-white`}
        style={{ bottom: `${(1 / 3) * 100}%` }}
      ></div>
      <span
        className={`absolute text-black text-sm leading-none pb-1 ${
          riskLevel === 1 ? 'font-medium' : 'opacity-25'
        }`}
        style={{ bottom: `${(1 / 3) * 100}%`, left: '75%' }}
      >
        Medium
      </span>
      <div
        className={`indicator-vertical absolute w-full z-10 border-b border-white`}
        style={{ bottom: `${(2 / 3) * 100}%` }}
      ></div>
      <span
        className={`absolute bottom-0 text-black text-sm leading-none ${
          riskLevel === 0 ? 'font-medium' : 'opacity-25'
        }`}
        style={{ left: '75%' }}
      >
        Lav
      </span>
    </div>
  );
};
