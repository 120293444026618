import { HoverContent } from 'components/HoverContent';
import React from 'react';
import { CardLabel, CardLabelType } from './CardLabel';
import { ReactComponent as Warning } from 'assets/svg/warning.svg';

interface CardPatientProps {
  title: string;
  active?: boolean;
  onClick?(): void;
  hover?: boolean;
  patientColor?: string;
  id: string;
  similarPatients: { [key: string]: string };
}

export const CardPatient: React.FC<CardPatientProps> = ({
  title,
  active,
  children,
  onClick,
  hover = true,
  patientColor,
  id,
  similarPatients,
}) => {
  const similarTreshold = Object.entries(similarPatients).filter(
    ([key, value]) => {
      return key === id && parseFloat(value) <= 0.7;
    },
  );

  return (
    <div
      className={`${
        hover ? 'cursor-pointer group' : ''
      } h-full flex flex-col`}
      onClick={onClick}
    >
      {active && (
        <div
          className="absolute top-0 left-0 w-full h-2"
          style={{ backgroundColor: patientColor }}
        ></div>
      )}

      <div
        className="absolute top-0 left-0 w-full h-2 opacity duration-75 ease-in-out opacity-0 group-hover:opacity-100"
        style={{ backgroundColor: patientColor }}
      ></div>

      <div className="flex mb-4">
        <CardLabel text={title} size={CardLabelType.xl} />
        {similarTreshold.length >= 1 && (
          <span className="ml-auto">
            <HoverContent
              content={`<div class="mb-4">NB! Denne pasienten avviker fra minstekriteriet for en god match. Ta hensyn til dette i dine valg</div>`}
            >
              <Warning className="w-5 h-5 stroke-current text-red-300" />
            </HoverContent>
          </span>
        )}
      </div>
      <div className="mb-2">{children}</div>
      <div className="mt-auto text-sm text-right text-gray-400 group-hover:text-gray-800 h-2">
        {active ? '' : 'Vis mer'}
      </div>
    </div>
  );
};
