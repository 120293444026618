import React from 'react';
import { SidebarItem, SidebarItemType } from './SidebarItem';
import { Logo } from 'components/Logo';
import { PatientInfo } from 'components/PatientInfo';
import { useHistory, useLocation } from 'react-router-dom';
import { PatientResponseInfo } from 'types';
// import { UserContext } from 'context/userContext';

interface SidebarProps {
  data: PatientResponseInfo;
}

export const Sidebar: React.FC<SidebarProps> = ({ data }) => {
  // const { patientIndex } = React.useContext(UserContext);
  const { patientDetails, similarPatients } = data;
  const { psfs1_act_1, psfs1_1, body_main_1 } = patientDetails;
  const history = useHistory();
  const location = useLocation();

  if (location.pathname === '/app/eksport') return null;
  if (!data) return null;

  // disabled patient matching and we assume it has 3 similar patients
  const baseline =
    [psfs1_act_1, psfs1_1, body_main_1].filter((v) => {
      if (v === 0) {
        return true;
      }
      return v && `${v}`.length >= 1;
    }).length < 3 || Object.keys(similarPatients).length <= 0;

  function logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    history.push('/');
  }

  return (
    <div className="bg-white max-w-sidebar w-full flex flex-col">
      <div className="mt-2">
        <Logo />
        <PatientInfo patient={data!} />
      </div>
      <div className="my-auto">
        <SidebarItem
          to="/app"
          title="Pasientprofil"
          type={SidebarItemType.dashboard}
        />
        <SidebarItem
          to="/app/baseline-common"
          title="Undersøkelse"
          type={SidebarItemType.checklist}
        />
        <SidebarItem
          to="/app/comparison"
          title="Pasientmatching"
          type={SidebarItemType.chart}
          disabled={baseline}
          disableText="Pasientspesifikk funkssjonsskala og diagnose må fylles ut og lagres før du kan utføre matching"
        />
        <SidebarItem
          to="/app/eksport"
          title="Eksport"
          target="_blank"
          type={SidebarItemType.document}
          disableText="Pasientmatching - velg pasientmatching"
        />
        {/* <SidebarItem
          to="/app/summary"
          title="Eksport"
          type={SidebarItemType.document}
          disableText="Pasientmatching - velg pasientmatching"
        /> */}
      </div>
      <div className="mt-auto">
        <SidebarItem
          to="/app/change-patient"
          title="Velg pasient"
          type={SidebarItemType.user}
        />
        <SidebarItem
          onClick={logout}
          to="/"
          title="Logg av"
          type={SidebarItemType.logout}
        />
      </div>
    </div>
  );
};
