import React, { useState } from 'react';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';

import { FormInput, FormButton, FormError } from 'components/Form';
import { ImportPatientSchema } from './ImportPatientSchema';
import { useRouter } from 'hooks';
import { PatientResponseInfo } from 'types';
import { hasTestPermission } from 'utils';

interface FormValues {
  journalId: string;
  birthYear: string;
  clinicId: string | '';
}

interface ImportPatientProps {}

export const ImportPatient: React.FC<ImportPatientProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | null>(
    null,
  );
  const router = useRouter();
  const username = localStorage.getItem('username');

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token') || '';
      const { setSubmitting } = actions;
      const { journalId, birthYear, clinicId } = values;

      // await importAllPatient();
      // return;

      const query = username
        ? new URLSearchParams({
            birthYear,
            journalId,
            clinicId: clinicId.toLowerCase(),
          })
        : '';

      await fetch(
        `${process.env.REACT_APP_URL_CBR}/patient/getPatient?${query}`,
        {
          headers: {
            Authorization: token,
          },
        },
      )
        .then(async (res) => {
          return res.json();
        })
        .then((res: PatientResponseInfo) => {
          setLoading(false);
          setSubmitting(false);
          if (
            res.patientDetails.consent_1?.toLowerCase() ===
            'jeg samtykker ikke'
          ) {
            alert('Paisenten har ikke samtykket til deltakelse');
          } else {
            localStorage.setItem('user', `${res.patientId}`);
            router.history.push('/app');
          }
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError({ message: 'Finner ikke pasienten' });
      console.log('SignInError:', err.message);
    }
  };

  return (
    <Formik
      initialValues={{
        journalId: '',
        birthYear: '',
        clinicId: username || '',
      }}
      onSubmit={(
        values: FormValues,
        actions: FormikHelpers<FormValues>,
      ) => {
        handleSubmit(values, actions);
      }}
      validationSchema={ImportPatientSchema}
    >
      {(props: FormikProps<FormValues>) => {
        return (
          <>
            <div className="mb-2 text-sm">importere pasient</div>
            <Form className="flex flex-row w-full space-x-6 mb-6">
              {hasTestPermission() && (
                <div className="w-64">
                  <FormInput
                    id="clinicId"
                    name="clinicId"
                    placeholder="id"
                  />
                </div>
              )}
              <div className="w-64">
                <FormInput
                  id="journalId"
                  name="journalId"
                  placeholder="Journal id"
                  type="text"
                />
              </div>
              <div className="w-64">
                <FormInput
                  id="birthYear"
                  name="birthYear"
                  placeholder="Fødselsår"
                />
              </div>
              <div className="w-32">
                <FormButton
                  className="w-full"
                  loading={loading}
                  title="Importer"
                />
              </div>
              <FormError error={error} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
