import React from 'react';
import {
  Scatter as Chart,
  ChartComponentProps,
} from 'react-chartjs-2';

interface LineProps extends ChartComponentProps {
  className?: String;
}

export const Line = React.forwardRef<Chart, LineProps>(
  ({ data, legend, options, width, height, className }, ref) => {
    return (
      <div className={`${className ? className : ''}`}>
        <Chart
          data={data}
          legend={legend}
          options={options}
          width={width}
          height={height}
          ref={ref}
        />
      </div>
    );
  },
);

// export const Line: React.FC<LineProps> = ({
//   data,
//   legend,
//   className,
//   options,
//   width,
//   height,
// }) => {
//   return (
//     <div className={`${className ? className : ''}`}>
//       <Chart
//         data={data}
//         legend={legend}
//         options={options}
//         width={width}
//         height={height}
//       />
//     </div>
//   );
// };
