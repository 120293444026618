import React from 'react';
import { CardLabel } from './CardLabel';

interface CardAnswersProps {
  text: string;
  noSpacing?: boolean;
  className?: string;
}

export const CardAnswers: React.FC<CardAnswersProps> = ({
  text,
  noSpacing,
  children,
  className,
}) => {
  const styles = noSpacing ? '' : 'mb-6';
  return (
    <div className={`${styles} ${className ? className : ''}`}>
      <div className="mb-2 text-lg font-medium">
        <CardLabel text={text} />
      </div>
      <div className="text-sm">{children}</div>
    </div>
  );
};
