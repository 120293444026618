import React from 'react';

interface CardCheckmarkProps {
  check?: boolean;
  className?: string;
  text?: string;
  emphasize?: boolean;
  similar?: boolean;
  color: string;
}

export const CardCheckmark: React.FC<CardCheckmarkProps> = ({
  check,
  text,
  className,
  emphasize,
  color,
  similar,
}) => {
  const styles = check ? '' : 'opacity-25';
  return (
    <div
      className={`inline-flex items-center mb-2 ${
        className ? className : ''
      }`}
    >
      <div className="w-8">
        {similar && (
          <div
            style={{
              backgroundColor: color,
              borderColor: color,
            }}
            className={`w-3 h-3 rounded-full border`}
          />
        )}
      </div>
      <div className={`pl-4 w-16`}>
        {check && (
          <div
            style={{
              backgroundColor: emphasize ? color : 'transparent',
              borderColor: color,
            }}
            className={`w-3 h-3 rounded-full border`}
          />
        )}
      </div>
      <span className={`text-sm  ${styles ? styles : ''}`}>
        {text}
      </span>
    </div>
  );
};
