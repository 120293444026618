import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { removeSimilarPatient } from 'services';

interface TestingRemoveSimilarPatientProps {}

export const TestingRemoveSimilarPatient: React.FC<TestingRemoveSimilarPatientProps> =
  () => {
    const router = useHistory();
    const user = localStorage.getItem('user');

    useEffect(() => {
      const save = async () => {
        try {
          await removeSimilarPatient([user!]);
          router.push('/app');
        } catch (error) {
          console.log('TestingRemoveSimilarPatient', error);
        }
      };
      save();
    });

    return <div>Removing Similar Patient...Please wait</div>;
  };
