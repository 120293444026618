import React from 'react';

interface ContactLinkProps {}

export const ContactLink: React.FC<ContactLinkProps> = () => {
  return (
    <div className="fixed right-0 bottom-0 mr-4 bg-white border hover:bg-gray-100 z-10">
      <a
        href="https://www.ntnu.no/supportprim/fysioterapeut"
        target="_blank"
        rel="noopener noreferrer"
        className="cursor-pointer py-2 px-4 leading-none inline-block"
        title="Informasjon til fysioterapeuter som deltar i SupportPrim"
      >
        Kontakt oss
      </a>

      {process.env.NODE_ENV === 'development' && (
        <div
          className="fixed right-0 top-0 mr-4 bg-white border hover:bg-gray-100 z-10 py-2 px-4 leading-none inline-block cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText(
              localStorage.getItem('user') || '',
            );
          }}
        >
          {localStorage.getItem('user')}
        </div>
      )}
    </div>
  );
};
