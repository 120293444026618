import React from 'react';

export enum CardLabelType {
  xs = 'xs',
  sm = 'sm',
  lg = 'lg',
  xl = 'xl',
}

interface CardLabelProps {
  text: string;
  size?: CardLabelType;
  className?: string;
}

export const CardLabel: React.FC<CardLabelProps> = ({
  text,
  size,
  className,
}) => {
  return (
    <div
      className={`${size ? sizeClassName[size] : ''} ${
        className ? className : ''
      }`}
    >
      {Array.isArray(text)
        ? text.map((t: string) => <div>{t}</div>)
        : text}
    </div>
  );
};

const sizeClassName = {
  xs: 'text-xs',
  sm: 'text-sm',
  lg: 'font-medium text-lg',
  xl: 'font-medium text-2xl',
};
