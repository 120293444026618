import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { PatientDetails } from 'types';
import { BoxField } from 'components/BoxField';
import { BASELINE_MAIN_PROBLEM } from 'data';
import { hasBaselinePermission } from 'utils';

interface DiagnoseProps {
  values: PatientDetails;
  initialValues: PatientDetails;
}

export const Diagnose: React.FC<DiagnoseProps> = ({
  values,
  initialValues,
}) => {
  const bodyKeys = Object.entries(BASELINE_MAIN_PROBLEM.body_main_1);
  return (
    <Card>
      <CardFormText text="Diagnose">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Definer hovedproblem (dette utløser spesifikke tester for gitt område)"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      {bodyKeys.map(([key, value]) => {
        return (
          <BoxField
            type="radio"
            name="body_main_1"
            key={key}
            value={value.en.toLowerCase()}
            checked={value.en.toLowerCase() === values.body_main_1}
            text={value.no}
            disabled={hasBaselinePermission(
              initialValues.body_main_1,
            )}
          />
        );
      })}
    </Card>
  );
};
