import React from 'react';

interface CircleProgressBarProps {
  value?: number | undefined;
  max?: number | undefined;
  indicators?: number[] | undefined;
  animation?: boolean;
}

export const CircleProgressBar: React.FC<CircleProgressBarProps> = ({
  value = 0,
  max = 100,
  indicators,
  animation,
}) => {
  const RADIUS = 46;
  const FROM = 2 * Math.PI * RADIUS;
  const TO = FROM - (value / max) * FROM;
  const STROKE_WIDTH = 8;

  let indicatorColor = 'text-green-500';

  if (indicators) {
    if (indicators[0] <= value && indicators[1] >= value) {
      indicatorColor = 'text-yellow-500';
    } else if (indicators[1] < value) {
      indicatorColor = 'text-red-500';
    }
  }

  return (
    <div className="inline-block w-20 h-20">
      <div className="relative flex items-center justify-center w-20 h-20">
        <svg
          aria-label={`${FROM}% progress`}
          viewBox="0 0 100 100"
          className="absolute top-0 left-0"
        >
          <circle
            cx="50"
            cy="50"
            r={RADIUS}
            stroke="currentColor"
            strokeWidth={STROKE_WIDTH}
            fill="none"
            opacity=".2"
          ></circle>
          <circle
            cx="50"
            cy="50"
            r={RADIUS}
            stroke="currentColor"
            strokeWidth={STROKE_WIDTH}
            strokeLinecap="round"
            fill="none"
            opacity="1"
            strokeDashoffset={animation ? FROM : TO}
            strokeDasharray={FROM}
            transform="rotate(-90 50 50)"
            className={`stroke-current ${indicatorColor}`}
          >
            {animation && (
              <animate
                attributeType="CSS"
                attributeName="stroke-dashoffset"
                from={FROM}
                to={TO}
                dur="600ms"
                begin="0"
                fill="freeze"
                className="duration-1000"
              ></animate>
            )}
          </circle>
        </svg>
        <span className="font-medium leading-none">{value}</span>
      </div>
    </div>
  );
};
