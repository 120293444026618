import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as Component } from 'assets/svg/logo.svg';

interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
  return (
    <div className="flex flex-col text-center py-6">
      <NavLink to="/app">
        <Component className="mx-auto mb-2 w-32" />
      </NavLink>
    </div>
  );
};
