import React from 'react';

import { Radar } from 'components/Charts';

import {
  IBaselinePatient,
  RADAR_LABELS,
  RADAR_VALUE_NORMALIZE,
} from 'data';

interface ComparisonRadarProps {
  patients: IBaselinePatient[];
  legendIndex: boolean[];
  handleLegendClick: (i: number) => void;
}

export const ComparisonRadar: React.FC<ComparisonRadarProps> = ({
  patients,
  legendIndex,
  handleLegendClick,
}) => {
  return (
    <div>
      <div className="flex space-x-8 justify-center mb-8">
        {patients.map((p, i) => {
          return (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => handleLegendClick(i)}
              key={`${p}-${i}`}
            >
              <span
                className="inline-block w-3 h-3 rounded-full mr-2"
                style={{
                  backgroundColor: p.pointBackgroundColor,
                }}
              />
              <span
                className={`${!legendIndex[i] ? 'line-through' : ''}`}
              >
                {p.title}
              </span>
            </div>
          );
        })}
      </div>
      <div>
        <Radar
          height={600}
          data={{
            labels: RADAR_LABELS.map((r) => r.no),
            datasets: patients.map((patient, index) => ({
              ...patient,
              data: Object.entries(patient.radar).map(
                ([key, value]) => {
                  if (key === 'Mentral distress') {
                    return (value - 1) * 33.3;
                  }

                  // Some case values are returned as string
                  const normalizedValue =
                    RADAR_VALUE_NORMALIZE[key][value];

                  if (normalizedValue !== undefined) {
                    return normalizedValue;
                  }

                  return value;
                },
              ),
              label: patient.title,
              pointRadius: 4,
              borderDash: index === 0 ? [4, 2] : [],
              hidden: !legendIndex[index],
            })),
          }}
          options={{
            scale: {
              pointLabels: {
                fontSize: 16,
                fontColor: '#000',
                lineHeight: 1,
              },
              ticks: {
                suggestedMin: -5,
                suggestedMax: 100,
                stepSize: 10,
                fontSize: 14,
              },
              gridLines: {
                color: 'rgba(0, 0, 0, 0.15)',
              },
            },
            legend: {
              display: false,
              position: 'top',
              labels: {
                boxWidth: 8,
                usePointStyle: true,
              },
            },
            tooltips: {
              enabled: false,
            },
            maintainAspectRatio: false,
            title: {
              display: false,
              text: 'Radar',
            },
          }}
        />
      </div>
      <div className="text-center max-w-xl mx-auto mt-8 text-gray-800 text-sm">
        Figuren viser pasientens score på 10 ulike faktorer. En skår
        som ligger inn mot midten av figuren betyr en god skår og
        dårligere skår desto lengre ut.
      </div>
    </div>
  );
};
