import React from 'react';

interface FormButtonProps {
  title: string;
  className?: string;
  loading: boolean;
}

export const FormButton: React.FC<FormButtonProps> = ({
  title,
  className,
  loading,
}) => {
  return (
    <button
      className={`inline-flex  items-center justify-center py-2 border border-transparent text-base leading-6 rounded-md focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 bg-indigo-600 hover:bg-indigo-500 text-white px-4 font-medium h-12 text-center ${
        className ? className : ''
      }`}
      type="submit"
    >
      {loading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {title}
    </button>
  );
};
