import React from 'react';

import { parseProgress } from 'utils';

interface VerticalBarProps {
  progress?: number | undefined;
  max?: number | undefined;
  indicators?: number[] | undefined;
}

export const VerticalBar: React.FC<VerticalBarProps> = ({
  progress = 0,
  max = 100,
  indicators,
}) => {
  const parsedProgress = parseProgress((progress / max) * 100);
  let indicatorColor = 'bg-red-500';

  if (indicators) {
    if (indicators[0] < progress) {
      indicatorColor = 'bg-yellow-500';
    }

    if (indicators[1] <= progress) {
      indicatorColor = 'bg-green-500';
    }
  }

  return (
    <div className="relative w-3 h-full border border-gray-500 rounded-full overflow-x-hidden bg-white">
      <div
        className={`indicator-vertical absolute bottom-0 w-3 ${indicatorColor}`}
        style={{ height: `${parsedProgress}%` }}
      ></div>
    </div>
  );
};
