import React from 'react';
import {
  Card,
  CardLabel,
  CardLabelType,
  CardProgressBar,
} from 'components/Card';
import { GROUP } from 'data';
import { replaceScore, variableChecker } from 'utils';
import { HoverContent } from 'components/HoverContent';
import { PatientResponseInfo } from 'types';
import { CopyText } from 'components/CopyText';

interface BeliefAndThoughtProps {
  className?: String;
  cardClassName?: String;
  data: PatientResponseInfo;
}

export const BeliefAndThought: React.FC<BeliefAndThoughtProps> = ({
  className,
  cardClassName,
  data,
}) => {
  const { patientDetails } = data;
  const { oreb_q10_1, oreb_q7_1, pseq_score_1 } = patientDetails;

  return (
    <div className={`${className ? className : ''}`}>
      <Card className={`${cardClassName ? cardClassName : ''}`}>
        <CardLabel
          className="mb-8"
          text={GROUP.thoughts.title.no}
          size={CardLabelType.lg}
        />
        <HoverContent
          content={replaceScore(
            GROUP.thoughts.oreb_q10_1.hover.no,
            oreb_q10_1,
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.thoughts.oreb_q10_1.hover.no,
              oreb_q10_1,
            )}
          >
            <CardProgressBar
              text={GROUP.thoughts.oreb_q10_1.label.no}
              progress={oreb_q10_1}
              parseProgressValue={(value) => (100 / 10) * value}
              min={0}
              max={10}
              indicators={[2, 5]}
              disabled={variableChecker(oreb_q10_1)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            GROUP.thoughts.pseq_score_1.hover.no,
            12 - (pseq_score_1 || 0),
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.thoughts.pseq_score_1.hover.no,
              12 - (pseq_score_1 || 0),
            )}
          >
            <CardProgressBar
              text={GROUP.thoughts.pseq_score_1.label.no}
              progress={12 - (pseq_score_1 || 0)}
              parseProgressValue={(value) => (100 / 12) * value}
              min={0}
              max={12}
              indicators={[5, 7]}
              disabled={variableChecker(pseq_score_1)}
            />
          </CopyText>
        </HoverContent>

        <HoverContent
          content={replaceScore(
            GROUP.thoughts.Oreb_q7_1.hover.no,
            oreb_q7_1,
          )}
        >
          <CopyText
            content={replaceScore(
              GROUP.thoughts.Oreb_q7_1.hover.no,
              oreb_q7_1,
            )}
          >
            <CardProgressBar
              text={GROUP.thoughts.Oreb_q7_1.label.no}
              progress={oreb_q7_1}
              parseProgressValue={(value) => (100 / 10) * value}
              min={0}
              max={10}
              indicators={[2, 4]}
              noSpacing={true}
              disabled={variableChecker(oreb_q7_1)}
            />
          </CopyText>
        </HoverContent>
      </Card>
    </div>
  );
};
