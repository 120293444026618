// Update patients similar patients

import { CASE_STRUCTURE } from 'data';
import { SimilarPatients } from 'types';
import { convertToCase, sortByKey } from 'utils';

export const savePatientBaseline = async (patients: string[]) => {
  try {
    const token = localStorage.getItem('token') || '';

    await Promise.all(
      patients.map((v) => {
        return fetch(
          `${
            process.env.REACT_APP_URL_CBR
          }/patient/getPatientById?${new URLSearchParams({
            patientId: v,
          })}`,
          {
            headers: {
              Authorization: token,
            },
          },
        )
          .then(async (response) => {
            const data = await response.json();

            // Set params
            const query = new URLSearchParams({
              casebase: 'cb_all',
              concept: 'patient',
              k: '-1', // desired number of similar cases
              // similarityFunction: 'ism_knowledge_based',
              similarityFunction: 'ism_knowledge_based',
            });

            // keys used for similarity matching.
            const caseKeys = Object.keys(
              CASE_STRUCTURE.activeAttributesWithWeights,
            );

            // Get patient
            // ! Need to use Object.assign to avoid removing empty value
            const body = JSON.stringify(
              convertToCase(
                Object.assign({}, data.patientDetails),
                caseKeys,
              ),
            );

            // Get similar patients data
            const { similarCasesWithContent } = await fetch(
              `${process.env.REACT_APP_URL_CBR}/cbr/retrieveSimilarCasesWithContent?${query}`,
              {
                method: 'POST',
                body,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: token,
                },
              },
            ).then((res) => res.json());

            // Decide which patients should be shown
            const similarPatients = similarCasesWithContent
              .filter((s: any) => s.outcome_01 === '1') // retrieve successful cases
              .slice(0, 3)
              .sort(
                (
                  a: { patient_id: string; similarity: number },
                  b: { patient_id: string; similarity: number },
                ) => (a.similarity < b.similarity ? 1 : -1),
              )
              .map((p: SimilarPatients) => ({
                [p.patient_id]: p.similarity,
              }));

            // Save new info to patient
            const saveRes = await fetch(
              `${process.env.REACT_APP_URL_CBR}/patient/savePatient`,
              {
                method: 'POST',
                body: JSON.stringify({
                  ...sortByKey({ ...data }),
                  similarPatients: {
                    ...Object.assign({}, ...similarPatients),
                  },
                  patientDetails: sortByKey({
                    ...data.patientDetails,
                  }),
                }),
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: token,
                },
              },
            );

            return await saveRes.json();
          })
          .catch((err) => console.log(err));
      }),
    );
  } catch (error) {
    console.log('savePatientBaseline error: ', error);
  }
};
