import {
  PATIENT_TEMPLATE,
  IBaselinePatient,
  RED_FLAGS,
  SLEEP,
  FUNCTION_AND_ACTIVITY_SCORE_TEXT,
  TREATMENT,
  TREATMENT_END,
  MSKT_SCORE_TEXT,
  GPE,
  COMPARISON_PRESCRIPE_DESCRIPTION,
  GENDER,
  PAIN_SCORE_TEXT,
  LCA_SCORE,
} from 'data';
import { PatientResponseInfo } from 'types';
import { getMappedValue } from 'utils';
import {
  convertSingleVariableArray,
  convertVariableArray,
} from './helpers';

export const getRedFlags = (arr: string[]) => {
  return RED_FLAGS.filter(
    (flag, i) =>
      // arr.some((f) => f === flag.value),
      arr[i] === 'Ja',
  ).map((v) => v.label);
};

export const patientMapping = (
  data: PatientResponseInfo,
  similarCases: PatientResponseInfo[],
) => {
  const date = new Date();
  const { patientDetails } = data;
  const patients: IBaselinePatient[] = PATIENT_TEMPLATE;

  return patients.map((p, i: number) => {
    if (i <= 0) {
      // * Current patient
      return {
        ...p,
        birthYear: patientDetails.birth_year,
        similarPatients: {},
        sex: patientDetails.gender_1,
        smoking:
          patientDetails.smoking_1.toLowerCase() === 'yes'
            ? 'Ja'
            : 'Nei',
        bmi: patientDetails.bmi_1,
        redFlags: getRedFlags([
          patientDetails.red_flag_cancer_1,
          patientDetails.red_flag_nightpain_1,
          patientDetails.red_flag_urination_1,
          patientDetails.red_flag_weight_1,
        ]),
        mainProblem: patientDetails.body_main_1,
        orebro: patientDetails.oreb_score_1,
        mskt: patientDetails.mskt_score_1,
        mskhq: patientDetails.mskhq_score_1,
        phenotype: patientDetails.phenotype_lca_1,
        sympton: `${patientDetails.icpc_letter_1 || ''}${
          patientDetails.icpc_nr_1 || ''
        }${
          patientDetails.diagnose_1
            ? ` - ${patientDetails.diagnose_1}`
            : ''
        }`,
        work: {
          education: patientDetails.education_1,
          employment: patientDetails.employ_1,
          work_ability_1: patientDetails.work_ability_1,
          Work_type_1: patientDetails.work_type_1,
        },
        warningValues: {
          neck_nevro_pow_1: patientDetails.neck_nevro_pow_1,
          neck_nevro_sens_1: patientDetails.neck_nevro_sens_1,
          neck_nevro_reflex_1: patientDetails.neck_nevro_reflex_1,
          neck_spurling_1: patientDetails.neck_spurling_1,
          neck_nevro_other_1: patientDetails.neck_nevro_other_1,
          neck_conclusion_1: patientDetails.neck_conclusion_1,
          shoulder_passive_extrot_1:
            patientDetails.shoulder_passive_extrot_1 === 'Yes'
              ? 1
              : 0,
          back_slr_1: patientDetails.back_slr_1,
          back_nevro_pow_1: patientDetails.back_nevro_pow_1,
          back_nevro_sens_1: patientDetails.back_nevro_sens_1,
          back_nevro_reflex_1: patientDetails.back_nevro_reflex_1,
          knee_hydrops_1: patientDetails.knee_hydrops_1,
          knee_trumatic_1: patientDetails.knee_trumatic_1,
        },
        radar: {
          'Pain intensity': patientDetails.oreb_q2_1,
          'Pain duration':
            PAIN_SCORE_TEXT.oreb_q1_1[patientDetails.oreb_q1_1].value,
          'Pain sites': patientDetails.painsite_number_1,
          // 'Continuous pain': 100,
          'Recovery expectations': patientDetails.oreb_q7_1,
          'Pain self-efficacy': patientDetails.pseq_score_1,
          'Fear avoidance': patientDetails.oreb_q10_1,
          'Mentral distress': patientDetails.hscl_score_1,
          'Sleep problems': getMappedValue(
            SLEEP.qol15d_q5_sleep_1,
            patientDetails.qol15d_q5_sleep_1,
          ).value,
          'Work ability': patientDetails.work_ability_1,
          'Daily activity level': getMappedValue(
            FUNCTION_AND_ACTIVITY_SCORE_TEXT.activity_1,
            patientDetails.activity_1,
          ).value,
        },
        graphs: {
          Funksjon: [
            patientDetails.psfs1_1,
            patientDetails.psfs1_w2,
            patientDetails.psfs1_w4,
            patientDetails.psfs1_w8,
            patientDetails.psfs1_p_3 || patientDetails.psfs1_3,
          ].map((v) => (Number(v) === 0 ? 0 : v || null)),
          Arbeidsevne: [
            patientDetails.work_ability_1,
            patientDetails.work_ability_w2,
            patientDetails.work_ability_w4,
            patientDetails.work_ability_w8,
            patientDetails.work_ability_3,
          ].map((v) => (Number(v) === 0 ? 0 : v || null)),
          Smerte: [
            patientDetails.oreb_q2_1,
            patientDetails.oreb_q2_w2,
            patientDetails.oreb_q2_w4,
            patientDetails.oreb_q2_w8,
            patientDetails.oreb_q2_3,
          ].map((v) => (Number(v) === 0 ? 0 : v || null)),
          Mestring: [
            patientDetails.pseq_score_1,
            Number(patientDetails.pseq_q1_w2) +
              Number(patientDetails.pseq_q2_w2),
            patientDetails.pseq_q1_w4 + patientDetails.pseq_q2_w4,
            patientDetails.pseq_q1_w8 + patientDetails.pseq_q2_w8,
            patientDetails.pseq_score_3,
          ].map((v) => (Number(v) === 0 ? 0 : v || null)),
          'Generell bedring': [
            null,
            getMappedValue(GPE.gpe_3, patientDetails.gpe_w2).value,
            getMappedValue(GPE.gpe_3, patientDetails.gpe_w4).value,
            getMappedValue(GPE.gpe_3, patientDetails.gpe_w8).value,
            getMappedValue(
              GPE.gpe_3,
              convertSingleVariableArray(patientDetails.gpe_3),
            ).value,
          ].map((v) => v || null),
        },
        prescription: {
          'Råd og veiledning': convertVariableArray([
            patientDetails.treat_advice_rest_3,
            patientDetails.treat_advice_pa_3,
            patientDetails.treat_advice_fear_3,
            patientDetails.treat_advice_selfman_3,
            patientDetails.treat_advice_work_3,
            patientDetails.treat_advice_sleep_3,
            patientDetails.treat_advice_weight_3,
            patientDetails.treat_advice_social_3,
            patientDetails.treat_advice_distress_3,
            patientDetails.treat_advice_expect_3,
          ]),
          'Aktive øvelser og tiltak': convertVariableArray([
            patientDetails.treat_active_strength_3,
            patientDetails.treat_active_cardio_3,
            patientDetails.treat_active_balance_3,
            patientDetails.treat_active_stretch_3,
            patientDetails.treat_active_posture_3,
            patientDetails.treat_active_general_3,
          ]),
          Hjemmeøvelser: [patientDetails.treat_home_3],
          'Passive tiltak': [
            patientDetails.treat_passive_massage_3,
            patientDetails.treat_passive_joint_3,
            patientDetails.treat_passive_stretch_3,
            patientDetails.treat_passive_trigger_3,
            patientDetails.treat_passive_shockwave_3,
          ],
          'Pasienten er drøftet med': [
            patientDetails.treat_discuss_gp_3,
            patientDetails.treat_discuss_specialist_3,
            patientDetails.treat_discuss_psycho_3,
            patientDetails.treat_discuss_physio_3,
            patientDetails.treat_discuss_other_3,
          ],
          'Kontakt med andre under behandlingen': [
            patientDetails.treat_contact_nav_3,
            patientDetails.treat_contact_occup_3,
            patientDetails.treat_contact_employ_3,
            patientDetails.treat_contact_family_3,
          ],
        },
        info: {
          practioner: patientDetails.treat_physio_3,
          type: [
            patientDetails.treat_setting_self_3,
            patientDetails.treat_setting_group_3,
            patientDetails.treat_setting_individual_3,
          ],
          continuationOfTreament: getMappedValue(
            TREATMENT.treat_contin_3,
            `${patientDetails.treat_contin_3}`,
          ).no,
          endOfTreatment: [
            'end_patient_3',
            'end_effect_3',
            'end_noeffect_3',
            'end_followup_3',
            'end_move_3',
            'end_medic_3',
            'end_death_3',
            'end_other_3',
          ]
            .map((k: string) => {
              const end = getMappedValue(
                TREATMENT_END[k],
                patientDetails[k],
              );

              return end.value ? end.no : '';
            })
            .filter((v) => v),
          consultation: patientDetails.consultation_3,
          exerciseProgress: patientDetails.compliance_3,
          // exerciseProgress: getMappedValue(
          //   COMPLIANCE.compliance_3,
          //   patientDetails.compliance_3,
          // ).no,
        },
        prescriptionDescription: [
          patientDetails.treatmentfactor_patient_3,
          patientDetails.treat_describe_important_3,
          patientDetails.treat_describe_3,
          getMappedValue(LCA_SCORE, patientDetails.phenotype_lca_1)
            .no || '',
          getMappedValue(
            MSKT_SCORE_TEXT.mskt_risk_1_long,
            patientDetails.mskt_score_1,
          ).no || '',
        ],
        ressources: [
          {
            variable: 'mskt_risk_1',
            value: patientDetails.mskt_risk_1,
          },
          {
            variable: 'health_lit_info_1',
            value: patientDetails.health_lit_info_1,
          },
          {
            variable: 'oreb_score_1',
            value: patientDetails.oreb_score_1,
          },
          {
            variable: 'activity_1',
            value: patientDetails.activity_1,
          },
          {
            variable: 'mskhq_pa_1',
            value: patientDetails.mskhq_pa_1,
          },
          {
            variable: 'mskhq_q7_1',
            value: patientDetails.mskhq_q7_1,
          },
          {
            variable: 'qol15d_q5_sleep_1',
            value: patientDetails.qol15d_q5_sleep_1,
          },
          {
            variable: 'qol15d_q14_vital_1',
            value: patientDetails.qol15d_q14_vital_1,
          },
          {
            variable: 'eq5d_depr_1',
            value: patientDetails.eq5d_depr_1,
          },
          {
            variable: 'hscl_score_1',
            value: patientDetails.hscl_score_1,
          },
          {
            variable: 'oreb_q10_1',
            value: patientDetails.oreb_q10_1,
          },
          {
            variable: 'pseq_score_1',
            value: patientDetails.pseq_score_1,
          },
          {
            variable: 'oreb_q7_1',
            value: patientDetails.oreb_q7_1,
          },
          {
            variable: 'work_ability_1',
            value: patientDetails.work_ability_1,
          },
          {
            variable: 'employ_1',
            value: patientDetails.employ_1,
          },
          {
            variable: 'oreb_q2_1',
            value: patientDetails.oreb_q2_1,
          },
          {
            variable: 'oreb_q1_1',
            value: patientDetails.oreb_q1_1,
          },
          {
            variable: 'painsite_number_1',
            value: patientDetails.painsite_number_1,
          },
          {
            variable: 'smoking_1',
            value: patientDetails.smoking_1,
          },
          {
            variable: 'bmi_1',
            value: patientDetails.bmi_1,
          },
        ],
      };
    }

    // Similar patients
    const c = similarCases[i - 1] || [];

    return {
      ...p,
      id: c.patientId,
      title: `${p.title}`,
      similarPatients: data.similarPatients,
      birthYear: date.getFullYear() - c.patientDetails.age_1,
      sex: c.patientDetails.gender_1,
      smoking:
        c.patientDetails.smoking_1.toLowerCase() === 'yes'
          ? 'Ja'
          : 'Nei',
      bmi: c.patientDetails.bmi_1,
      orebro: c.patientDetails.oreb_score_1,
      mskt: c.patientDetails.mskt_score_1,
      mskhq: c.patientDetails.mskhq_score_1,
      phenotype: c.patientDetails.phenotype_lca_1,
      mainProblem: c.patientDetails.body_main_1,
      sympton: `${c.patientDetails.icpc_letter_1 || ''}${
        c.patientDetails.icpc_nr_1 || ''
      }${
        c.patientDetails.diagnose_1
          ? ` - ${c.patientDetails.diagnose_1}`
          : ''
      }`,
      work: {
        education: c.patientDetails.education_1,
        employment: c.patientDetails.employ_1,
        work_ability_1: c.patientDetails.work_ability_1,
        Work_type_1: c.patientDetails.work_type_1,
      },
      warningValues: {
        neck_nevro_pow_1: c.patientDetails.neck_nevro_pow_1,
        neck_nevro_sens_1: c.patientDetails.neck_nevro_sens_1,
        neck_nevro_reflex_1: c.patientDetails.neck_nevro_reflex_1,
        neck_spurling_1: c.patientDetails.neck_spurling_1,
        neck_nevro_other_1: c.patientDetails.neck_nevro_other_1,
        neck_conclusion_1: c.patientDetails.neck_conclusion_1,
        shoulder_passive_extrot_1:
          c.patientDetails.shoulder_passive_extrot_1 === 'Yes'
            ? 1
            : 0,
        back_slr_1: c.patientDetails.back_slr_1,
        back_nevro_pow_1: c.patientDetails.back_nevro_pow_1,
        back_nevro_sens_1: c.patientDetails.back_nevro_sens_1,
        back_nevro_reflex_1: c.patientDetails.back_nevro_reflex_1,
        knee_hydrops_1: c.patientDetails.knee_hydrops_1,
        knee_trumatic_1: c.patientDetails.knee_trumatic_1,
      },
      radar: {
        'Pain intensity': c.patientDetails.oreb_q2_1,
        // 'Continuous pain': 100,
        'Pain duration':
          PAIN_SCORE_TEXT.oreb_q1_1[c.patientDetails.oreb_q1_1].value,
        'Pain sites': c.patientDetails.painsite_number_1,
        'Recovery expectations': c.patientDetails.oreb_q7_1,
        'Pain self-efficacy': c.patientDetails.pseq_score_1,
        'Fear avoidance': c.patientDetails.oreb_q10_1,
        'Mentral distress': c.patientDetails.hscl_score_1,
        'Sleep problems': getMappedValue(
          SLEEP.qol15d_q5_sleep_1,
          c.patientDetails.qol15d_q5_sleep_1,
        ).value,
        'Work ability': c.patientDetails.work_ability_1,
        'Daily activity level': getMappedValue(
          FUNCTION_AND_ACTIVITY_SCORE_TEXT.activity_1,
          c.patientDetails.activity_1,
        ).value,
      },
      graphs: {
        Funksjon: [
          c.patientDetails.psfs1_1,
          c.patientDetails.psfs1_w2,
          c.patientDetails.psfs1_w4,
          c.patientDetails.psfs1_w8,
          c.patientDetails.psfs1_p_3 || c.patientDetails.psfs1_3,
        ].map((v) => (Number(v) === 0 ? 0 : v || null)),
        Arbeidsevne: [
          c.patientDetails.work_ability_1,
          c.patientDetails.work_ability_w2,
          c.patientDetails.work_ability_w4,
          c.patientDetails.work_ability_w8,
          c.patientDetails.work_ability_3,
        ].map((v) => (Number(v) === 0 ? 0 : v || null)),
        Smerte: [
          c.patientDetails.oreb_q2_1,
          c.patientDetails.oreb_q2_w2,
          c.patientDetails.oreb_q2_w4,
          c.patientDetails.oreb_q2_w8,
          c.patientDetails.oreb_q2_3,
        ].map((v) => (Number(v) === 0 ? 0 : v || null)),
        Mestring: [
          c.patientDetails.pseq_score_1,
          Number(c.patientDetails.pseq_q1_w2) +
            Number(c.patientDetails.pseq_q2_w2),
          c.patientDetails.pseq_q1_w4 + c.patientDetails.pseq_q2_w4,
          c.patientDetails.pseq_q1_w8 + c.patientDetails.pseq_q2_w8,
          c.patientDetails.pseq_score_3,
        ].map((v) => (Number(v) === 0 ? 0 : v || null)),
        'Generell bedring': [
          null,
          getMappedValue(GPE.gpe_3, c.patientDetails.gpe_w2).value,
          getMappedValue(GPE.gpe_3, c.patientDetails.gpe_w4).value,
          getMappedValue(GPE.gpe_3, c.patientDetails.gpe_w8).value,
          getMappedValue(
            GPE.gpe_3,
            convertSingleVariableArray(c.patientDetails.gpe_3),
          ).value,
          getMappedValue(
            GPE.gpe_3,
            convertSingleVariableArray(c.patientDetails.gpe_3),
          ).value,
        ].map((v) => v || null),
      },
      prescription: {
        'Råd og veiledning': convertVariableArray([
          c.patientDetails.treat_advice_rest_3,
          c.patientDetails.treat_advice_pa_3,
          c.patientDetails.treat_advice_fear_3,
          c.patientDetails.treat_advice_selfman_3,
          c.patientDetails.treat_advice_work_3,
          c.patientDetails.treat_advice_sleep_3,
          c.patientDetails.treat_advice_weight_3,
          c.patientDetails.treat_advice_social_3,
          c.patientDetails.treat_advice_distress_3,
          c.patientDetails.treat_advice_expect_3,
        ]),
        'Aktive øvelser og tiltak': convertVariableArray([
          c.patientDetails.treat_active_strength_3,
          c.patientDetails.treat_active_cardio_3,
          c.patientDetails.treat_active_balance_3,
          c.patientDetails.treat_active_stretch_3,
          c.patientDetails.treat_active_posture_3,
          c.patientDetails.treat_active_general_3,
        ]),
        Hjemmeøvelser: [c.patientDetails.treat_home_3],
        'Passive tiltak': [
          c.patientDetails.treat_passive_massage_3,
          c.patientDetails.treat_passive_joint_3,
          c.patientDetails.treat_passive_stretch_3,
          c.patientDetails.treat_passive_trigger_3,
          c.patientDetails.treat_passive_shockwave_3,
        ],
        'Pasienten er drøftet med': [
          c.patientDetails.treat_discuss_gp_3,
          c.patientDetails.treat_discuss_specialist_3,
          c.patientDetails.treat_discuss_psycho_3,
          c.patientDetails.treat_discuss_physio_3,
          c.patientDetails.treat_discuss_other_3,
        ],
        'Kontakt med andre under behandlingen': [
          c.patientDetails.treat_contact_nav_3,
          c.patientDetails.treat_contact_occup_3,
          c.patientDetails.treat_contact_employ_3,
          c.patientDetails.treat_contact_family_3,
        ],
      },
      info: {
        sexAge: `${
          getMappedValue(GENDER.gender_1, c.patientDetails.gender_1)
            .no
        } - ${date.getFullYear() - c.patientDetails.birth_year} år`,
        sympton: `${c.patientDetails.icpc_letter_1 || ''}${
          c.patientDetails.icpc_nr_1 || ''
        }${
          c.patientDetails.diagnose_1
            ? ` - ${c.patientDetails.diagnose_1}`
            : ''
        }`,
        consultation: c.patientDetails.consultation_3,
        practioner: c.patientDetails.treat_physio_3,
        type: [
          ['noe', 'mye'].includes(
            (
              c.patientDetails.treat_setting_self_3 || ''
            ).toLowerCase(),
          )
            ? 'Individuell behandling'
            : '',
          ['noe', 'mye'].includes(
            (
              c.patientDetails.treat_setting_group_3 || ''
            ).toLowerCase(),
          )
            ? 'Behandling i gruppe'
            : '',
          ['noe', 'mye'].includes(
            (
              c.patientDetails.treat_setting_individual_3 || ''
            ).toLowerCase(),
          )
            ? 'Egentrening'
            : '',
        ],
        continuationOfTreament: getMappedValue(
          TREATMENT.treat_contin_3,
          `${c.patientDetails.treat_contin_3}`,
        ).no,
        endOfTreatment: [
          'end_patient_3',
          'end_effect_3',
          'end_noeffect_3',
          'end_followup_3',
          'end_move_3',
          'end_medic_3',
          'end_death_3',
          'end_other_3',
        ]
          .map((k: string) => {
            const end = getMappedValue(
              TREATMENT_END[k],
              c.patientDetails[k],
            );

            return end.value ? end.no : '';
          })
          .filter((v) => v),
        exerciseProgress: c.patientDetails.compliance_3,
        c,
        // exerciseProgress: getMappedValue(
        //   COMPLIANCE.compliance_3,
        //   c.patientDetails.compliance_3,
        // ).no,
      },
      prescriptionDescription: [
        c.patientDetails.treatmentfactor_patient_3,
        c.patientDetails.treat_describe_important_3,
        c.patientDetails.treat_describe_3,
        getMappedValue(LCA_SCORE, c.patientDetails.phenotype_lca_1)
          .no || '',
        getMappedValue(
          MSKT_SCORE_TEXT.mskt_risk_1_long,
          c.patientDetails.mskt_score_1,
        ).no || '',
      ],
      ressources: [
        {
          variable: 'mskt_risk_1',
          value: patientDetails.mskt_risk_1,
        },
        {
          variable: 'health_lit_info_1',
          value: patientDetails.health_lit_info_1,
        },
        {
          variable: 'oreb_score_1',
          value: patientDetails.oreb_score_1,
        },
        {
          variable: 'activity_1',
          value: patientDetails.activity_1,
        },
        {
          variable: 'mskhq_pa_1',
          value: patientDetails.mskhq_pa_1,
        },
        {
          variable: 'mskhq_q7_1',
          value: patientDetails.mskhq_q7_1,
        },
        {
          variable: 'qol15d_q5_sleep_1',
          value: patientDetails.qol15d_q5_sleep_1,
        },
        {
          variable: 'qol15d_q14_vital_1',
          value: patientDetails.qol15d_q14_vital_1,
        },
        {
          variable: 'eq5d_depr_1',
          value: patientDetails.eq5d_depr_1,
        },
        {
          variable: 'hscl_score_1',
          value: patientDetails.hscl_score_1,
        },
        {
          variable: 'oreb_q10_1',
          value: patientDetails.oreb_q10_1,
        },
        {
          variable: 'pseq_score_1',
          value: patientDetails.pseq_score_1,
        },
        {
          variable: 'oreb_q7_1',
          value: patientDetails.oreb_q7_1,
        },
        {
          variable: 'work_ability_1',
          value: patientDetails.work_ability_1,
        },
        {
          variable: 'employ_1',
          value: patientDetails.employ_1,
        },
        {
          variable: 'oreb_q2_1',
          value: patientDetails.oreb_q2_1,
        },
        {
          variable: 'oreb_q1_1',
          value: patientDetails.oreb_q1_1,
        },
        {
          variable: 'painsite_number_1',
          value: patientDetails.painsite_number_1,
        },
        {
          variable: 'smoking_1',
          value: patientDetails.smoking_1,
        },
        {
          variable: 'bmi_1',
          value: patientDetails.bmi_1,
        },
      ],
    };
  });
};

export const patientPrescription = (patient: IBaselinePatient) => {
  return COMPARISON_PRESCRIPE_DESCRIPTION.map((c, index) => {
    return {
      title: c.replace(
        '"actual score"',
        ` - ${getMappedValue(
          MSKT_SCORE_TEXT.mskt_score_1,
          patient.mskt,
        ).no.toLocaleLowerCase()}`,
      ),
      value: patient.prescriptionDescription[index],
    };
  });
};
