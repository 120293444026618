import React from 'react';
import { Card, CardLabel, CardLabelType } from 'components/Card';
import { CardFormText } from 'components/Card/CardFormText';
import { PatientDetails } from 'types';
import { BoxFields } from 'components/BoxFields';
import { BASELINE_KNEE } from 'data';
import { getMappedValue } from 'utils';
import { FormInput } from 'components/Form';

interface KneeProps {
  values: PatientDetails;
}

export const Knee: React.FC<KneeProps> = ({ values }) => {
  const {
    knee_hydrops_1,
    knee_move_passive_1,
    knee_passive_flexlim_1,
    knee_passive_extlim_1,
    knee_passive_notlim_1,
    knee_passive_flexpain_1,
    knee_passive_extpain_1,
    knee_passive_notpain_1,
    knee_pivot_1,
    knee_lachman_1,
    knee_sag_1,
    knee_mcmurray_1,
    knee_palp_1,
    knee_valgus_ext_1,
    knee_valgus_flex_1,
    knee_varus_ext_1,
    knee_varus_flex_1,
    knee_move_1,
    knee_move_rom_flex_1,
    knee_move_rom_ext_1,
    knee_move_rom_not_1,
    knee_move_pain_ext_1,
    knee_move_pain_flex_1,
    knee_move_pain_not_1,
    knee_traumatic_1,
  } = values;
  // Conditional display fields

  const isKneeActive =
    getMappedValue(BASELINE_KNEE.knee_move_active_1, knee_move_1)
      .en === 'Yes';

  const isKneePassive =
    getMappedValue(
      BASELINE_KNEE.knee_move_passive_1,
      knee_move_passive_1,
    ).en === 'Yes';

  const isKneeTrumatic =
    getMappedValue(BASELINE_KNEE.knee_traumatic_1, knee_traumatic_1)
      .en === 'Yes';

  return (
    <Card>
      <CardFormText text="Kneundersøkelse">
        <div className="mt-2 mb-4">
          <CardLabel
            text="Reduserte og/eller smertefulle aktive bevegelsesutslag"
            size={CardLabelType.sm}
          />
        </div>
      </CardFormText>
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            knee_move_1,
          }}
          keys={BASELINE_KNEE.knee_move_active_1}
        />
      </div>
      {isKneeActive && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                knee_move_rom_flex_1,
                knee_move_rom_ext_1,
                knee_move_rom_not_1,
              }}
              keys={BASELINE_KNEE.limitedActive}
            />
          </div>
          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                knee_move_pain_flex_1,
                knee_move_pain_ext_1,
                knee_move_pain_not_1,
              }}
              keys={BASELINE_KNEE.painActive}
            />
          </div>
        </>
      )}
      <CardFormText text="Reduserte og/eller smertefulle passive bevegelsesutslag" />
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            knee_move_passive_1,
          }}
          keys={BASELINE_KNEE.knee_move_passive_1}
        />
      </div>
      {isKneePassive && (
        <>
          <CardFormText text="Reduserte bevegelsesutslag i" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                knee_passive_flexlim_1,
                knee_passive_extlim_1,
                knee_passive_notlim_1,
              }}
              keys={BASELINE_KNEE.limitedPassive}
            />
          </div>
          <CardFormText text="Smerter ved" />
          <div className="mb-8">
            <BoxFields
              values={values}
              square={true}
              variables={{
                knee_passive_flexpain_1,
                knee_passive_extpain_1,
                knee_passive_notpain_1,
              }}
              keys={BASELINE_KNEE.painPassive}
            />
          </div>
        </>
      )}

      <div>
        <CardFormText text="Er det hydrops i kneet" />
        <div className="mb-8">
          <BoxFields
            values={values}
            variables={{
              knee_hydrops_1,
            }}
            keys={BASELINE_KNEE['knee_hydrops_1']}
          />
        </div>
      </div>

      <CardFormText text="Har pasienten vært utsatt for et traume" />
      <div className="mb-8">
        <BoxFields
          values={values}
          variables={{
            knee_traumatic_1,
          }}
          keys={BASELINE_KNEE['knee_traumatic_1']}
        />
      </div>

      {isKneeTrumatic && (
        <>
          {[
            {
              title: 'Positiv Pivot Shift test',
              variable: knee_pivot_1,
              key: 'knee_pivot_1',
              keys: BASELINE_KNEE['knee_pivot_1'],
            },
            {
              title: 'Positiv Lachman test uten endepunkt',
              variable: knee_lachman_1,
              key: 'knee_lachman_1',
              keys: BASELINE_KNEE['knee_lachman_1'],
            },
            {
              title: 'Positiv Sag test',
              variable: knee_sag_1,
              key: 'knee_sag_1',
              keys: BASELINE_KNEE['knee_sag_1'],
            },
            {
              title: 'Gjenkjennbar smerte ved McMurrays test',
              variable: knee_mcmurray_1,
              key: 'knee_mcmurray_1',
              keys: BASELINE_KNEE['knee_mcmurray_1'],
            },
            {
              title:
                'Gjenkjennbar smerte ved palpasjon i leddspalten',
              variable: knee_palp_1,
              key: 'knee_palp_1',
              keys: BASELINE_KNEE['knee_palp_1'],
            },
            {
              title:
                'Forøket valgus ved stress test på ekstendert kne',
              variable: knee_valgus_ext_1,
              key: 'knee_valgus_ext_1',
              keys: BASELINE_KNEE['knee_valgus_ext_1'],
            },
            {
              title:
                'Forøket valgus ved stress test på 20 graders flektert kne',
              variable: knee_valgus_flex_1,
              key: 'knee_valgus_flex_1',
              keys: BASELINE_KNEE['knee_valgus_flex_1'],
            },
            {
              title:
                'Forøket varus ved stress test på ekstendert kne',
              variable: knee_varus_ext_1,
              key: 'knee_varus_ext_1',
              keys: BASELINE_KNEE['knee_varus_ext_1'],
            },
            {
              title:
                'Forøket varus ved stress test på 20 graders flektert kne',
              variable: knee_varus_flex_1,
              key: 'knee_varus_flex_1',
              keys: BASELINE_KNEE['knee_varus_flex_1'],
            },
          ].map(({ title, key, keys, variable }) => (
            <div key={`${title}-${key}`}>
              <CardFormText text={title} />
              <div className="mb-8">
                <BoxFields
                  values={values}
                  variables={{
                    [key as string]: variable,
                  }}
                  keys={keys}
                />
              </div>
            </div>
          ))}
        </>
      )}
      <div className="mb-6">
        <CardLabel
          text="Eventuelt kommentarer:"
          size={CardLabelType.lg}
        />
      </div>
      <div className="mb-6">
        <FormInput
          id="knee_comment_1"
          name="knee_comment_1"
          type="text"
          className="border border-gray-500 w-full px-4 p-3 rounded leading-none"
        />
      </div>
    </Card>
  );
};
