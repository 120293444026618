import { GENDER } from 'data';
import React from 'react';
import { PatientResponseInfo } from 'types';
import { getMappedValue } from 'utils';

const Label = ({ title }: { title: string }) => {
  return (
    <div className="mb-4">
      <span>{title}</span>
    </div>
  );
};

interface PatientInfoProps {
  patient: PatientResponseInfo;
}

export const PatientInfo: React.FC<PatientInfoProps> = ({
  patient,
}) => {
  if (!patient)
    return (
      <div
        className="flex mx-12 text-sm"
        style={{ minHeight: '185px' }}
      />
    );
  const {
    patientId,
    patientDetails: {
      birth_year: birthYear,
      gender_1: sex,
      smoking_1: smoking,
      bmi_1: bmi,
    },
  } = patient;

  const date = new Date();
  return (
    <div className="flex mx-12 text-sm">
      <div className="mr-3 text-gray-500">
        <Label title="Pasient:" />
        <Label title="Alder:" />
        <Label title="Kjønn:" />
        <Label title="Røyker:" />
        <Label title="BMI:" />
      </div>

      <div>
        <Label
          title={
            patientId
              ? patientId.split('_')[patientId.split('_').length - 2]
              : ''
          }
        />
        <Label title={`${date.getFullYear() - birthYear} år`} />
        <Label title={getMappedValue(GENDER.gender_1, sex).no} />
        <Label
          title={smoking.toLowerCase() === 'yes' ? 'Ja' : 'Nei'}
        />
        <Label title={bmi ? bmi.toFixed(1) : ''} />
      </div>
    </div>
  );
};
