import React from 'react';
import { Radar as Chart, ChartComponentProps } from 'react-chartjs-2';

interface RadarProps extends ChartComponentProps {}

export const Radar = React.forwardRef<Chart, RadarProps>(
  ({ data, legend, options, width, height }, ref) => {
    return (
      <Chart
        data={data}
        legend={legend}
        options={options}
        width={width}
        height={height}
        ref={ref}
      />
    );
  },
);
