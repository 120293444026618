import React, { useEffect, useState } from 'react';
import * as JsSearch from 'js-search';

import { Card } from 'components/Card';
import { Input } from 'components/Input';
import { useRouter } from 'hooks';
import { PatientResponseInfo } from 'types';
import { Helmet } from 'react-helmet';
import { ImportPatient } from 'components/ImportPatient';
import { getAllPatients } from 'services';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const stemmer = require('porter-stemmer').stemmer;
export const PATIENTS_TEMP = [
  'support10_1_1963',
  'support10_2_1965',
  'support10_5_1983',
  'support10_6_1958',
  'support10_7_1956',
  'support10_8_1967',
  'support10_9_1966',
  'support10_10_1964',
  'support10_11_1966',
  'support10_12_1950',
  'support10_13_1977',
  'support10_16_1972',
  'support10_17_1973',
  'support11_1808_1969',
  'support11_1810_1989',
  'support11_1822_1981',
  'support11_1837_1984',
  'support11_1903_1996',
  'support11_1912_1974',
  'support12_31903_1972',
  'support12_31910_1968',
  'support12_31992_1961',
  'support12_32035_1969',
  'support13_29310_2002',
  'support13_31894_1956',
  'support13_31909_1995',
  'support13_32177_1961',
  'support15_6245_1969',
  'support19_5191_1968',
  'support2_7446_1988',
  'support2_31277_1964',
  'support23_2_1965',
  'support23_3_1950',
  'support24_101_1984',
  'support24_102_1978',
  'support24_104_1966',
  'support26_1001_1950',
  'support27_1002_1990',
  'support28_2000_1973',
  'support28_2002_1981',
  'support28_2005_1956',
  'support28_2006_1957',
  'support28_2007_1947',
  'support31_25613_1992',
  'support31_32359_1979',
  'support31_32542_1988',
  'support31_32581_1985',
  'support31_32753_1994',
  'support31_32790_1957',
  'support31_32946_1990',
  'support31_33151_1966',
  'support34_3_1968',
  'support34_4_1977',
  'support34_5_2001',
  'support34_2_1983',
  'support36_9546_1985',
  'support39_11918_1970',
  'support39_11930_1967',
  'support39_11932_1992',
  'support4_38888_1998',
  'support4_39151_1997',
  'support4_39178_1999',
  'support4_39185_1993',
  'support4_39442_1989',
  'support5_8637_1994',
  'support5_11785_1956',
  'support5_17504_1973',
  'support5_26296_1962',
  'support5_30160_1992',
  'support5_31911_1995',
  'support5_31912_1971',
  'support5_31959_1969',
  'support5_32015_1965',
  'support5_32110_1959',
  'support5_32111_1998',
  'support5_32765_1939',
  'support6_8853_1968',
  'support6_24778_1984',
  'support6_39465_1967',
  'support6_39884_1995',
  'support6_39956_1957',
  'support6_40051_1961',
  'support7_3816_1984',
  'support7_5655_1992',
  'support9_2748_1996',
  'support9_8017_1973',
  'support9_8092_1982',
  'support9_9145_1975',
  'support9_9327_1955',
  'support6_41393_1998',
  'support6_40931_1958',
  // 17/04/2021 Added new CBR patients
  'support10_2_1965',
  'support23_8_1991',
  'support23_10_1960',
  'support23_11_1974',
  'support27_1005_1961',
  'support27_1006_1957',
  'support27_1007_1940',
  'support34_6_1959',
  'support39_12019_1950',
  'support41_2_1954',
  'support41_3_1989',
  'support41_4_1968',
  'support42_3001_1975',
  'support42_3002_1990',
  'support42_3003_1975',
  'support6_41393_1998',
  'support6_41404_1982',
  'support9_1003_1982',
  'support9_8092_1982',
].map((v) => {
  const str = v.split('_');
  return {
    id: v,
    patientId: v,
    patientDetails: {
      birth_year: str[str.length - 1],
    },
  };
});

interface PatientsProps {}

export const Patients: React.FC<PatientsProps> = () => {
  const [data, setData] = useState<PatientResponseInfo[]>();
  const router = useRouter();
  const [keyword, setKeyword] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const username = localStorage.getItem('username');

  const logout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    router.push('/');
  };

  // SPA Link Tracking
  const { trackPageView, enableLinkTracking } = useMatomo();
  enableLinkTracking();
  // Track page view
  useEffect(() => {
    const { location } = router;
    const trackOptions = {
      documentTitle: `${document.title} - Velg patient`,
      href: `https://supportprim.idi.ntnu.no${location.pathname}`, // optional
    };
    trackPageView(trackOptions);
  }, [router, trackPageView]);

  // Fetch users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token') || '';

        const query = username
          ? new URLSearchParams({
              clinicId: username,
            })
          : '';
        const response = await fetch(
          `${process.env.REACT_APP_URL_CBR}/patient/getPatientByClinicId?${query}`,
          {
            headers: {
              Authorization: token,
            },
          },
        );
        const json = await response.json();
        if (username === 'ui') {
          const patients = (await getAllPatients()) || [];
          setData(
            [...json, ...patients].sort((a, b) => {
              return (
                Number(new Date(b.dateUpdated)) -
                Number(new Date(a.dateUpdated))
              );
            }),
          );
        } else {
          setData(
            [...json].filter((p) => {
              return !PATIENTS_TEMP.map((c) => c.id).includes(
                p.patientId,
              );
            }),
          );
        }
      } catch (error) {
        console.log('PatientsError', error);
        // localStorage.removeItem('user');
        // localStorage.removeItem('token');
        // localStorage.removeItem('username');
        router.push('/login');
      }
    };
    fetchData();
  }, []);

  if (!data) return null;

  const search = new JsSearch.Search('patientId');
  search.tokenizer = new JsSearch.StemmingTokenizer(
    stemmer, // Function should accept a string param and return a string
    new JsSearch.SimpleTokenizer(),
  );
  search.addIndex('patientId');
  search.addIndex('birthYear');

  search.addDocuments(data!);

  const handleInputSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    const result: any = search.search(value);
    setKeyword(value);
    setSearchResult(result);
  };

  const handleClickPatient = (id: string) => {
    localStorage.setItem('user', `${id}`);
    router.history.push('/app');
  };

  return (
    <div className="py-4 mx-auto max-w-screen-lg px-6">
      <Helmet>
        <title>{`SupportPrim - Velg pasient`}</title>
      </Helmet>
      <div className="flex mb-2">
        <button className="ml-auto text-sm" onClick={() => logout()}>
          Logg av
        </button>
      </div>
      <ImportPatient />
      <Card>
        <Input
          placeholder="Søk pasient"
          onChange={handleInputSearch}
        />
      </Card>
      <Card noSpacing={true} className="py-6 mb-6">
        <div className="flex px-6 mb-4">
          <div className="w-1/3 text-sm font-medium">Id</div>
          <div className="w-1/3 text-sm font-medium">Fødselsår</div>
          <div className="w-1/3 text-sm font-medium"></div>
        </div>

        {(keyword ? searchResult : data).map(
          ({
            patientId,
            patientDetails,
            dateUpdated,
            similarPatients,
          }) => {
            const { birth_year, consent_1 } = patientDetails;
            if (!birth_year) return null;

            return (
              <div
                className={`flex text-gray-800 hover:bg-gray-200 opacity hover:bg-opacity-25 py-3 px-6 cursor-pointer ${
                  localStorage.getItem('user') === patientId
                    ? 'font-bold text-indigo-600'
                    : ''
                }`}
                key={patientId}
                onClick={() => {
                  if (
                    consent_1?.toLowerCase() === 'jeg samtykker ikke'
                  ) {
                    alert('Pasient samtykker ikke');
                  } else {
                    handleClickPatient(patientId);
                  }
                }}
              >
                <div className="w-1/3 text-sm">
                  <div>{patientId}</div>
                  {username === 'ui' &&
                  dateUpdated &&
                  Object.keys(similarPatients || {}).length >= 3 ? (
                    <div className="text-gray-600 font-normal">
                      {new Date(dateUpdated).toLocaleDateString(
                        'da-DK',
                        {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        },
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="w-1/3 text-sm">{birth_year}</div>
                <div className="w-1/3 text-sm text-right cursor-pointer hover:text-indigo-500">
                  {consent_1?.toLowerCase() === 'jeg samtykker ikke'
                    ? 'Paisenten har ikke samtykket til deltakelse'
                    : 'Velg'}
                </div>
              </div>
            );
          },
        )}
      </Card>
    </div>
  );
};
